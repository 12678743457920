@import 'scss/vars.scss';

.data-fill {
  .title-block {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .title {
    margin-top: 2em;
  }

  .title,
  .subtitle {
    color: $color-text;
  }

  .bot-main {
    display: flex;
    flex-direction: column;
    background-color: $color-white;

    .bot-form {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-content: stretch;
      .bot-select {
        display: flex;
        flex-direction: column;
      }

      .bot-upload {
        margin-top: 1em;
        display: flex;
        flex-direction: column;

        .upload-section {
          display: flex;
          flex-direction: row;
          justify-content: space-between;

          .upload-file-container {
            margin-right: 1em;
          }

          .upload-file-container,
          .file-list-container {
            display: flex;
            flex: 1 1 50%;

            .file-upload-template-wrapper,
            .upload-list {
              width: 100%;
            }

            .upload-list {
              .headertext {
                font-size: $typography-font-size-normal;
                font-weight: $typography-font-weight-light;
                color: $color-grey-550;
                margin-bottom: 1em;
              }

              .itemlink {
                max-width: 25rem;
                text-overflow: ellipsis;
                overflow: hidden;
                font-size: $typography-font-size-normal;
                font-weight: $typography-font-weight-bolder;
                color: $color-blue-main;
                &:hover,
                &:focus {
                  word-wrap: break-word;
                }
              }

              .list-group {
                border-top: 1px solid rgba($color-grey-550, 0.2);
                border-bottom: 1px solid rgba($color-grey-550, 0.2);
                .list-group-item {
                  padding-left: 0;
                  padding-right: 0;
                  @include flex;
                  align-items: center;
                  justify-content: space-between;

                  .itemtext {
                    font-size: $typography-font-size-normal;
                    font-weight: $typography-font-weight-bolder;
                    color: $color-black;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;
                    flex: 1 1;
                    max-width: 50vw;
                  }

                  .itemdetail {
                    margin-left: 2em;
                    margin-right: 0.2em;
                    color: $color-grey-550;
                    font-style: italic;
                    flex: 0 0 70px;
                  }

                  .itemselect {
                    width: 220px;
                    margin-left: auto;
                    margin-right: 0.1em;
                    flex: 0 0 40px;
                  }

                  .item-deletebutton {
                    margin-left: 2em;
                    border: 1px solid rgba($color-grey-550, 0.3);
                    box-shadow: 0 1px 3px 0 rgba(223, 225, 228, 0.5);
                    color: $color-grey-900;
                    &:hover {
                      color: $color-white;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    .bot-invalid {
      margin-top: 1em;
      color: $color-red-dg;
      font-weight: $typography-font-weight-bold;
      display: flex;
      flex-wrap: nowrap;
    }

    .bot-actions {
      display: flex;
      justify-content: flex-end;
      margin-top: 1em;

      button {
        margin-left: 0.5em;
      }
    }
  }
}
