@import 'scss/vars.scss';

.data-job-direction-select {
  @include block;
  margin-top: 1em;
  padding: 1em 1.5em 2em 1.5em;

  .title h2 {
    font-size: 2rem;
  }

  .options-list {
    @include flex;
    align-items: stretch;
    justify-content: space-between;
    margin-top: 1em;

    .option-wrap {
      @include flex;
      @include block;
      flex: 0 1 32%;
      padding: 1em 1.5em;
      background: $color-grey-50;

      &.active {
        background: $color-white;
        box-shadow: 0 0 3px 0 $color-orange-dg;
      }

      &.invalid {
        border-color: #dc3545;
      }

      &:not(.active) {
        cursor: pointer;

        &:hover {
          border-color: $color-grey-500;
        }
      }

      .option-input {
        margin-right: 1em;
      }

      .option-label {
        .title {
          font-weight: $typography-font-weight-bold;
          font-size: 1.1em;
          line-height: 1.3;
        }

        .subtitle {
          margin-top: 0.5em;
        }
      }
    }
    .option-wrap-isIE {
      flex-basis: 29%;
    }
  }

  .invalid-feedback {
    margin-top: 1em;
  }

  &.invalid {
    .invalid-feedback {
      display: block;
    }
  }
}
