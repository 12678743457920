@import "scss/vars.scss";

.breadcrumbs {
    margin-bottom: 1em;
    padding: 0 20px;
    font-size: 13px;

    p {
        margin: 0;
    }

    ul {
        margin: 0;
        padding: 0;
        list-style: none;
    }

    .oi {
        margin-right: 8px;
        color: $color-grey-550;
        font-size: 8px;
    }
}

.breadcrumbs-list {
    display: inline-flex;
    flex-wrap: wrap;
    align-items: center;
}

.breadcrumbs-list-item {
    display: inline-flex;
    align-items: center;
    margin-right: 8px;
    color: $color-text;
    // TODO: Uncomment this when PDP taxonomy is clickable
    // &--active {
    //     color: $color-blue-dg;
    //     .oi {
    //         color: $color-blue-dg;
    //     }
    // }
}
