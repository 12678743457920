@import "scss/vars.scss";

.request-update-card {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  box-shadow: 0 1px 3px 1px rgba(0, 19, 92, 0.15);
  padding: 1em;
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column nowrap;

  background-color: $color-blue-dg;
  color: $color-white;

  &-title {
    color: $color-white;
    text-transform: uppercase;
    font-weight: $typography-font-weight-bolder;
  }

  .request-update-card-btn {
    width: 100%;
    margin-top: 0.25em;
    font-weight: 400;
    padding: 1px 0;
    border-color: #3278eb !important;
    color: #3278eb !important;
  }

}
