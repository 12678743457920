@import "scss/vars.scss";

.data-job-create-details-bottom {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  height: 100%;
  padding: 20px;
  background-color: $color-grey-100;
  border-style: solid;
  border-width: 2px 0 0 0;
  border-top-color: $color-grey-200;
  border-radius: 0 0 0.5em 0.5em;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.05);
}
