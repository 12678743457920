@import 'scss/vars.scss';

.cds-bot-info {
  padding: 5px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  border-radius: 8px;
  background: #fff;
  box-shadow: 1px 2px 5px 1px rgba(0, 0, 0, 0.25);

  .cds-bot-item {
    display: flex;
    justify-content: space-between;
    cursor: pointer;

    .cds-bot-item-description {
      color: $color-grey-900;
      font-size: 12px;
      font-style: normal;
      font-weight: $typography-font-weight-normal;
      line-height: normal;
    }

    .cds-bot-title {
      color: $color-grey-900;
      text-align: center;
      font-size: 16px;
      font-style: normal;
      font-weight: $typography-font-weight-boldest;
      line-height: normal;
    }

    .powered-by-wrapper {
      font-size: 12px;

      span {
        img {
          margin-left: 0.4em;
          width: 16px;
          height: 16px;
        }
      }
    }
  }
}
