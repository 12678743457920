@import 'scss/vars.scss';

.general-info-assets-actions {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: 10px;
  font-size: 15px;

  .dropdown-toggle {
    height: 100%;
    padding: 8px 14px;
    font-size: 15px;

    &:first-of-type {
      margin-right: 10px;
    }

    &::after {
      margin-left: 1.2em;
    }
  }

  input[type='checkbox'] {
    margin-right: 10px;
    cursor: pointer;
  }

  .dropdown-menu {
    margin: 10px 0;
    padding: 0;
    overflow: auto;
    color: $color-text;
    border: 1px solid $color-grey-dg;
    border-radius: 8px;
    box-shadow: 0 2px 6px 0 rgba(30, 44, 71, 0.2);
  }

  .specific-documentation > .dropdown-menu {
    min-width: 480px;
    max-height: 390px;
  }

  .download-images > .dropdown-menu {
    max-width: 60vw;
    max-height: 430px;
  }

  .assets-dropdown-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px 0 20px;

    .title {
      font-weight: $typography-font-weight-bolder;
      font-size: 18px;
      line-height: 30px;
    }

    .close-icon {
      color: $color-grey-dg;
      font-size: 10px;
      cursor: pointer;
    }
  }

  .assets-actions-body {
    margin: 20px 0;
    padding: 0px 20px 10px 20px;
    font-size: 13px;

    .assets-actions-body-header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-bottom: 5px;
      font-weight: $typography-font-weight-bolder;

      &__leading {
        margin-right: 15px;
      }

      &__select {
        @include user-select-none;
        color: $color-blue-dg;
        font-weight: $typography-font-weight-normal;
        cursor: pointer;
        span {
          margin-right: 5px;
          font-size: 10px;
        }
        &--disabled {
          color: $color-blue-dg-light;
          cursor: default;
        }
      }
    }

    label {
      @include user-select-none;
      max-width: 310px;
      margin: 0;
      color: $color-text;
      cursor: text;
    }

    .assets-actions-body-row {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 12px 0;
      border-bottom: 1px solid $color-grey-dg;

      &:last-child {
        border-bottom: none;
      }
    }

    .assets-actions-body-images {
      display: flex;
      flex-wrap: wrap;
      margin-top: 10px;
      min-width: 35vw;
      max-width: 50vw;
      justify-content: space-between;
    }

    .assets-actions-body-img {
      position: relative;
      width: 140px;
      height: 140px;
      border: 1px solid $color-grey-dg;
      border-radius: 4px;
      margin: 2px;
      flex: 0 0 32%;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        border-radius: 4px;
      }

      a {
        position: absolute;
        right: 0;
        bottom: 0;
        margin-right: 10px;
        margin-bottom: 10px;
      }
    }

    .assets-actions-body-download {
      @include button-reset;
      @include user-select-none;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 30px;
      height: 30px;
      margin-left: auto;
      font-size: 11px;
      background-color: $color-white;
      border: 1px solid $color-grey-dg;
      border-radius: 6px;
      box-shadow: 0 1px 3px 0 rgba(223, 225, 228, 0.5);

      &:focus {
        outline: none;
      }
    }

    .assets-actions-body-type {
      margin-right: 10px;
    }

    .assets-actions-body-checkbox {
      display: flex;
      align-items: center;
      margin-right: 15px;
    }
  }

  &--download {
    align-items: center;
    font-weight: $typography-font-weight-bolder;

    span.oi {
      margin-right: 15px;
      font-size: 11px;
    }
  }
}
