@import 'scss/vars.scss';

.reclassify-to-actions {
  display: flex;
  flex-direction: column;
  flex-grow: 4;
  padding: 0.5em;
  border: 1px solid $color-white;
  border-radius: 0.5em;

  &.disabled {
    border-color: $color-grey-400;
    background-color: $color-grey-300;
    color: $color-grey-550;
    h4 {
      color: $color-grey-550;
    }
  }

  input {
    padding: 1.3em 0.75em;
  }

  .to-radio {
    padding: 0.5em 0;

    label {
      display: inline-flex;
      align-items: baseline;
      font-size: 1.25rem;
      margin-right: 1rem;

      input {
        margin-right: 0.25rem;
      }
    }
  }
}
