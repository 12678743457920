.view-login {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 100%;
  background-color: rgba(30, 44, 71, 0.5);
  background-image: url("../assets/img/backbone-bg.jpg");
  background-repeat: no-repeat;
  background-size: cover;

  @media
  (-webkit-min-device-pixel-ratio: 1.8),
  (min-resolution: 170dpi),
  (min-height: 1440),
  (min-width: 2560) {
      background-image: url("../assets/img/backbone-bg-4k.jpg");
  }


  .view-login-wrapper {
    display: flex;
    flex: 0 1 $min-content-height;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    min-height: $min-content-height;
  }

  .view-login-wrapper-isIE {
    margin-top: 20%;
  }

  .view-login-addon-cta {
    width: 185px;
    margin: 0 auto;
    padding: 0.75rem;
    color: $color-white;
    font-weight: $typography-font-weight-bolder;
    font-size: 18px;
    letter-spacing: 0.1px;
    text-align: center;
    vertical-align: middle;
    background-color: $color-orange-dg;
    border-radius: 6px;
  }

  .view-login-card {
    display: flex;
    flex-direction: column;
    width: 480px;
    margin: 15px;
    padding: 2.5rem 2.5rem;
    color: $color-text;
    text-align: center;
    background-color: $color-white;
    border: 1px solid $color-grey-dg;
    border-radius: 8px;
    box-shadow: 0 2px 6px 0 rgba(30, 44, 71, 0.2);
  }

  .view-login-form {
    margin: 20px 0;
    text-align: left;

    .view-login-forgot {
      margin-top: 5px;
    }
  }

  .view-login-group {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 30px;
    text-align: left;

    .view-login-label {
      color: $color-text;
      font-weight: $typography-font-weight-bolder;
      font-size: 13px;
    }

    .view-login-input {
      flex: 1 1 auto;
      height: 38px;
      padding: 0.5rem 1rem;
      font-size: 15px;
      background-color: $color-white;
      border: 1px solid $color-grey-dg;
      border-radius: 6px;
      box-shadow: inset 0 1px 2px 0 rgba(223, 225, 228, 0.6);
      transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

      &::placeholder {
        color: $color-grey-dg;
      }
      &:focus {
        border-color: $color-orange-dg;
        box-shadow: 0 0 3px $color-orange-dg;
      }
    }

    .view-login-group-addon {
      position: relative;
      display: inline-flex;
      .password-toggle-icon {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        margin-top: auto;
        margin-right: 10px;
        margin-bottom: auto;
        color: $color-text;
        cursor: pointer;
      }
    }
  }

  .view-login-submit {
    width: 100%;
    margin: 20px auto;
    padding: 0.75rem 0;
    color: $color-white;
    font-weight: $typography-font-weight-bolder;
    font-size: 18px;
    background-color: $color-green-dg;
    border: 1px solid $color-green-2;
    border-radius: 6px;
    box-shadow: 0 2px 3px 0 rgba(32, 192, 148, 0.2);
  }

  .view-login-addon {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    padding: 1.4rem;
    font-size: 15px;
    text-align: center;
    background-color: $color-grey-50;
    border-radius: 8px;
  }

  .view-login-heading {
    font-weight: $typography-font-weight-bolder;
    font-size: 28px;
  }

  .view-login-subheading {
    color: $color-orange-dg;
    font-size: 18px;
  }

  .view-login-branding {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: auto;
    font-size: 30px;

    a {
      flex: 1;
    }

    img {
      height: 65px;
    }
  }
}
