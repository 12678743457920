@import "scss/vars.scss";

.databot {
  display: flex;
  flex-direction: column;
  width: 280px;
  height: 264px;
  color: $color-text;
  background-color: $color-white;
  border-radius: 6px;
  box-shadow: 0px 2px 6px 0px #00135c0f;

  &--running {
    color: $color-white;
    background-color: $color-blue-dg;

    .databot-details {
      padding-top: 4px;
    }
  }

  &-actions {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &__recently-finished {
      text-align: center;
    }
  }

  &-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 72px;
    padding: 15px 20px;
    background-color: $color-blue-dg;
    border-radius: 6px 6px 0px 0;

    h2 {
      color: $color-white;
      font-weight: $typography-font-weight-bolder;
      font-size: 15px;
      letter-spacing: 0.6px;
    }

    .cubes-icon {
      width: 28px;
      height: 28px;
      margin-right: 10px;
      color: $color-white;
    }
  }

  &-details {
    // display: flex;
    // flex-direction: column;
    // justify-content: space-between;
    height: 100%;
    // padding: 12px 20px;
    font-size: 13px;

    .databot-status-template {
      position: relative;
      height: 100%;

      $_h_padding: 1em;
      $_v_padding: 0.75em;

      &-top {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        padding: $_v_padding $_h_padding 0 $_h_padding;
      }

      &-middle {
        position: absolute;
        top: 40%;
        left: 0;
        right: 0;
        transform: translateY(-50%);
        padding: 0 $_h_padding;
        p:last-child {
          margin-bottom: 0;
        }
      }

      &-bottom {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        padding: 0 $_h_padding $_v_padding $_h_padding;

        .running-message-wrap {
          @include flex;
          align-items: center;
          justify-content: center;
          font-size: 1.25em;
          font-weight: $typography-font-weight-bold;
          .running-icon {
            margin-left: 0.5em;
          }
        }
      }
    }
    .progress {
      height: 16px;
      margin-top: 4px;
      background-color: $color-white;
      border-radius: 6px;
    }

    .progress-bar {
      background-color: #ccd8ff;
      border-radius: 6px;
    }

    p {
      margin: 0;
      margin-bottom: 14px;
    }

    &__time-remaining {
      font-size: 14px;
    }

    &__last-ran,
    &__est-time {
      color: #7c859d;
      font-weight: $typography-font-weight-bolder;
      font-size: 11px;
      letter-spacing: 0.33px;
      text-transform: uppercase;
    }

    &__tertiary,
    &__secondary,
    &__primary {
      display: inline-flex;
      margin-top: 12px;
      margin-right: 5px;
      padding: 4px 12px;
      font-weight: $typography-font-weight-bolder;
      font-size: 12px;
      border-radius: 99px;
    }

    &__primary {
      color: $color-green-dg;
      background-color: #e9fdf8;
    }

    &__secondary {
      color: $color-text;
    }

    &__tertiary {
      color: $color-blue-dg;
      background-color: #e6ecff;
    }

    &__complete {
      color: $color-text;
      font-weight: $typography-font-weight-bolder;
      font-size: 15px;
      letter-spacing: 0.45px;

      svg {
        margin-right: 4px;
        color: $color-green-dg;
      }
    }

    &--loading {
      margin: auto;
      height: 100%;
      width: 100%;
      display: flex;
    }
  }

  &-action {
    @include button-reset;
    display: inline-flex;
    align-items: center;
    padding: 8px 14px;
    color: $color-white;
    font-weight: $typography-font-weight-bolder;
    font-size: 12px;
    line-height: 1.3;
    background-color: $color-blue-dg;
    border: 1px solid $color-blue-dg;
    border-radius: 6px;
    transition: all 0.1s ease-in-out;

    svg {
      margin-left: 8px;
      font-size: 16px;
    }

    .fa-forward {
      width: 0.875em;
    }

    &--alt {
      color: $color-blue-dg;
      background-color: $color-white;
    }

    &--failed {
      svg {
        font-size: 14px;
      }
    }
  }
}
