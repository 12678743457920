@import 'scss/vars.scss';

.image-quality-assessments {
  margin-bottom: 130px;
  .image-quality-search {
    position: relative;
    margin-top: 2em;
  }

  .loading-container {
    width: 100%;
    display: flex;
  }

  .assessments-table {
    .pagination-top,
    .pagination-bottom {
      display: flex;
      flex-flow: row nowrap;
      width: 100%;
      margin-top: 1.5em;
    }
  }
}
