@import "scss/vars.scss";

.bai-value-block {
  $_block-padding: 1em;
  @mixin _kv-title {
    color: $color-blue-dg;
    font-weight: $typography-font-weight-bolder;
    font-size: 1.2em;
  }

  &:not(.borderless) {
    @include block;
  }
  padding: $_block-padding;

  > .title {
    @include flex;
    .label {
      @include _kv-title;
      flex: 1 1 auto;
    }

    .title-tooltip {
      margin-top: 0.25em;
      margin-left: 0.75em;
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;
      flex: 0 0 1.5em;
      width: 1.5em;
      height: 1.5em;
      padding: 0.3em;
      border: 1px solid $color-grey-400;
      border-radius: 50%;
      box-shadow: 0 1px 3px 0 $color-grey-500;
      cursor: default;

      .icon {
        color: $color-grey-550;
        font-size: 0.85em;
      }
    }
  }

  > .value {
    margin-top: 0.25em;
    font-weight: $typography-font-weight-bolder;
    font-size: 2.5em;
    line-height: 1.2;
  }

  > .subtitle {
    @include _kv-title;
    color: $typography-font-color-normal;
    font-size: 1em;
    text-transform: uppercase;
  }

  > .additional-data {
    margin-top: 0.5em;
    color: $color-grey-500;
    font-weight: $typography-font-weight-bolder;
    line-height: 1.2;

    .value {
      font-weight: $typography-font-weight-boldest;
    }
  }
}
