@import "scss/vars.scss";

.section-overview {
    .kv-pair {
      .value {
        margin-top: 0.25em;
        font-size: 1.2em;
        font-weight: $typography-font-weight-bold;
      }
    }

    .summary-data-1 {
      margin-top: 1em;
      @include flex;
      .job-name {
        flex: 1 0 auto;
        margin-right: 1em;
      }
    }

    .summary-data-2 {
      margin-top: 2em;
      border-radius: 0.1em;
      background: $color-grey-50;
      border: 1px solid $color-grey-300;
      padding: 0.75em 1em;
      @include flex;
      justify-content: space-between;
      > .kv-pair {
        min-width: 8em;

        &:not(:first-child) {
          margin-left: 1em;
        }
      }
    }

    .aggregate-score {
      .aggregate-score-value {
        margin: 0 auto 1em auto;
        width: 13em;

        .CircularProgressbar {
          .CircularProgressbar-path {
            stroke: $color-grey-400;
          }
          .CircularProgressbar-trail {
            stroke: change-color($color-grey-400, $lightness: 90%);
          }
        }

        .circular-progressbar-label {
          text-align: center;
          .value {
            font-size: 3em;
            line-height: 1;
            font-weight: $typography-font-weight-bolder;
            .unit {
              font-size: 0.5em;
            }
          }
          .label {
            margin-top: 0.25em;
            font-weight: $typography-font-weight-bolder;
          }
          .status-label-wrap {
            margin-top: 0.75em;
            @include flex;
            justify-content: center;
            .status-label {
              font-size: 0.85em;
              margin: 0 auto;
              padding: 0.3em 1em 0.4em 1em;
              line-height: 1;
              border-radius: 1em;
              font-weight: $typography-font-weight-bolder;
              background: change-color($color-grey-400, $lightness: 90%);
              color: $color-grey-400;
            }
          }
          &.empty .empty-label {
            display: block;
            font-size: 2em;
            line-height: 1;
            color: $color-grey-200;
            font-weight: $typography-font-weight-bolder;
            margin-top: -0.1em;
          }
        }
      }

      &.good .aggregate-score-value {
        .CircularProgressbar {
          .CircularProgressbar-path {
            stroke: $color-green-dg;
          }
          .CircularProgressbar-trail {
            stroke: change-color($color-green-dg, $lightness: 85%);
          }
        }
        .circular-progressbar-label {
          .status-label-wrap {
            .status-label {
              background: change-color($color-green-dg, $lightness: 85%);
              color: $color-green-dg;
            }
          }
        }
      }
      &.acceptable {
        .CircularProgressbar {
          .CircularProgressbar-path {
            stroke: change-color($color-yellow-dg, $lightness: 40%);
          }
          .CircularProgressbar-trail {
            stroke: change-color($color-yellow-dg, $lightness: 75%);
          }
        }
        .circular-progressbar-label {
          .status-label-wrap {
            .status-label {
              background: change-color($color-yellow-dg, $lightness: 75%);
              color: change-color($color-yellow-dg, $lightness: 40%);
            }
          }
        }
      }
      &.poor {
        .CircularProgressbar {
          .CircularProgressbar-path {
            stroke: $color-red-2;
          }
          .CircularProgressbar-trail {
            stroke: change-color($color-red-2, $lightness: 85%);
          }
        }
        .circular-progressbar-label {
          .status-label-wrap {
            .status-label {
              background: change-color($color-red-2, $lightness: 85%);
              color: $color-red-2;
            }
          }
        }
      }
    }
  }
