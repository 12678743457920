@import 'scss/vars.scss';

.distributor-items-export-panel {
  .distributor-titlebar-subtitle {
    color: $color-grey-550;
  }

  .distributor-items-form {
    margin: 3rem;
    justify-content: space-around;
  }

  .distributor-form-group {
    display: inline-flex;
    align-items: center;
  }

  .disabled {
    display: none;
  }

  .form-label {
    margin-right: 1rem;
  }
}