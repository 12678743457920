html {
  font-family: $typography-font-family-normal;
  font-size: $typography-font-size-root;
  font-weight: $typography-font-weight-normal;
  color: $typography-font-color-normal;
}

body,
input,
textarea,
select,
button,
.popover,
.Toastify,
.Toastify .Toastify__toast {
  font-family: $typography-font-family-normal;
  font-size: $typography-font-size-normal;
  font-weight: $typography-font-weight-normal;
  color: $typography-font-color-normal;
}

// Bootstrap overrides
body {
  .dropdown-menu {
    border-radius: 0.5em;
    padding: 1em 0;
  }
  blockquote {
    color: $color-grey-3;
    margin: 0;
    padding-left: 3em;
    border-left: 0.5em $color-grey-200 solid;
  }
  input,
  textarea,
  select {
    outline: none;
  }
  label {
    color: $color-grey-550;
  }
  select.form-control {
    appearance: none;

    background-image: linear-gradient(45deg, transparent 50%, $typography-font-color-normal 50%),
      linear-gradient(135deg, $typography-font-color-normal 50%, transparent 50%);
    // linear-gradient(to right, #ccc, #ccc);
    background-position: calc(100% - 15px) calc(50%), calc(100% - 10px) calc(50%);
    // calc(100% - 2.5em) 0.5em;
    background-size: 5px 5px, 5px 5px;
    // 1px 1.5em;
    background-repeat: no-repeat;

    padding-right: 25px;
    text-overflow: ellipsis;

    &.is-invalid,
    &:invalid {
      background-position: center right 2rem;
    }
  }

  .form-control {
    border-radius: $border-radius-lg;
    border: 1px solid $color-grey-300; // Add to color palette
    color: $typography-font-color-normal;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.05);

    &:hover {
      border-color: $color-grey-400;
    }
    &:focus {
      border-color: $color-orange-dg;
      box-shadow: 0 0 3px $color-orange-dg;
    }
  }

  .custom-control-input ~ .custom-control-label::before {
    border-color: $color-grey-100;
  }

  .custom-control-input:hover ~ .custom-control-label::before {
    border-color: $color-grey-500;
  }

  .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: none;
    border-color: $color-orange-main !important;
  }

  .custom-control-input:checked ~ .custom-control-label::before {
    background-color: $color-orange-main;
  }

  .btn,
  a.btn,
  span.btn {
    border-radius: $border-radius-lg;
    // text-transform: uppercase;
    font-weight: $typography-font-weight-bold;
    transition: box-shadow 0.25s;
    &.btn-lg {
      padding-left: 2rem;
      padding-right: 2rem;
    }
    &.btn-primary,
    &.btn-primary.dropdown-toggle {
      color: $color-white;
      border: 1px solid $color-green-2;
      background: $color-green-dg;
      box-shadow: 0 2px 3px 0 rgba(32, 192, 148, 0.2) !important; // Add to color palette

      &.disabled,
      &:disabled {
        opacity: 0.5;
      }

      &:hover,
      &:active,
      &:focus {
        color: $color-white;
        border-color: $color-green-2;
        background: linear-gradient(180deg, #30d0a4 0%, $color-green-dg 100%);
        box-shadow: 0 2px 3px 0 rgba(32, 192, 148, 0.5);
      }
    }
    &.btn-outline-primary {
      text-transform: uppercase;
      font-weight: $typography-font-weight-bolder;
      letter-spacing: 0.1em;
      color: $color-green-dg;
      border-color: $color-green-dg;

      &.disabled,
      &:disabled {
        color: $color-green-dg;
        border-color: $color-green-dg;
        opacity: 0.5;
      }

      &:hover {
        background-color: transparent;
        color: $color-green-dg;
        border-color: $color-green-dg;
      }
    }
    &.btn-secondary,
    &.btn-secondary.dropdown-toggle {
      color: $typography-font-color-normal;
      border: 1px solid $color-grey-300;
      background: $color-white;
      box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.05); // Add to color palette

      &.disabled,
      &:disabled {
        opacity: 0.5;
      }

      &:hover,
      &:active,
      &:focus {
        color: $typography-font-color-normal;
        border-color: $color-grey-300;
        background: linear-gradient(180deg, $color-white 0%, #fafafa 100%);
        box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.2) !important;
      }
    }

    &.btn-request-data {
      border: 1px solid #ed594b;
      background: linear-gradient(180deg, #fa7c28 0%, #ac2f08 100%);
      box-shadow: 0 2px 3px 0 rgba(250, 124, 40, 0.25) !important;
      font-weight: 500;
      white-space: nowrap;

      &:hover,
      &:active,
      &:focus {
        border-color: #ed594b;
        background: linear-gradient(180deg, #fa7c28 0%, #b1411f 100%);
        box-shadow: 0 2px 3px 0 rgba(250, 124, 40, 0.5) !important;
      }
    }
  }
}

.btn-outline-primary.focus,
.btn-outline-primary:focus {
  box-shadow: none;
}

.react-datepicker-wrapper {
  display: block;
  padding: 0;
  border: 0;

  .react-datepicker__input-container {
    position: relative;
    display: block;
    width: initial;
  }
}

body {
  /* prettier-ignore */
  h1, h2, h3, h4, h5, h6 {
    color: $typography-font-color-normal;
    margin-top: 0.25em;
    margin-bottom: 0.15em;
  }

  /* prettier-ignore */
  & {
    h1 { font-size: 3rem; font-weight: $typography-font-weight-normal; }
    h2 { font-size: 2.6rem; }
    h3 { font-size: 2.2rem; }
    h4 { font-size: 1.8rem; }
    h5 { font-size: 1.5rem; }
    h6 { font-size: 1.25rem; }
  }

  button.link {
    background: none;
    border: none;
    padding: 0;
    margin: 0;
  }

  /* prettier-ignore */
  a, a:link, a:hover, a:active, .link,
  a:not([href]):not([tabindex]) {
    color: $color-blue-dg;
    text-decoration: none;
    cursor: pointer;
  }

  /* prettier-ignore */
  a:hover, .link:hover,
  a:not([href]):not([tabindex]):focus, a:not([href]):not([tabindex]):hover {
    color: $color-blue-dark;
  }
  .link.disabled {
    opacity: 0.5;
  }
}

ul.unstyled {
  @include unstyled-list;
}
