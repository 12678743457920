@import 'scss/vars.scss';

.bot-file-chip {
  display: inline-flex;
  align-items: center;
  padding: 4px 8px;
  color: $color-white;
  font-weight: $typography-font-weight-bolder;
  font-size: 12px;
  letter-spacing: 0.04em;
  background-color: $color-blue-dg;
  border: 2px solid $color-blue-dg;
  border-radius: 100px;

  cubes-icon {
    width: 14px;
    height: 14px;
    margin-right: 10px;
  }
}

.bot-ran-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 40px;
  color: $color-white;
  background-color: $color-green-dg;
  border-radius: 6px;

  img {
    width: 18px;
    height: 18px;
  }
}

.bot-informational-card {
  display: inline-flex;
  align-items: center;
  width: 200px;
  height: 80px;
  min-height: 80px;
  padding: 8px 16px;
  color: $color-white;
  font-weight: $typography-font-weight-bolder;
  font-size: 13px;
  letter-spacing: 0.04em;
  background-color: $color-blue-dg;
  border-color: $color-blue-dg;
  border-style: solid;
  border-width: 1px;
  border-radius: 6px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);
  transition: background-color 0.1s ease-in-out, color 0.1s ease-in-out;

  .cubes-icon {
    width: 24px;
    height: 24px;
    margin-right: 12px;
  }

  &:hover {
    background-color: $color-blue-light;
  }

  &__ran {
    background-color: $color-green-dg;
    border-color: $color-green-dg;

    &:hover {
      background-color: $color-green-2;
    }
  }

  &--flipped {
    height: 100%;
    color: $color-text;
    font-weight: $typography-font-weight-normal;
    background-color: $color-white;

    &:hover {
      background-color: $color-white;
    }
  }
}

.bai-tooltip {
  .tooltip-inner {
    padding: 10px 16px;
    font-weight: $typography-font-weight-bolder;
    font-size: 12px;
    line-height: 130%;
    letter-spacing: 0.03em;
    background-color: $color-text;
    border-radius: 6px;
  }

  &.bs-tooltip-top {
    margin-bottom: 3px;
  }

  &.bs-tooltip-bottom {
    margin-top: 3px;
  }

  &.bs-tooltip-left {
    margin-right: 3px;
  }

  &.bs-tooltip-right {
    margin-left: 3px;
  }
}
