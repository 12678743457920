@import 'scss/vars.scss';

.modal_image__light_box_wrapper .__react_modal_image__medium_img {
  max-height: 90%;
}
.bai-card {
  align-items: center;
  width: 100%;
  max-width: 400px;
  height: auto;
  max-height: 312px;
  background-color: $color-white;
  border: 1px solid $color-grey-dg;
  border-radius: 8px;
  box-shadow: 0px 2px 6px rgba(30, 44, 71, 0.04);

  &.clickable {
    backface-visibility: hidden;
    cursor: pointer;
    transition: all 0.25s ease-in-out;
    -webkit-font-smoothing: subpixel-antialiased;
    will-change: transform;

    &:hover {
      box-shadow: 0 0 4px $color-yellow-dg-light;
      transform: scale(1.02) translate3d(0, 0, 0) perspective(1px);
      .bai-card-icon {
        color: $color-orange-dg;
      }
    }
  }

  .bai-card-body {
    height: 312px;
    padding: 1em;

    .bai-card-icon {
      @include flex;
      align-items: center;
      justify-content: center;
      height: 190px;
      color: $color-yellow-dg;
      font-size: 5em;
    }

    .bai-card-header {
      margin-top: 0.5em;
      text-align: center;
    }

    .bai-card-description {
      max-width: 18em;
      margin-top: 0.5em;
      margin-right: auto;
      margin-left: auto;
      color: $color-text;
      text-align: center;
    }
  }
}
