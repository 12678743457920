@mixin loading-ovly {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.85);
  cursor: wait;
  overflow: none;

  .loading-spinner {
    display: block;
    margin: 0;
  }
}

@mixin unstyled-list {
  margin: 0;
  padding: 0;
  list-style: none;
  > li {
    margin: 0;
    padding: 0;
  }
}

@mixin break-word-wrap {
  word-wrap: break-word;
  word-break: break-word;
  overflow-wrap: break-word;
}

@mixin user-select-none {
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

@mixin button-reset {
  width: auto;
  margin: 0;
  padding: 0;
  overflow: visible;
  color: inherit;
  font: inherit;
  line-height: normal;
  background: transparent;
  border: none;
}

@mixin flex {
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  justify-content: flex-start;
}

@mixin ie11-hack {
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    @content;
  }
}

@mixin block {
  background: $color-white;
  border: 1px solid $color-grey-300;
  border-radius: 0.5em;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.1);
}
@keyframes pulse {
  0%,
  50%,
  100% {
    opacity: 1;
  }
  25%,
  75% {
    opacity: 0;
  }
}
@keyframes orangeToOrangeNotifyPulse {
  0% {
    border: 1px solid $color-orange-dg;
    box-shadow: 0 0 0 0 rgba($color-orange-dg, 0.8);
  }

  30% {
    color: $color-orange-dg;
    border: 1px solid $color-orange-dg;
    box-shadow: 0 0 0 5px rgba($color-orange-dg, 0.1);
  }
  100% {
    border: 1px solid $color-orange-dg;
    box-shadow: 0 0 0 0 rgba($color-orange-dg, 0);
  }
}

@keyframes greyToOrangeNotifyPulse {
  0% {
    border: 1px solid $color-grey-550;
    box-shadow: 0 0 0 0 rgba($color-grey-550, 0.7);
  }

  30% {
    color: $color-orange-dg;
    border: 1px solid $color-orange-dg;
    box-shadow: 0 0 0 5px rgba($color-orange-dg, 0.1);
  }
  100% {
    border: 1px solid $color-grey-550;
    box-shadow: 0 0 0 0 rgba($color-grey-550, 0);
  }
}
