@import 'scss/vars.scss';

$_slug: 'gap-analysis';
$_block_padding: 1.5rem;

.databot-config-panel.databot-config-panel-#{$_slug} {
  .databot-config-panel-body {
    .databot-config-panel-body-content {
      > section {
        &:not(:first-child) {
          margin-top: #{$_block-padding * 2};
        }

        .styled-multiselect-container {
          overflow-wrap: anywhere;
        }

        > .title {
          font-size: 1.5rem;
          font-weight: $typography-font-weight-bolder;
        }

        > .content {
          margin-top: $_block-padding;
        }

        &.primary-file-selection,
        &.secondary-file-selection {
          > .content {
            > :not(:first-child) {
              margin-left: $_block-padding;
            }

            .styled-multiselect-container {
              width: 25%;
              vertical-align: middle;
            }
          }
        }

        &.header-selection {
          > .note {
            @include flex;
            color: $color-grey-550;
          }

          > .content {
            @include flex;
            align-items: flex-end;
            justify-content: space-between;

            .header-selector {
              flex: 0 0 25%;
              vertical-align: middle;

              .file-label {
                font-weight: $typography-font-weight-bold;
              }

              .styled-multiselect-container {
                margin-top: 0.5em;
              }
            }

            > .icon-divider {
              padding: 0 $_block-padding;
              .icon {
                color: $color-grey-500;
                font-size: 2rem;
              }
            }
          }
        }
      }
    }
    .databot-config-panel-actions {
      margin-top: 4rem;
    }
  }
}
