@import 'scss/vars.scss';

.img-bot-engine-log-table {
  position: relative;
  min-height: 10em;

  .react-bootstrap-table {
    /* prettier-ignore */
    thead {
      tr {
        th {
          .label {
            color: $color-grey-550;
          }
        }
        th.job_id { width: 7em; }
        th.file { width: 15em; }
        th.updated_at { width: 7em; }
        th.start_by { width: 15em; }
        th.status { width: 15em; }
        th.cds-actions { width: 15em; .th-wrap { justify-content: flex-end; } }
      }
    }

    tbody {
      tr {
        td {
          &.file {
            .file-block {
              .file-block-name {
                @include flex;
                .fname {
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  display: inline-block;
                  vertical-align: middle;
                }
                .fext {
                  white-space: nowrap;
                  vertical-align: middle;
                }
              }

              .file-block-img-count {
                font-size: 0.85em;
                margin-top: 0.25em;
                .label {
                  color: $color-grey-500;
                }
                .value {
                  margin-left: 0.5em;
                  font-weight: $typography-font-weight-bolder;
                  color: $color-grey-500;
                }
              }
            }
          }
          &.user {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          &.status {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

            .bot-name {
              color: #000;
              font-weight: $typography-font-weight-bolder;
              white-space: normal;
              line-height: 1.2rem;
            }

            .status-block {
              font-family: 'Inter', sans-serif;
              white-space: normal;
              margin-top: 0.25em;
              font-weight: $typography-font-weight-bolder;

              &-info {
                display: flex;
                align-items: center;
                font-weight: $typography-font-weight-bolder;
                line-height: 1.2rem;
                color: $color-grey-500;

                .icon {
                  margin-right: 0.25em;
                  .svg-inline--fa {
                    color: $color-grey-500;
                  }

                  &.failed .svg-inline--fa {
                    color: $color-red-dg;
                  }

                  &.rejected .svg-inline--fa {
                    color: $color-red-dg;
                  }

                  &.completed .svg-inline--fa {
                    color: $color-green-dg;
                  }

                  &.pending .svg-inline--fa {
                    color: $color-orange-dg;
                  }

                  &.running .svg-inline--fa {
                    color: $color-blue-dg;
                  }

                  &.under_review .svg-inline--fa {
                    color: $color-blue-dg;
                  }
                }

                .status-block-phase {
                  font-weight: $typography-font-weight-bolder;
                  line-height: 1.2rem;
                  color: $color-grey-500;
                }
                .status-block-status {
                  font-weight: $typography-font-weight-bolder;
                  line-height: 1.2rem;
                  color: $color-grey-500;
                  margin-top: 0.25em;
                  gap: 4px;
                  &-progress {
                    margin-left: 0.25rem;
                  }
                }
              }
            }
          }
          &.cds-actions {
            .btn {
              .label {
                display: none;
              }
            }
          }
        }
      }
    }
  }

  .loading-spinner-wrap {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  &.is-loading {
    .react-bootstrap-table {
      opacity: 0.35;
    }

    .loading-spinner-wrap {
      display: block;
      .load-logo-spinner-wrapper {
        margin-top: 2em;
      }
    }
  }
}

.btn-start-first-ids-job {
  margin-left: 0.5em;
  .label {
    display: none;
  }
}
