@import "scss/vars.scss";

$_slug: "premium-short-description-creator";
$_block_padding: 1.5rem;

.databot-config-panel.databot-config-panel-#{$_slug} {
  .databot-config-panel-body {
    .databot-config-panel-body-content {
      > section {
        &:not(:first-child) {
          margin-top: #{$_block-padding * 2};
        }
        > .title {
          font-size: 1.5rem;
          font-weight: $typography-font-weight-bolder;
        }
        > .content {
          margin-top: $_block-padding;
        }
        &.file-selection,
        &.descr-length-selection {
          > .content {
            > :not(:first-child) {
              margin-left: $_block-padding;
            }

            .styled-multiselect-container {
              width: 25%;
              vertical-align: middle;
            }
          }
        }
        &.descr-length-selection {
          .char-limit {
            width: 25%;

            &-label {
              display: block;
            }

            &-input {
              display: block;
              width: 100%;
              padding: 10px 15px;
              border: 1px solid $color-grey-dg;
              border-radius: 8px;

              &::placeholder {
                color: $color-grey-550;
              }

              &::-webkit-outer-spin-button,
              &::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
              }

              &.invalid {
                color: $color-red-dg;
                border-color: $color-red-dg;
              }

              -moz-appearance: textfield;
            }

            &-invalid {
              color: $color-red-dg;
              font-weight: $typography-font-weight-bold;
              font-size: 0.85rem;
              margin-top: 0.5rem;
            }
          }
        }
        &.header-selection {
          > .content {
            .header-selector {
              .header-selector-list {
                @include unstyled-list;

                .header-selector-list-item {
                  @include flex;
                  flex-direction: column;
                  label {
                    input + span.label {
                      margin-left: 0.3em;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
