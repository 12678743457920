@import "scss/vars.scss";

.main-metrics-card {
    flex: 1 1 auto;
    width: 100%;
    max-width: 290px;
    height: 90px;
    padding: 15px 25px;
    color: $color-text;
    font-size: 28px;
    background-color: $color-white;
    border-radius: 8px;
    box-shadow: 0px 2px 6px rgba(30, 44, 71, 0.04);

    span {
        font-weight: $typography-font-weight-bolder;
    }

    h2 {
        margin: 0;
        font-weight: $typography-font-weight-normal;
        font-size: 18px;
    }
}
