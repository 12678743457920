body .hdr {
  background: $color-white;
}

.hdr-row {
  &.hdr-row-top {
    position: relative;
    z-index: 10;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    box-shadow: 0 0 25px rgba(0, 0, 0, 0.25);

    .hdr-block-logo-backbone,
    .hdr-block-logo-tmp {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: flex-start;

      img {
        display: block;

        &.logo-tmp {
          max-width: 200px;
          max-height: 80px;
        }
        &.logo-backbone {
          width: 180px;
        }
        &.logo-backbone-small {
          height: 40px;
        }
      }
    }

    .hdr-block-search {
      display: flex;
      flex: 1 1 100%;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;
      margin-right: 1.5rem;
      margin-left: 1.5rem;

      .header-text-search {
        width: 100%;
        max-width: 500px;
      }

      .hdr-search-form {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: flex-start;

        .form-control {
          width: 20em;
          border-radius: $border-radius-md 0 0 $border-radius-md;
        }

        .btn-primary {
          background-color: #ced4da;
          border-color: #ced4da;
          border-radius: 0 $border-radius-md $border-radius-md 0;
          cursor: pointer;
        }
      }
    }

    .hdr-block-user {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: flex-start;
      margin-right: 1.5rem;

      .user-notifications-wrap {
        margin-right: 1.5rem;
      }

      .user-profile-circle-wrap {
        position: relative;
        cursor: pointer;
        opacity: 0.85;
        transition: all 0.2s;

        .user-profile-circle {
          width: 3rem;
          height: 3rem;
          box-shadow: 2px 2px 10px -2px rgba(0, 0, 0, 0.1);
        }

        &::after {
          position: absolute;
          right: -5px;
          bottom: 0;
          display: block;
          width: 0;
          height: 0;
          border-color: transparent transparent transparent transparent;

          border-style: solid;
          border-width: 5px 5px 0 5px;
          transition: all 0.2s;
          content: '';
        }

        &:hover {
          opacity: 1;

          &::after {
            border-color: #aaa transparent transparent transparent;
          }
        }
      }
    }
  }

  &.hdr-row-nav {
    background: $color-logo-blue;
    .hdr-block-main-menu {
      @include flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;

      .main-menu {
        .main-menu-items {
          display: flex;
          flex-flow: row wrap;
          align-items: center;
          justify-content: flex-start;
          margin: 0;
          padding: 0;
          list-style: none;

          .main-menu-item {
            position: relative;
            border-bottom: 3px solid transparent;
            padding-top: 3px;
            opacity: 0.85;

            a {
              display: flex;
              align-items: center;
              padding: 0.5rem 0;
              color: $color-white;
              text-decoration: none;

              .icon {
                margin-right: 0.5rem;
                font-size: 1.1rem;
              }

              .label {
                font-weight: $typography-font-weight-bold;
                font-size: 0.85rem;
                text-transform: uppercase;
              }
            }

            &:not(:last-child) {
              margin-right: 2rem;
            }

            &:hover {
              opacity: 1;
            }

            &.active {
              border-bottom: 3px solid $color-orange-main;
              opacity: 1;
            }
          }
        }
      }

      .request-data {
        .btn.btn-request-data {
          font-size: 0.85rem;
        }
      }
    }
  }

  &.hdr-row-breadcrumbs {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    background: #f5f5f5;

    .hdr-block-breadcrumbs {
      font-size: 0.8rem;
      text-transform: uppercase;

      .breadcrumb-divider {
        display: inline-block;
        margin-right: 0.5em;
        margin-left: 0.5em;
      }
    }
  }

  .content {
    display: flex;
    flex-flow: row nowrap;
    align-items: stretch;
    justify-content: flex-start;
  }
}

.hdr-block {
  &.hdr-block-logo-tmp {
    flex: 1 0 auto;
  }
}

.hdr-user-profile-menu {
  z-index: 100;
  min-width: 250px;
  margin-top: 0.25em;
  margin-left: 0.5em;
  background: $color-white;
  border: 1px solid #ced4da;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.15);

  .user-menu-items {
    margin: 0;
    padding: 0;
    list-style: none;

    .user-menu-item {
      &:not(:first-child) {
        border-top: 1px solid #f5f5f5;
      }

      &.user-menu-item-title {
        > span {
          display: block;
          padding: 0.5rem 1.5rem;
          color: $color-grey-500;
          font-weight: $typography-font-weight-bold;
          font-size: 0.85rem;
          text-transform: uppercase;
          background: $color-grey-25;
        }

        &.role-supplier > span {
          background: $color-alt-1;
        }

        &.role-client > span {
          background: $color-alt-2;
        }
      }

      a,
      .link {
        display: block;
        padding: 0.5rem 1.5rem;
        color: $color-grey-2;
        text-decoration: none;
        cursor: pointer;

        .icon {
          margin-right: 0.5rem;
          font-size: 1.1rem;
        }

        .label {
          font-weight: $typography-font-weight-bold;
          font-size: 0.85rem;
          text-transform: uppercase;
        }

        &:hover {
          color: $color-black;
          background: #f5f5f5;
        }
      }
    }
  }
}

// --- generic dropdown - TODO: UNIFY

.dropdown-menu {
  min-width: 250px;
  margin-top: 0.25em;
  margin-bottom: 2em;
  margin-left: 0.5em;
  padding: 0;
  background: $color-white;
  border: 1px solid #ced4da;
  border-radius: 0;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.15);

  .dropdown-item {
    display: block;
    padding: 0.5rem 1.5rem;
    color: $color-black;
    text-decoration: none;

    &:not(:first-child) {
      border-top: 1px solid #f5f5f5;
    }

    .icon {
      margin-right: 0.5rem;
      font-size: 1.1rem;
    }

    .label {
      font-weight: $typography-font-weight-bold;
      font-size: 0.85rem;
      text-transform: uppercase;
    }

    &:hover {
      color: $color-black;
      background: #f5f5f5;
    }
  }
}

@media print {
  .hdr-row.hdr-row-nav {
    background: $color-white;
  }

  .hdr-row.hdr-row-nav .main-menu-items .main-menu-item a {
    color: $color-black;
  }
}
