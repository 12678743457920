.component-spinner {
  z-index: 100;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  text-align: center;
  background: rgba(255, 255, 255, 0.85);
  cursor: wait;
  .component-spinner-content {
    opacity: 0.5;
  }
  .component-spinner-loading-spinner {
    font-size: 8em;
  }
  .component-spinner-label {
    font-size: 1em;
    width: 100%;
    margin: auto;
    margin-top: 3em;
  }
}

// this is janky - but the bootstrap table lib has to have a noDataIndication prop to allow an overlay with pagination
.tableNoDataLoader {
  opacity: 1;
  background: rgba(255, 255, 255);
  z-index: 0;
  overflow-y: hidden;
  margin: auto;
  height: 100%;
  transform: translateY(-1em);
}
