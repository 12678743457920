.view-metrics,
.view-client-metrics,
.view-manufacturer-metrics,
.view-customer-manufacturer-metrics,
.view-customer-distributor-metrics,
.view-weekly-metrics {
  margin-top: 40px;
  margin-bottom: 180px;
}

.view-client-metrics,
.view-manufacturer-metrics,
.view-customer-manufacturer-metrics,
.view-customer-distributor-metrics {
  .metrics-overview {
    min-height: 280px;
  }
}

.view-customer-manufacturer-metrics,
.view-customer-distributor-metrics {
  .metrics-history-table .react-bootstrap-table th:hover,
  .metrics-table .react-bootstrap-table td:hover {
    white-space: nowrap;
    word-wrap: initial;
    word-break: initial;
    overflow-wrap: initial;
  }
}

.view-weekly-metrics {
  display: flex;
  flex-direction: column;
  .metrics-overview {
    margin-bottom: 5px;
  }
}

.metrics-overview {
  display: flex;
  flex-direction: column;
  margin-bottom: 60px;
  color: $color-text;

  .main-metrics-card,
  .company-metrics-card {
    margin-right: 16px;
    margin-left: 16px;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }

  &-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;

    &-cards {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: space-between;
      width: calc(100% + 16px);
      margin: 0px 0 0 -16px;
      overflow: auto;
    }
  }
}

.metrics-details {
  &-header {
    margin-bottom: 10px;
  }

  &-heading {
    color: $color-text;
    font-size: 28px;
  }

  &-subheading {
    color: $color-grey-550;
    font-size: 13px;
  }

  &-group {
    display: flex;
    flex-direction: column;
    margin-bottom: 35px;

    .metrics-table {
      margin-bottom: 0;
    }

    &-item {
      margin-top: 5px;
      margin-left: auto;
    }
  }
}

@media (max-width: 1300px) {
  .metrics-overview {
    flex-wrap: wrap;
    &-main {
      flex: 100%;
      margin-right: 0;
    }

    .company-metrics-card {
      margin-top: 20px;
    }

    .company-metrics-card:first-of-type,
    .main-metrics-card:first-of-type {
      margin-left: 0;
    }

    .main-metrics-card {
      height: 90px;
      margin-top: 20px;
    }
  }
}
