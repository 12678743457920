@import 'scss/vars.scss';

.file-upload-template-wrapper {
  .template-file-modal-panel {
    .modal-body {
      .share_url {
        @include flex;
        align-items: center;

        > input {
          flex: 1 1 auto;
        }

        > button {
          flex: 0 0 auto;
          margin-left: 0.5em;
          white-space: nowrap;
        }
      }
    }
  }

  .template-upload-attachment {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 13rem;
    background: $color-grey-50;
    border: 2px dashed $color-grey-400;
    border-radius: 8px;
    cursor: pointer;
    &:hover {
      border-color: $color-grey-500;
    }
  }

  .disabled-div {
    pointer-events: none;
    opacity: 0.6;
  }

  .template-upload-attachment__folder-icon {
    --fa-primary-opacity: 1;
    --fa-secondary-opacity: 1;
    --fa-primary-color: #{$color-grey-550};
    --fa-secondary-color: #{$color-yellow-dg};
    margin-bottom: 0.5rem;
  }

  .template-upload-attachment__folder-icon--IE11 {
    @extend .template-upload-attachment__folder-icon;
    color: $color-grey-550;
    .fa-secondary {
      color: $color-yellow-dg;
      opacity: 1;
    }
  }

  .template-upload-attachment__download-icon {
    margin-bottom: 0.5rem;
    color: #{$color-grey-550};
  }

  .template-upload-attachment__check-icon {
    margin-bottom: 0.5rem;
    color: #{$color-green-dg};
  }

  .template-upload-attachment__btn-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  .template-upload-attachment__btn {
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    width: 180px;
    min-height: 45px;
    margin-right: 1rem;
    margin-bottom: 0.5rem;
    margin-left: 1rem;
    padding: 10px 15px;
    color: $color-text;
    font-weight: $typography-font-weight-normal;
    font-size: 15px;
    background-color: $color-white;
    border: 1px solid $color-grey-dg;
    border-radius: 6px;
    box-shadow: 0 1px 3px 0 rgba(223, 225, 228, 0.5);
    cursor: pointer;
    &:hover {
      background-color: $color-grey-50;
    }
  }

  .template-upload-attachment__info {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    text-align: center;
    &-txt {
      width: 100%;
      color: $color-text;
      font-size: $typography-font-size-root;
    }
    &-subtxt {
      color: $color-grey-550;
      font-size: 12px;
    }
    &-headertxt {
      color: $color-text;
      font-weight: $typography-font-weight-bold;
      font-size: 18px;

      &-action {
        font-weight: bolder;
      }
    }
  }

  .template-upload-attachment__info-icon {
    margin-right: 15px;
    padding-bottom: 2px;
    background-color: $color-white;
    border: 1px solid $color-grey-dg;
    border-radius: 50%;
    box-shadow: 0 1px 3px 0 rgba(223, 225, 228, 0.5);
  }

  .template-upload-attachment__info-actions {
    display: flex;
    align-content: space-evenly;
    justify-content: space-evenly;
    margin-top: 1rem;
    margin-bottom: 1.5rem;
    color: $color-text;
    font-weight: $typography-font-weight-bold;
    font-size: 18px;

    &-group {
      display: inline-flex;
      align-items: center;
      margin: 0 25px;
      font-weight: $typography-font-weight-normal;
      font-size: 15px;
      user-select: none;

      &__radio {
        display: flex;
        padding-right: 10px;
      }

      &__label {
        margin-bottom: 0;
        color: $color-text;
      }
    }
  }

  .template-upload-attachment__progress-wrapper {
    width: 70%;
    margin: 0 auto;

    .progress {
      height: 1.4rem;
      font-size: 0.85rem;
      background-color: $color-grey-dg-light;
      border-radius: 1.15rem;
      box-shadow: 0 2px 3px 0 rgba(200, 201, 202, 0.5);
    }

    .progress-bar {
      background-color: $color-green-dg;
    }
  }
}

.upload-content {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  margin-top: 0.5rem;
  border: 2px dashed $color-grey-400;
  border-radius: 8px;
  padding: 1rem;
  background: $color-grey-50;

  .upload-files-items {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #00135c;

    button {
      border: none;
    }

    .file-name-wrapper {
      flex: 1;
      text-align: left;
      margin-right: 2rem;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.upload-disabled {
  pointer-events: none;
  opacity: 0.6;
}

.template-upload-attachment__link:link {
  color: $color-blue-link;
  text-decoration-color: $color-blue-link;
  box-shadow: 0px 2px 0px $color-blue-link;
  &:hover &:active {
    color: $color-blue-link;
    text-decoration-color: $color-blue-link;
    box-shadow: 0px 2px 0px #5797cf;
  }
}
