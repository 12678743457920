@import 'scss/vars.scss';

.databot-approval-panel {
    margin-top: 20px;

    p {
        font-weight: $typography-font-weight-bolder;
        font-size: 16px;
        color: $color-text;
        margin: 1.25em 0;
    }

    &-table {
        display: flex;
        flex-direction: column;
        margin-top: 20px;

        .react-bootstrap-table {
            border-top-left-radius: 8px;
            border-top-right-radius: 8px;

            .actions button {
                @include button-reset;
                padding: 8px 16px;
                color: $color-text;
                font-weight: $typography-font-weight-bolder;
                font-size: 12px;
                background-color: $color-white;
                border: 1px solid #9aa4ac;
                border-radius: 6px;
                box-shadow: 0px 2px 6px rgba(0, 19, 92, 0.06);
                transition: all;
                transition-timing-function: ease-in-out;
                transition-duration: 150ms;

                &:hover {
                    color: $color-white;
                    background-color: $color-blue-dg;
                    border-color: $color-blue-dg;
                }
            }

            .actions {
                text-align: right;
            }

            thead {
                border-bottom: 1px solid #e5e7eb;
                th {
                    padding: 1em 0.25em;
                }
                tr {
                    font-size: 12px;
                    letter-spacing: 0.05em;
                    text-transform: uppercase;
                    background-color: #f9fafb;
                }
            }

            thead tr th .th-wrap .label {
                color: #6b7280;
                font-weight: $typography-font-weight-bold;
            }

            thead tr th:first-child,
            tbody tr td:first-child,
            tfoot tr th:first-child {
                padding-left: 1.5em;
            }

            thead tr th:last-child,
            tbody tr td:last-child,
            tfoot tr th:last-child {
                padding-right: 1.5em;
            }

            td {
                padding: 0.75em 0.25em;
            }
        }
    }
}
