@import "scss/vars.scss";

.metrics-overview-weekly {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  min-height: 100%;
  margin-bottom: 30px;

  h1 {
    margin: 0;
    color: $color-text;
    font-weight: $typography-font-weight-normal;
    font-size: 40px;
  }

  #weekpicker-container {
    order: 1;
  }
}

.metrics-overview-row-loading {
  margin: auto;
}
