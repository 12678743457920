@import 'scss/vars.scss';

.data-lakehouse-dashboard {
  $_block-padding: 1em;
  @mixin _kv-title {
    color: $color-blue-dg;
    font-weight: $typography-font-weight-bolder;
    font-size: 1.2em;
  }

  .details-loading-wrapper {
    display: flex;
    justify-content: center;
  }

  @media (max-width: 1200px) {
    .data-lakehouse-dashboard-databots {
      .databots-list-wrap {
        .databots-list {
          .databot-item {
            flex: 0 0 calc(33.33% - #{$_block-padding});
          }
        }
      }
    }
  }

  @media (max-width: 900px) {
    .data-lakehouse-dashboard-summary {
      .fill-rate-summary {
        > section:nth-child(2) {
          flex-basis: 20em;
        }
      }
    }

    .data-lakehouse-dashboard-databots {
      .databots-list-wrap {
        .databots-list {
          .databot-item {
            flex: 0 0 calc(50% - #{$_block-padding});
          }
        }
      }
    }
  }

  @media (max-width: 700px) {
    .data-lakehouse-dashboard-summary {
      .fill-rate-summary {
        > section:nth-child(2) {
          flex-basis: 18em;

          .bai-circular-progressbar.semi-circle .bai-circular-progressbar-label {
            margin-top: -7em;

            .value {
              font-weight: $typography-font-weight-boldest;
              font-size: 2em;
            }

            .status-label-wrap {
              margin-top: 0.5em;
              .status-label {
                font-size: 0.65em;
              }
            }
          }
        }
      }
    }
  }

  .manufacturer-select {
    @include flex;

    > div {
      flex: 0 0 100%;
      max-width: 25em;

      &:not(:first-child) {
        margin-left: 1em;
      }
    }

    .title {
      margin-bottom: 0.5em;

      .loading-spinner {
        margin-left: 0.5em;
        font-size: 1.25em;
      }
    }
  }

  > section {
    margin-top: 2em;
    .heading {
      @include flex;
      align-items: center;

      .title {
        flex: 1 1 auto;
        font-size: 2em;

        .loading-spinner {
          margin-left: 0.5em;
        }
      }

      .actions {
        margin-left: 1em;

        .btn:not(:disabled):not(.disabled):active {
          border-color: $color-green-2;
          color: $color-green-2;
        }
        .btn:not(:first-child) {
          margin-left: 0.5em;
        }
      }
    }
  }

  .data-lakehouse-dashboard-summary {
    margin-top: 2em;

    .fill-rate-summary {
      @include block;
      @include flex;

      > section:nth-child(1) {
        flex: 1 1 auto;
        padding: $_block-padding;

        .title {
          font-weight: $typography-font-weight-bolder;
          font-size: 2em;
        }

        .summary-data {
          @include flex;
          margin-top: 1.5em;

          > .kv-pair {
            &:not(:first-child) {
              margin-left: 1em;
            }

            .label {
              @include _kv-title;
            }

            .value {
              font-weight: $typography-font-weight-bolder;
              font-size: 3em;
              line-height: 1.2;
            }
          }
        }
      }

      > section:nth-child(2) {
        flex: 0 0 27em;
        align-self: flex-end;
        padding: $_block-padding $_block-padding 0 $_block-padding;
      }
    }

    .databots-summary {
      @include flex;
      align-items: stretch;
      justify-content: space-between;
      margin-top: 2em;

      > .bai-value-block {
        @include block;
        width: 24%;
        max-width: 30em;
        padding: $_block-padding;

        > .title {
          @include flex;
          align-items: center;
          .label {
            @include _kv-title;
            flex: 1 1 auto;
          }

          .title-tooltip {
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            justify-content: center;
            width: 1.5em;
            height: 1.5em;
            padding: 0.3em;
            border: 1px solid $color-grey-400;
            border-radius: 50%;
            box-shadow: 0 1px 3px 0 $color-grey-500;
            cursor: default;

            .icon {
              color: $color-grey-550;
              font-size: 0.85em;
            }
          }
        }

        > .value {
          margin-top: 0.25em;
          font-weight: $typography-font-weight-bolder;
          font-size: 2.5em;
          line-height: 1.2;
        }

        > .subtitle {
          @include _kv-title;
          color: $typography-font-color-normal;
          font-size: 1em;
          text-transform: uppercase;
        }

        > .additional-data {
          margin-top: 0.5em;
          color: $color-grey-500;
          font-weight: $typography-font-weight-bolder;
          line-height: 1.2;

          .value {
            font-weight: $typography-font-weight-boldest;
          }
        }
      }
    }
  }

  .summary-company {
    @include flex;
    align-items: stretch;

    > div {
      &:nth-child(2n) {
        margin-left: 1em;
      }

      &.company-info-summary {
        flex: 2 1 50%;
        @include block;
        padding: 1em;
        .bai-value-block {
          padding: 0;
          &:not(:first-child) {
            margin-top: 1em;
          }
          &.main-contact {
            .value {
              font-size: 1em;
            }
          }
        }
      }

      &.databots-stats {
        flex: 1 1 50%;
        @include flex;
        flex-wrap: wrap;
        align-items: stretch;

        > .bai-value-block {
          flex: 0 0 calc(50% - 0.5em);

          &:nth-child(2n) {
            margin-left: 1em;
          }

          &:nth-child(n + 3) {
            margin-top: 1em;
          }

          .value {
            font-size: 1.5em;
          }
        }
      }
    }
    @media (max-width: 800px) {
      flex-wrap: wrap;
      > div {
        &:nth-child(2n) {
          margin-left: 0;
        }

        &.company-info-summary,
        &.databots-stats {
          flex: 0 0 100%;
        }

        &.databots-stats {
          margin-top: 1em;
        }
      }
    }
  }

  .data-lakehouse-dashboard-databots {
    .databots-list-wrap {
      margin-top: 1em;

      .databots-list {
        @include unstyled-list;
        @include flex;
        display: inline-flex;
        flex-wrap: wrap;
        align-items: stretch;
        width: calc(100% + #{$_block-padding});

        margin: calc(-1 * #{$_block-padding}) 0 0 calc(-1 * #{$_block-padding});

        .databot-item {
          flex: 0 0 calc(25% - #{$_block-padding});
          margin: $_block-padding 0 0 $_block-padding;

          .databot {
            width: auto;
            margin: 0;
          }
        }
      }
    }
  }

  > section.transformed-files {
    .transformed-files-wrap {
      margin-top: 1em;
    }
  }

  > section.source-files {
    .source-files-wrap {
      margin-top: 1em;
    }
  }

  > section.data-jobs {
    .data-jobs-wrap {
      margin-top: 1em;
    }
  }

  > section.image-quality {
    .data-jobs-wrap {
      margin-top: 1em;
    }
  }

  > section.img-bot-engine {
    .heading {
      margin-bottom: 1em;
    }
  }

  > section.assessments {
    .assessments-wrap {
      margin-top: 1em;

      .assessments-details {
        .assessments-card {
          margin-bottom: 0;
          &:not(:first-child) {
            margin-top: 1em;
          }
        }
      }
    }

    &.empty {
      display: none;
    }
  }
}
