@import "scss/vars.scss";

.weekpicker-input-wrapper {
  position: relative;

  &-input {
    width: 200px;
    height: 40px;
    padding: 5px 0 5px 38px;
    color: transparent;
    font-weight: $typography-font-weight-bolder;
    font-size: 15px;
    text-shadow: 0 0 0 $color-text; // Hack to disable the cursor on internet explorer
    background-color: $color-white;
    border: solid 1px $color-grey-dg;
    border-radius: 6px;
    box-shadow: 0 1px 3px 0 rgba(223, 225, 228, 0.5);
    cursor: pointer;
    &::-ms-clear {
      display: none;
    }
  }

  .fa-calendar-alt {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 16px;
  }
}

.weekpicker-wrapper {
  .react-datepicker {
    display: flex;
    flex-direction: column;
    width: 350px;
    height: 100%;
    padding: 24px 32px;
    color: #19181a;
    border: $color-white solid 1px;
    border-radius: 10px;
    box-shadow: 0px 30px 84px rgba(19, 10, 46, 0.04), 0px 8px 32px rgba(19, 10, 46, 0.03),
      0px 3px 14px rgba(19, 10, 46, 0.01), 0px 1px 3px rgba(19, 10, 46, 0.03);

    .weekpicker-header {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 13px;

      &-button {
        display: inline-flex;
        align-items: center;
        margin-right: 4px;
        margin-left: 4px;
        padding: 8px 16px;
        color: #19181a;
        font-size: 14px;
        background-color: $color-white;
        border: none;
        border-radius: 8px;
        box-shadow: none;

        &:focus-within {
          color: $color-green-dg;
          background-color: $color-grey-50;
          border: none;
          box-shadow: none;

          svg {
            color: $color-green-dg;
          }
        }

        &:after {
          content: none;
        }

        svg {
          margin-left: 8px;
          color: #bababa;
        }
      }

      .react-datepicker__month {
        width: 100%;
        margin: 0;
        text-align: center;

        .react-datepicker__day-names,
        .react-datepicker__week {
          display: inline-flex;
          justify-content: space-between;
          width: 100%;
        }
      }

      &-selectors {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 12rem;
        margin-bottom: 10px;
      }

      .react-datepicker__day-name,
      .react-datepicker__day,
      .react-datepicker__time-name {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 38px;
        height: 38px;
        margin: 0.1rem;
        color: $color-text;
        line-height: 1.2;
      }

      .react-datepicker__day--disabled,
      .react-datepicker__month-text--disabled,
      .react-datepicker__quarter-text--disabled,
      .react-datepicker__year-text--disabled {
        color: #a3a3a3;
      }

      .react-datepicker__month-container {
        .react-datepicker__header.react-datepicker__header--custom {
          padding-top: 0;
          font-size: 13px;
          background-color: $color-white;
          border-bottom: none;

          .react-datepicker__day-names {
            display: inline-flex;
            justify-content: space-between;
            width: 100%;
            font-weight: $typography-font-weight-bolder;
            text-align: center;
          }

          .weekpicker-header-menu {
            min-width: initial;
            padding: 8px 0;
            border: none;
            box-shadow: 0px 30px 84px rgba(19, 10, 46, 0.06), 0px 8px 32px rgba(19, 10, 46, 0.05),
              0px 3px 14px rgba(19, 10, 46, 0.01), 0px 1px 3px rgba(19, 10, 46, 0.04);

            .dropdown-item {
              color: $color-text;
              border: none;
            }

            .dropdown-item.active {
              color: $color-white;
              font-weight: $typography-font-weight-bolder;
              background-color: $color-green-dg;
            }

            .dropdown-item.active:hover {
              color: $color-white;
            }

            .dropdown-item:hover {
              color: $color-green-dg;
            }
          }
        }

        .react-datepicker__day {
          border-radius: 50%;
        }

        .react-datepicker__day:not(.react-datepicker__day--selected):hover,
        .react-datepicker__day:not(.react-datepicker__day--selected):focus,
        .react-datepicker__day:not(.react-datepicker__day--selected):active {
          color: $color-text;
          background-color: $color-dg-input;
          border-radius: 50%;
        }

        .react-datepicker__day--selected {
          color: $color-white;
          font-weight: $typography-font-weight-bolder;
          background-color: $color-green-dg;
          border-radius: 50%;
        }

        .react-datepicker__day--keyboard-selected,
        .react-datepicker__day--in-range {
          color: $color-white;
          font-weight: $typography-font-weight-bolder;
          background-color: $color-green-dg;
          border-radius: 50%;
        }

        .react-datepicker__day--keyboard-selected:not(.react-datepicker__day--in-range) {
          color: #a3a3a3;
          font-weight: $typography-font-weight-normal;
          background-color: $color-white;

          &:hover,
          &:active,
          &:focus {
            color: #a3a3a3;
            font-weight: $typography-font-weight-normal;
            background-color: $color-white;
          }
        }

        .react-datepicker__day--in-range:not(.react-datepicker__day--range-start):hover {
          color: $color-white !important;
          background-color: $color-green-dg !important;
          cursor: default;
        }

        .react-datepicker__day--in-selecting-range {
          color: $color-white;
          background-color: darken($color-green-dg, 5%);
        }

        .react-datepicker__day--disabled:hover {
          color: #a3a3a3 !important;
          background-color: $color-white !important;
          cursor: default;
        }
      }
    }

    .weekpicker-actions {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 20px;

      &-btn {
        width: 136px;
        height: 40px;
        padding: 8px 16px;
        font-weight: $typography-font-weight-bolder;
        font-size: 14px;
        border: 0;
        border-radius: 8px;

        &--save {
          color: $color-white;
          background-color: $color-green-dg;
        }

        &--cancel {
          background-color: #f8f7fa;
        }
      }
    }
  }
}
