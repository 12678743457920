.feature-flags-main {
  box-sizing: border-box;
  height: 100%;
  padding: 15px 25px 15px 25px;
  background-color: $color-white;
  border-radius: 8px;
  box-shadow: 0 1px 3px 0 rgba(223, 225, 228, 0.5);

  .flag-list {
    .flag-item {
      display: flex;
      flex-direction: column;
      padding-bottom: 1.5em;
      .flag-row {
        display: flex;
        .flag-name {
          flex: 0 0 250px;
        }

        label {
          padding-right: 1em;
          input {
            margin-right: 0.25em;
          }
        }
      }
    }
  }
}
