@import 'scss/vars.scss';

.search-suggestions {
  position: absolute;
  z-index: 50;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 90vw;
  max-height: 400px;
  margin-top: -10px;
  padding: 16px 20px;
  overflow: auto;
  background-color: $color-white;
  border: 1px solid $color-grey-dg;
  border-radius: 6px;
  box-shadow: 0px 30px 84px rgba(19, 10, 46, 0.01), 0px 8px 32px rgba(19, 10, 46, 0.03),
    0px 3px 14px rgba(19, 10, 46, 0.03), 0px 1px 3px rgba(19, 10, 46, 0.03);

  mark {
    padding: 0;
    color: $color-text;
    font-weight: $typography-font-weight-bold;
    text-decoration: underline;
    background-color: $color-white;
  }

  p {
    margin: 0;
    color: $color-text;
  }

  .search-suggestion {
    display: flex;
    flex-direction: column;
    margin-top: 8px;
    margin-bottom: 8px;
    color: $color-text;
    font-size: 15px;
    cursor: pointer;

    &__type {
      color: #b0b2bf;
      font-size: 14px;
    }
  }
}
