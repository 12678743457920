@import 'scss/vars.scss';

.styled-multiselect-container {
  @include ie11-hack;
  display: inline-flex;
  flex: 1 0 auto;
  width: 100%;
  margin-top: auto;
  margin-bottom: auto;
  border-color: $color-grey-300;
  border-style: solid;
  border-width: 0.1rem;
  border-radius: 0.5rem;
  box-shadow: 0 0 1px $color-grey-300;
  &:hover {
    background-color: $color-grey-dg-light;
    border-color: $color-grey-400;
    box-shadow: 0 0 1px $color-grey-400;
  }
  &:focus-within {
    border-color: $color-orange-dg;
    border-width: 0.1rem;
    border-radius: 0.5rem;
    box-shadow: 0 0 3px $color-orange-dg;
  }
}

.styled-multiselect-container-invalid {
  display: inline-flex;
  flex: 1 0 auto;
  width: 100%;
  margin-top: auto;
  margin-bottom: auto;
  border-color: $color-red-dg;
  border-style: solid;
  border-width: 0.1rem;
  border-radius: 0.5rem;
  box-shadow: 0 0 1px $color-red-dg;
  &:hover {
    border-color: $color-grey-400;
    box-shadow: 0 0 1px $color-grey-400;
  }
  &:focus-within {
    border-color: $color-red-dg;
    border-width: 0.1rem;
    border-radius: 0.5rem;
    box-shadow: 0 0 3px $color-red-dg;
  }

  ~ .invalid-feedback,
  ~ .invalid-tooltip {
    display: block;
  }
}
.styled-multiselect-control-label {
  flex: 0 0 auto;
  margin: auto;
  padding-left: 1em;
  color: $color-grey-550;
  vertical-align: middle;
}

.error-note {
  color: $color-red-dg;
  font-size: 0.85em;
  margin-top: 0.2em;
}
