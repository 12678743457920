@import "scss/vars.scss";

.feedback-modal-panel {
    .modal-header {
        padding: 20px 20px 0 20px;
        border-bottom: 0;
        color: $color-text;
    }

    .modal-dialog {
        max-width: 530px;
    }

    .modal-title {
        font-size: 18px;
        line-height: 30px;
        font-weight: $typography-font-weight-bolder;
    }

    .modal-content {
        border: 1px solid $color-grey-dg;
        border-radius: 8px;
        box-shadow: 0 2px 6px 0 rgba(30, 44, 71, 0.2);
    }

    .close {
        color: $color-grey-dg;
        outline: none;
    }

    .modal-body {
        margin: 30px 0;
        font-size: 13px;
        padding: 0 20px 10px 20px;

        .feedback-form {
            display: flex;
            flex-direction: column;
        }

        .label {
            color: $color-text;
            font-weight: $typography-font-weight-bolder;
            margin-bottom: 0.5rem;
        }

        .slate-rte-text-editor,
        .feedback-form-textarea {
            height: 20rem;
            font-size: 15px;
            border: 1px solid $color-grey-dg;
            border-radius: 6px;
            box-shadow: inset 0 1px 2px 0 rgba(223, 225, 228, 0.6);
        }

        .feedback-form-textarea__wrapper {
            position: relative;
            display: flex;
            flex-direction: column;
        }

        .feedback-form-textarea-counter {
            text-align: right;
        }

        .feedback-form-textarea {
            padding: 10px;
            flex: 1 1 auto;
            &::placeholder {
                color: $color-grey-550;
            }
            &:hover {
                background-color: $color-white;
            }
            &-counter {
                color: $color-grey-500;
                margin-top: 2px;
                margin-left: auto;
            }
        }

        .slate-rte-text-editable {
            padding: 10px;
            height: 100%;
            overflow-y: auto;
            overflow-x: hidden;
        }

        .slate-rte-text-editor-wordcount {
            font-size: 13px;
        }
    }

    .modal-footer {
        background-color: $color-dg-input;
        padding: 10px 20px;
        line-height: 30px;
        font-weight: $typography-font-weight-bolder;
        font-size: 15px;
        border-top: 1px solid $color-grey-dg;
        > * {
            margin: 0;
        }

        .footer-btn {
            cursor: pointer;
            border-radius: 0.5rem;
            padding: 0.375rem 0.8rem;
            user-select: none;
            &:first-child {
                margin-right: 10px;
            }

            &--secondary {
                background-color: $color-white;
                border: 1px solid $color-grey-dg;
                box-shadow: 0 1px 3px 0 rgba(223, 225, 228, 0.5);
            }
            &--primary {
                background-color: $color-green-dg;
                color: $color-white;
                border: 1px solid $color-green-2;
                box-shadow: 0 2px 3px 0 rgba(32, 192, 148, 0.2);
                &:hover,
                :focus,
                :active {
                    background: linear-gradient(180deg, #30d0a4 0%, $color-green-dg 100%);
                }
            }
        }
    }
}
