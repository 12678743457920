@import 'scss/vars.scss';

.user-comments-panel {
  .user-comments-list {
    @include unstyled-list;

    li {
      padding: 1em 0.5em;
    }

    li:not(:first-child) {
      padding-top: 1em;
    }

    .user-comment-item:nth-child(2n) {
      background-color: $color-grey-50;
      border-radius: 5px;
    }
    .user-comment-item__editable {
      @include flex;
      flex-direction: row;

      .user-comment {
        flex: 1 1 auto;
        overflow: hidden;
        margin-bottom: 0.5rem;

        .comment-mention {
          padding: 3px 3px 2px;
          margin: 0 1px;
          vertical-align: baseline;
          display: inline-block;
          border-radius: 4px;
          background-color: $color-grey-300;
          font-size: 0.9em;
          font-weight: $typography-font-weight-boldest;
          :focus {
            box-shadow: 0 0 0 2px #b4d5ff;
          }
        }
      }
      .user-comment-actions {
        margin-left: 15px;
        align-content: left;
        flex: 0 0 6rem;
      }
    }
  }

  .user-comment-add {
    @include flex;
    align-items: center;

    margin-top: 1em;
    padding-top: 1em;
    border-top: 1px solid $color-grey-300;

    > div:not(:first-child) {
      margin-left: 1em;
    }

    .add-comment-author {
      flex: 1 1 auto;
      margin: auto;
      align-self: flex-end;
      .user-profile-circle {
        width: 3rem;
        height: 3rem;

        .name-placeholder {
          font-size: 1.1em;
        }
      }
    }
    .add-comment-actions {
      width: 5.5em;
      align-self: flex-end;
      align-content: center;
      flex: 1 1 auto;
    }

    .add-comment-field {
      overflow-x: hidden;
      flex: 1 3 100%;

      .add-comment-textarea {
        color: $color-grey-900;
        font-size: 15px;
        border: 1px solid lightgrey;
        border-radius: 4px;
        background-color: $color-white;
        box-sizing: border-box;
        padding: 3px 10px;
        width: 100%;
      }
    }

    &.focused {
      .add-comment-author {
        margin: auto;
        align-self: flex-start;
      }
      .add-comment-field textarea {
        height: auto;
      }
      .add-comment-actions {
        align-self: flex-end;
      }
    }
  }
}
