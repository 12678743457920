@import 'scss/vars.scss';

.view-products-export {
  margin-top: 2em;
  .nav-tabs {
    border-bottom: none;

    .nav-item {
      min-width: 15em;
      padding: 0.6em 1em;
      color: $color-text;
      font-weight: $typography-font-weight-bold;
      background-color: $color-white;
      border: solid $color-grey-dg;
      border-width: 2px 2px 0 2px;
      border-radius: 8px 8px 0 0 !important;
      opacity: 0.5;

      &:not(:first-child) {
        margin-left: 0.5em;
      }

      &:hover {
        opacity: 0.75;
      }

      &.active {
        opacity: 1;
      }
    }
  }
  .tab-content {
    @include block;
    padding: 2em 1em;
    border-left-width: 2px;
    border-top-left-radius: 0;
  }
}
