@import 'scss/vars.scss';

.filter-companies {
    .dropdown-menu {
      min-width: 300px;
      padding: 0;
    }

    ul {
      @include unstyled-list;
      padding: 1.5em;
      color: $color-text;
      font-size: 14px;

      li {
        margin: 8px 0;

        &:first-child {
          margin-top: 0;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    &-item {
      display: inline-flex;
      align-items: center;
      width: 100%;

      &:first-child {
        font-weight: $typography-font-weight-bolder;
      }
    }

    &-input {
      @include button-reset;
      display: inline-flex;
      align-items: center;
      margin-right: 8px;

      &:focus,
      &:active {
        outline: none;
      }

      .name {
        padding-left: 1em;
      }
    }

    &-footer {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      padding: 10px 20px;
      font-weight: $typography-font-weight-bolder;
      font-size: 14px;
      line-height: 30px;
      background-color: $color-dg-input;
      border-top: 1px solid $color-grey-dg;
      border-radius: 0 0 8px 8px;

      &-btn {
        @include user-select-none;
        padding: 0.2rem 0.8rem;
        border-radius: 0.5rem;
        cursor: pointer;

        &--secondary {
          background-color: $color-white;
          border: 1px solid $color-grey-dg;
          box-shadow: 0 1px 3px 0 rgba(223, 225, 228, 0.5);
        }

        &--primary {
          color: $color-white;
          background-color: $color-green-dg;
          border: 1px solid $color-green-2;
          box-shadow: 0 2px 3px 0 rgba(32, 192, 148, 0.2);

          &:hover,
          :focus,
          :active {
            background: linear-gradient(180deg, #30d0a4 0%, $color-green-dg 100%);
          }
        }

        &:first-child {
          margin-right: 10px;
        }
      }
    }
  }