@import 'scss/vars.scss';

.progress-steps {
  $progress-steps-height: 40px;
  $progress-steps-shadow-arrow-size: 4px;
  $progress-steps-border-radius-arrow: 4px;
  $progress-steps-border-arrow-size: 19.28px; // sqrt(height^2/2) + (border-radius / 2)
  $progress-steps-color-active: $color-green-dg;
  $progress-steps-shadow-norm: 2px 1px 2px;
  $progress-steps-shadow-first-child: -2px 0 2px -2px;
  $progress-steps-shadow-arrow: 0 0 $progress-steps-shadow-arrow-size;
  $progress-steps-border-norm: 1px solid;
  $progress-steps-border-radius: 5px;

  @mixin progress-item-colors($bckg, $color, $border, $shadow, $arrow-border, $arrow-shadow) {
    background: $bckg;
    color: $color;
    border-top: $progress-steps-border-norm $border;
    border-bottom: $progress-steps-border-norm $border;
    box-shadow: $progress-steps-shadow-norm $shadow;
  }

  .progress-wrapper {
    display: flex;
    line-height: 1;
    padding-right: calc(($progress-steps-height / 2) + $progress-steps-shadow-arrow-size + 1px);
    box-sizing: border-box;
    flex-direction: row;

    .progress-step {
      display: flex;
      flex: 1 1 auto;
      padding-left: calc($progress-steps-height / 2);
      height: $progress-steps-height;
      @include progress-item-colors(
        $color-grey-100,
        $color-grey-500,
        $color-grey-300,
        rgba(0, 0, 0, 0.05),
        $color-grey-300,
        rgba(0, 0, 0, 0.05)
      );

      &:first-child {
        padding-left: 0;
        border-radius: $progress-steps-border-radius 0 0 $progress-steps-border-radius;
        border-left: 1px solid $color-grey-300;
        .progress-steps-item-content-wrap {
          border-radius: $progress-steps-border-radius 0 0 $progress-steps-border-radius;
        }
      }

      .progress-steps-item-content-wrap {
        position: relative;
        width: 100%;
        height: 100%;

        .progress-steps-item-content {
          @include flex;
          font-weight: $typography-font-weight-bolder;
          align-items: center;
          height: 100%;
          margin: 0 1em;

          .icon {
            margin-right: 0.6em;
          }
        }
      }

      &::after {
        content: '';
        position: relative;
        right: -31px;
        top: -3px;
        margin-left: auto;
        width: $progress-steps-height * 0.8+2px;
        height: $progress-steps-height * 0.777;
        overflow: hidden;
        border-radius: 4px;
        transform: rotate(45deg);
        transform-origin: top left;
        background: $color-grey-100;
        color: $color-grey-500;
        border: $progress-steps-border-norm $color-grey-300;
        border-left: none;
        border-bottom: none;
        box-shadow: 1px -1px 1px rgba(0, 0, 0, 0.05);
      }

      &.active {
        background: $color-green-dg;
        color: $color-white;
        border-top: $progress-steps-border-norm change-color($color-green-dg, $lightness: 40%);
        border-bottom: $progress-steps-border-norm change-color($color-green-dg, $lightness: 40%);
        box-shadow: $progress-steps-shadow-norm $color-green-dg;
      }

      &.active::after {
        background: $color-green-dg;
        color: $color-white;
        border: $progress-steps-border-norm change-color($color-green-dg, $lightness: 40%);
        border-left: none;
        border-bottom: none;
        box-shadow: 1px -1px 1px $color-green-dg;
      }

      &.done {
        @include progress-item-colors(
          $color-white,
          $typography-font-color-normal,
          $color-grey-300,
          $color-grey-300,
          $color-grey-300,
          rgba(0, 0, 0, 0.1)
        );
      }

      &.done::after {
        background: $color-white;
        color: $color-grey-300;
        box-shadow: 1px -1px 3px rgba(0, 0, 0, 0.1);
      }

      &.status-canceled {
        @include progress-item-colors(
          $color-grey-500,
          $color-grey-100,
          $color-grey-400,
          $color-grey-400,
          $color-grey-400,
          rgba(0, 0, 0, 0.1)
        );

        .progress-steps-item-content-wrap {
          .progress-steps-item-content {
            justify-content: center;
          }
        }
      }

      &.status-canceled::after {
        background: $color-grey-500;
        color: $color-grey-300;
        border-top: $progress-steps-border-norm $color-grey-400;
        border-right: $progress-steps-border-norm $color-grey-400;
        box-shadow: 1px -1px 1px rgba(0, 0, 0, 0.1);
      }

      &.status-rejected {
        @include progress-item-colors(
          $color-red-2,
          $color-grey-100,
          $color-red-1,
          $color-red-1,
          $color-red-1,
          rgba(0, 0, 0, 0.1)
        );

        .progress-steps-item-content-wrap {
          .progress-steps-item-content {
            justify-content: center;
          }
        }
      }

      &.status-rejected::after {
        background: $color-red-2;
        color: $color-grey-100;
        border-top: $progress-steps-border-norm $color-red-1;
        border-right: $progress-steps-border-norm $color-red-1;
        box-shadow: 1px -1px 1px rgba(0, 0, 0, 0.1);
      }
    }
  }
}
