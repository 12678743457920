@import "scss/vars.scss";

.section-smart-suggestions {
  .title {
    line-height: 1em;
  }
  .percent {
    font-size: xxx-large;
    font-style: bold;
    line-height: 1em;
  }

  .number-box {
    flex: 0 0 40px;
    height: 40px;
    flex: 0 0 1.75em;
    margin-bottom: 40px;
  }
  .circle {
    color: $color-grey-dg;
    box-shadow: 0 1px 3px 0 rgba(223, 225, 228, 0.5);
    border-radius: 50%;
  }

  .number {
    text-align: center;
    font-weight: $typography-font-weight-bolder;
    left: 120%;
    top: 45%;
  }

  .progress-bar {
    position: relative;
    height: 20px;
    border-radius: 50px;
    border: 1px solid #333;
    background-color: $color-grey-dg;
    margin-right: 30px;
  }

  .filler {
    background: $color-green-dg;
    height: 100%;
    border-radius: inherit;
    transition: width 0.2s ease-in;
  }

  .suggestion-content {
    display: inline;
    font-weight: $typography-font-weight-bold;
    .attribute-name {
      font-weight: $typography-font-weight-bold;
    }
  }
}
