$typography-font-family-normal: "Inter", sans-serif;
$typography-font-family-monospace: monospace;

$typography-font-weight-lightest: 100;
$typography-font-weight-lighter: 200;
$typography-font-weight-light: 300;
$typography-font-weight-normal: 400;
$typography-font-weight-bold: 500;
$typography-font-weight-bolder: 600;
$typography-font-weight-boldest: 700;
$typography-font-weight-black: 900;

$typography-font-size-root: 14px;
$typography-font-size-normal: 1rem;

$typography-font-color-normal: $color-grey-900;
