.control-dg {
  cursor: pointer;
  user-select: none;

  &[disabled] {
    cursor: default;
  }
}

.button-inherit {
  color: inherit;
  background-color: inherit;
  background: inherit;
  border: 0;
  padding: inherit;
  margin: inherit;
}
