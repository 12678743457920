@import "scss/vars.scss";

.user-profile-circle {
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  box-shadow: 2px 2px 10px -2px rgba(0, 0, 0, 0.1);
  &.sm {
    width: 2rem;
    height: 2rem;
    font-size: 1rem;
  }

  &.md {
    width: 3rem;
    height: 3rem;
    font-size: 1.5rem;
  }
  &.lg {
    width: 4rem;
    height: 4rem;
    font-size: 2rem;
  }

  .profile-pic {
    border: 1px solid $color-grey-200;
    border-radius: 50%;
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    &.sm {
      width: 2rem;
      height: 2rem;
    }

    &.md {
      width: 3rem;
      height: 3rem;
    }
    &.lg {
      width: 4rem;
      height: 4rem;
    }

    &.name-placeholder {
      background: $color-grey-500;
      color: $color-white;
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;

      > span {
        font-weight: $typography-font-weight-bolder;
      }
    }
  }
  .profile-pic--noneBorder {
    border: none;
  }
}
