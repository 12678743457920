@import 'scss/vars.scss';

@keyframes loadingI {
  100% {
    transform: rotate(360deg);
  }
}
@keyframes bounce {
  0%,
  100% {
    transform: scale(0.35);
  }
  50% {
    transform: scale(1);
  }
}

*[class^='bbLogo-animate--part'] {
  fill: $color-logo-blue;
}

.load-logo-spinner-wrapper {
  justify-content: space-evenly;
  display: inline-block;
  position: relative;
  width: 1em;
  height: 1em;
  margin: auto;
  vertical-align: middle;
  text-align: center;

  &.small-logo-spinner {
    width: 0.25em;
    height: 0.25em;
  }
}

.load-logo-spinner {
  animation: loadingI 4s linear infinite;
}

.bbLogo-animate-NW,
.bbLogo-animate-NE,
.bbLogo-animate-SE,
.bbLogo-animate-SW {
  transform-origin: center;
  animation: bounce 3s ease-in-out infinite;
}
.bbLogo-animate-NW {
  animation-delay: -0.75s;
}
.bbLogo-animate-SW {
  animation-delay: -1.5s;
}
.bbLogo-animate-SE {
  animation-delay: -2.25s;
}

.bbLogo-animate-NE {
  animation-delay: -3s;
}
