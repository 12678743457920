@import "scss/vars.scss";

.bot-category-card {
    padding: 25px 30px;
    background-color: $color-white;
    border-radius: 6px;

    &-info {
        margin-bottom: 4px;

        &__heading {
            margin-top: 0;
            margin-bottom: 6px;
            color: $color-text;
            font-weight: $typography-font-weight-bolder;
            font-size: 18px;
        }

        &__subheading {
            margin: 0;
            color: $color-text;
            font-size: 15px;
        }
    }

    &-list {
        display: flex;
        flex-wrap: wrap;

        .bot-informational-card {
            margin-top: 8px;
            margin-right: 16px;
            margin-bottom: 8px;
        }
    }
}
