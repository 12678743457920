// TODO: Check and refactor this, parts of it are for old data job screen, parts might be used
// TODO: On a first glance it doesn't seem to break when this whole file is removed.
// TODO: Most of these styles seem to be duplicated in specific sheets

.distributor-job-details-box-value-input__flavor-text {
  margin: 8px 0px;
  font-size: 0.8rem;
}

.distributor-job-details-box-btn {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: 8px 15px;
  white-space: nowrap;
  background-color: $color-white;
  border: 1px solid $color-grey-dg;
  border-radius: 6px;
  box-shadow: 0 1px 3px 0 rgba(223, 225, 228, 0.5);
  cursor: pointer;
  &.disabled {
    cursor: default;
    opacity: 0.5;
  }
}

.distributor-job-details-box-btn__icon {
  margin-right: 10px;
}

.distributor-job-details-box-btn__label {
  color: $color-grey-900;
  font-weight: $typography-font-weight-bolder;
  font-size: 15px;
}

.upload-attachment {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
  margin-top: 15px;
  padding: 10px;
  border-radius: 8px;
}

.upload-attachment__btn {
  margin-left: auto;
}

.upload-attachment__infos {
  display: flex;
  align-items: center;
  color: $color-text;
}

.upload-attachment__info-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25px;
  height: 25px;
  margin-right: 15px;
  padding-bottom: 2px;
  color: $color-text;
  font-size: 11px;
  background-color: $color-white;
  border: 1px solid $color-grey-dg;
  border-radius: 50%;
  box-shadow: 0 1px 3px 0 rgba(223, 225, 228, 0.5);
}
