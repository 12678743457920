@import "scss/vars.scss";

.image-quality-company,
.image-quality-partner,
.image-quality-configuration {
  background: $color-white;
  border: 1px solid $color-grey-300;
  padding: 1em;
  border-radius: 0.5em;
  box-shadow: 0 1px 3px 0 $color-grey-300;
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  justify-content: flex-start;

  .image-quality-header {
    display: flex;

    .title {
      color: $color-blue-dg;
      font-size: 1.5em;
      font-weight: $typography-font-weight-bolder;
      vertical-align: bottom;
      line-height: 30px;
    }
  }
}
.image-quality-assessment-top {
  color: $color-text;
  .image-quality-assessment-top-cards {
    display: flex;
    flex-flow: row wrap;
    width: 100%;

    .image-quality-company {
      flex: 2 1 200px;
      margin-right: 1em;

      .value {
        @include flex;
        flex-wrap: wrap;
        width: 100%;

        > .name {
          flex: 1 1 auto;
        }

        > .child-select {
          flex: 1 1 8em;
          font-size: 1rem;
          font-weight: $typography-font-weight-normal;
        }
      }
    }
    .image-quality-partner {
      flex: 1 2 100px;
      .image-quality-header {
        width: 100%;
        justify-content: space-between;
      }
    }

    .value {
      font-weight: $typography-font-weight-bolder;
      font-size: 2em;
    }
  }

  > .title {
    @include flex;
    justify-content: space-between;
    margin-top: 1.5em;

    .label {
      font-size: 2.2rem;
    }

    .actions {
      margin: 0.5em 0 0 1.5em;
      white-space: nowrap;
    }
  }

  .image-quality-configuration {
    margin-top: 1em;

    .configuration-data-wrapper {
      display: flex;
      flex-flow: row wrap;
      width: 100%;

      .configuration-item {
        flex: 0 1 33%;
        padding: 0.5em 1.5em 0.5em 0;
        font-weight: $typography-font-weight-bold;
      }
    }
  }

  .btn.btn-primary.btn-view-other-assessments {
    background: $color-blue-dg;
    border-color: $color-blue-dg;
    box-shadow: None;
    margin-right: 1rem;
    box-shadow: none;

    &:hover {
      border-color: $color-blue-dg;
      background: linear-gradient(180deg, $color-blue-dg 0%, $color-blue-dg-light 500%);
      box-shadow: 0 1px 3px 0 $color-blue-dg;
    }
  }
}
