@import "scss/vars.scss";

.img-bot-engine-config-table {
  position: relative;
  min-height: 10em;

  .react-bootstrap-table {
    /* prettier-ignore */
    thead {
      tr {
        th {
          .label {
            color: $color-grey-550;
          }
        }

        th.actions {
          width: 23em;

          .th-wrap {
            justify-content: flex-end;
          }
        }
      }
    }

    tbody {
      tr {
        td {
          &.actions {
            .btn {
              .label {
                display: none;
              }

            }
          }
        }
      }
    }
  }
}
