@import 'scss/vars.scss';

.image-quality-row {
  background: $color-white;
  border: 1px solid $color-grey-300;
  padding: 1em;
  border-radius: 0.5em;
  box-shadow: 0 1px 3px 0 $color-grey-300;
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: 0.5em;

  .image-quality-header {
    margin-bottom: 1em;
    width: 100%;
    display: flex;
    flex-wrap: nowrap;

    > span {
      margin-right: 0.5em;
    }

    .title {
      color: $color-blue-dg;
      font-size: 1.5em;
      font-weight: $typography-font-weight-bolder;
      vertical-align: bottom;
      line-height: 30px;
    }

    .date {
      font-size: 0.75em;
      color: $color-grey-500;
      vertical-align: bottom;
      line-height: 30px;
    }

    .btn {
      color: $color-text;
      padding: 2px 0.5em;
    }
  }

  .image-quality-data {
    display: flex;
    align-items: stretch;
    width: 100%;

    > div {
      width: calc(20% - 0.5em);

      margin: 0 0.25em;
      display: flex;
      flex-flow: column nowrap;

      &.update-wrapper {
        display: flex;
        margin-left: auto;
      }

      .actions {
        justify-self: flex-end;
        margin-top: auto;
      }
    }

    .assessment-data {
      box-shadow: 0 1px 3px 1px rgba(0, 19, 92, 0.15);
      padding: 1em;
    }

    .total-products {
      display: flex;
      flex-direction: column;
      align-content: stretch;
      justify-content: space-evenly;

      .assessment-data:first-child {
        margin-bottom: 0.5em;
      }
    }

    .data-title {
      color: $color-blue-dg;
      text-transform: uppercase;
      font-weight: $typography-font-weight-bolder;
    }

    .data-stats {
      flex-flow: row nowrap;
      color: $color-grey-500;

      .value {
        font-size: 2em;
        color: $color-text;
        margin-right: 0.5em;
      }
    }

    .ratings {
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
      margin-bottom: 0.5em;

      .good,
      .bad {
        font-weight: $typography-font-weight-bold;
        color: $color-text;

        &:before {
          border-radius: 10px;
          content: '';
          margin-right: 8px;
          height: 10px;
          width: 10px;
          display: inline-block;
        }
      }
      .good:before {
        background-color: $color-green-dg;
      }
      .bad:before {
        background-color: $color-red-dg;
      }
    }

    .btn {
      width: 100%;
      margin-top: 0.25em;
      font-weight: $typography-font-weight-normal;
      padding: 1px 0;
    }

    .btn.btn-primary {
      background: $color-blue-dg;
      border-color: $color-blue-dg;
      box-shadow: None;
    }
    .btn.btn-primary:hover {
      border-color: $color-blue-dg;
      background: linear-gradient(180deg, $color-blue-dg 0%, $color-blue-dg-light 500%);
      box-shadow: 0 1px 3px 0 $color-blue-dg;
    }

    .btn-secondary {
      border-color: $color-blue-dg;
      color: $color-blue-dg;
    }
  }
}

.image-quality-config-modal {
  .modal-dialog-centered {
    max-width: 80vw;
    .title {
      color: $color-blue-dg;
      font-size: 1em;
      font-weight: $typography-font-weight-bolder;
    }
  }
}

.configuration-data-wrapper {
  display: flex;
  flex-flow: row wrap;

  .configuration-item {
    order: 1;
    flex: 0 1 33%;
    padding: 0.5em 1.5em 0.5em 0;
    font-weight: $typography-font-weight-bold;
  }

  .configuration-message {
    order: 2;
    flex: 1 0 100%;
    justify-self: flex-end;
    font-size: 0.8rem;
    color: $color-grey-500;
  }
}
