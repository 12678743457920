.view-databots {
  margin-top: 40px;
  margin-bottom: 180px;

  .bot-category-card {
    margin-bottom: 20px;
    box-shadow: 0px 2px 6px 0px #00135c0f;
  }
}

.databots-section {
  margin-top: 4em;
  margin-bottom: 80px;
  display: flex;
  flex-direction: column;

  .image-quality {
    .title {
      font-weight: $typography-font-weight-bolder;
      font-size: 2em;
      color: $color-text;
    }
    .heading {
      @include flex;
      align-items: center;

      .title {
        flex: 1 1 auto;
        font-size: 2em;

        .loading-spinner {
          margin-left: 0.5em;
        }
      }

      .actions {
        margin-left: 1em;

        .btn:not(:disabled):not(.disabled):active {
          border-color: $color-green-2;
          color: $color-green-2;
        }
        .btn:not(:first-child) {
          margin-left: 0.5em;
        }
      }
    }
  }

  .manufacturer-select {
    @include flex;

    > div {
      flex: 0 0 100%;
      max-width: 25em;

      &:not(:first-child) {
        margin-left: 1em;
      }
    }

    .title {
      margin-bottom: 0.5em;

      .loading-spinner {
        margin-left: 0.5em;
        font-size: 1.25em;
      }
    }
  }

  &-header {
    display: inline-flex;

    .loading-spinner {
      margin-left: 12px;
    }
  }

  &-heading {
    margin-bottom: 10px;
    color: $color-text;
    font-weight: $typography-font-weight-bold;
    font-size: 28px;
  }

  &-description {
    color: $color-text;
    font-size: 15px;
  }

  .databot {
    margin-top: 32px;
    margin-right: 32px;
  }
}
