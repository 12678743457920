@import 'scss/vars.scss';

.ids-short-description {
  margin-bottom: 2em;
}

.ids-summary-wrapper {
  overflow-x: auto;

  > .header {
    @include flex;
    flex-direction: row;
    align-items: center;
    width: 100%;

    .title {
      font-size: 2em;
      width: 100%;

      > div {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      .ids-regular-dates {
        display: flex;
        flex: 1;
        justify-content: flex-end;
        gap: 8px;
        align-items: center;

        .ids-month-filter-icon {
          color: $color-grey-400;
          font-size: 1.5rem;
        }

        .ids-months-filter {
          min-width: 11rem;
          font-size: 1rem;
          .styled-multiselect-container {
            @include ie11-hack;
            display: inline-flex;
            flex: 1 0 auto;
            width: 100%;
            margin-top: auto;
            margin-bottom: auto;
            border-color: $color-grey-300;
            border-style: solid;
            border-width: 0.1rem;
            border-radius: 0.5rem;
            box-shadow: 0 0 1px $color-grey-300;
            &:hover {
              background-color: $color-grey-dg-light;
              border-color: $color-grey-400;
              box-shadow: 0 0 1px $color-grey-400;
            }
            &:focus-within {
              border-color: $color-orange-dg;
              border-width: 0.1rem;
              border-radius: 0.5rem;
              box-shadow: 0 0 3px $color-orange-dg;
            }
          }
        }
      }
    }

    .date {
      font-size: 0.75em;
      color: $color-grey-500;
      vertical-align: bottom;
      line-height: 30px;
      margin-left: 1em;
    }
  }

  .empty-statistics {
    @include flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .title {
      font-size: 1.2em;
      font-weight: 600;
      margin-bottom: 0.25em;
      margin-top: 0.25em;
    }
  }

  .ids-summary-regular-user {
    align-items: stretch;
    justify-content: space-between;
    margin-top: 2em;
    min-width: 1300px;

    .value-group-columns {
      display: flex;
      align-items: stretch;
      justify-content: space-between;
      gap: 1em;
      > div {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;

        .value {
          font-weight: 600;
          .icon {
            font-size: 1rem;
            margin-left: 4px;
            text-align: center;
            vertical-align: middle;
            color: $color-grey-550;
          }
        }
        .percentage {
          font-size: 0.75em;
          font-weight: 400;
          &-positive {
            color: $color-green-dg;
          }
          &-negative {
            color: $color-red-dg;
          }
          &-zero {
            color: $color-grey-900;
          }
        }

        min-width: 0;
        width: auto;
        margin-bottom: 1em;
      }
    }
  }

  .ids-summary-self-service {
    @include flex;

    align-items: stretch;
    justify-content: space-between;
    margin-top: 2em;
    min-width: 1300px;

    > section.circular-progress-bar-wrapper {
      flex: 0 0 25em;

      div.circular-progress-bar {
        @include block;
        padding: 1em;

        &:first-child {
          margin-bottom: 1em;
        }
        &:last-child {
          margin-top: 1em;
        }
      }

      > div.circular-progress-bar-wrapper {
        margin: 1em 0;
        gap: 1em;

        .bai-circular-progressbar.semi-circle {
          position: relative;
          padding-top: 50%;
          overflow: hidden;

          &:first-child {
            margin-bottom: 0.5em;
          }

          &:last-child {
            margin-top: 0.5em;
          }
        }
      }
    }

    .value-group {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(2, 1fr);
      grid-auto-rows: 1fr;
      grid-auto-flow: dense;
      grid-gap: 1em;
      margin-right: 2em;

      .span-2r {
        // span 2 rows
        grid-column: span 2;
      }

      > div {
        flex: 1 1 50%;

        > .bai-value-block {
          width: 100%;
          &:first-child {
            margin-right: 0.5em;
          }

          &:last-child {
            margin-left: 0.5em;
          }
        }
      }

      .inner-row {
        display: flex;
        flex-direction: row;

        .bai-value-block > .additional-data {
          display: flex;
        }
      }
    }
  }
}
