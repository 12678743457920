.view-assessments {
  margin-top: 40px;
  margin-bottom: 180px;

  .assessments-overview {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    color: $color-text;

    &-header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }

    h1 {
      margin-top: 0;
      margin-bottom: 16px;
      color: $color-text;
      font-weight: $typography-font-weight-bold;
      font-size: 40px;
    }

    .header-actions {
      @include flex;
      align-items: flex-end;

      .assessments-search {
        position: relative;
        width: 40%;

        .search {
          margin: 0;
        }

        .search-clear-btn {
          color: $color-text;
          font-size: 1em;
        }
      }

      .manufacturer-select {
        @include flex;

        .manufacturer {
          max-width: 18em;
        }
    
        > div {
          flex: 1 1 100%;
    
          &:not(:first-child) {
            margin-left: 1em;
          }
        }
    
        .title {
          margin-bottom: 0.5em;
    
          .loading-spinner {
            margin-left: 0.5em;
            font-size: 1.25em;
          }
        }
      }

      .assessments-filter-companies {
        flex: 1 1 30%;
        margin-left: 1em;

        .dropdown-toggle {
          min-height: 40px;
        }
      }

      .assessments-per-page {
        margin-left: auto;
      }
    }
  }

  .assessments-per-page {
    @include flex;
    align-items: center;

    .assessments-number {
      color: $color-grey-550;
      margin-right: 1em;
    }
  }

  .footer-actions {
    @include flex;
    justify-content: space-between;

    .assessments-per-page {
      flex-direction: row-reverse;

      .assessments-number {
        margin-left: 1em;
        margin-right: 0;
      }
    }

    .assessments-paginator {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;

      &-ctl {
        @include button-reset;

        padding: 10px 16px;
        color: $color-text;
        font-weight: $typography-font-weight-bolder;
        font-size: 15px;
        background-color: $color-white;
        border: 1px solid $color-grey-dg;
        border-radius: 6px;
        box-shadow: 0px 1px 3px rgba(223, 225, 228, 0.5);

        &:hover {
          background-color: linear-gradient(180deg, rgba(255, 255, 255, 0.5) 0%, rgba(243, 243, 247, 0.499167) 100%),
            $color-white;
        }

        &:not(:first-child) {
          margin-left: 1em;
        }
      }
    }
  }
}
