@import "scss/vars.scss";

.image-quality-assessments-faq-panel {
  .faq-list {
    @include unstyled-list;

    .faq-item:not(:first-child) {
      margin-top: 1.5rem;
    }

    .faq-item {
      min-width: 40em;

      .image-quality-assessments-faq-item {
        @include block;
        padding: 1rem 1.5rem 2em 1.5em;

        .heading {
          @include flex;
          justify-content: space-between;
          .title {
            font-size: 1.25em;
            font-weight: $typography-font-weight-bold;
          }

          .actions {
            margin-left: 1.5em;
            white-space: nowrap;
          }
        }

        .content {
          @include flex;
          margin-top: 1.5em;

          .image {
            flex: 0 0 25em;
            width: 25em;
            height: 25em;
            position: relative;

            img {
              display: block;
              width: 100%;
              height: 100%;
              object-fit: cover;
            }

            .image-caption {
              position: absolute;
              bottom: 1.5em;
              left: 1.5em;
              background: change-color($color-grey-dg-light, $alpha: 0.75);
              border-radius: 1em;
              box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
              padding: 0.25em 0.5em;
            }
          }

          .description {
            margin-left: 1.5em;

            .actions {
              margin-top: 2em;
              white-space: nowrap;
            }
          }
        }

        &.collapsed {
          padding-bottom: 1rem;
          .content {
            display: none;
          }
        }
      }
    }
  }
}

@media (max-width: 800px) {
  .image-quality-assessments-faq-panel {
    .faq-list {
      .faq-item {
        .image-quality-assessments-faq-item {
          .content {
            .image {
              flex: 0 0 18em;
              width: 18em;
              height: 18em;
              position: relative;
            }
          }
        }
      }
    }
  }
}
