@import "scss/vars.scss";

.databot-config-modal-panel {
    .modal-header {
        padding: 20px 20px 0 20px;
        color: $color-text;
        border-bottom: 0;
    }

    .modal-dialog {
        max-width: 530px;
    }

    .modal-title {
        font-weight: $typography-font-weight-bolder;
        font-size: 18px;
        line-height: 30px;
    }

    .modal-content {
        border: 1px solid $color-grey-dg;
        border-radius: 8px;
        box-shadow: 0 2px 6px 0 rgba(30, 44, 71, 0.2);
    }

    .close {
        color: $color-grey-dg;
        outline: none;
    }

    .modal-body {
        display: flex;
        flex-direction: column;
        margin: 15px 0 25px 0;
        padding: 0 20px 10px 20px;
        color: $color-text;

        .label {
            margin-bottom: 30px;
            font-size: 15px;
        }

        .details {
            display: flex;
            flex-direction: column;

            &-row {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                margin-bottom: 5px;

                p {
                    width: 50%;
                    margin: 0;
                }

                &-value {
                    font-size: 16px;
                }

                &-label {
                    font-weight: $typography-font-weight-bolder;
                    font-size: 12px;
                    letter-spacing: 0.05em;
                    text-transform: uppercase;
                }
            }
        }
    }

    .modal-footer {
        padding: 10px 20px;
        font-weight: $typography-font-weight-bolder;
        font-size: 15px;
        line-height: 30px;
        background-color: $color-dg-input !important;
        border-top: 1px solid $color-grey-dg;
        > * {
            margin: 0;
        }

        .footer-btn {
            @include user-select-none;
            padding: 5px 15px;
            font-size: 14px;
            border-radius: 0.5rem;
            cursor: pointer;
            &:first-child {
                margin-right: 10px;
            }

            &--secondary {
                color: $color-text;
                background-color: $color-white;
                border: 1px solid $color-grey-dg;
                box-shadow: 0 1px 3px 0 rgba(223, 225, 228, 0.5);
            }
            &--primary {
                color: $color-white;
                background-color: $color-green-dg;
                border: 1px solid $color-green-2;
                box-shadow: 0 2px 3px 0 rgba(32, 192, 148, 0.2);
                &:hover,
                :focus,
                :active {
                    background: linear-gradient(180deg, #30d0a4 0%, $color-green-dg 100%);
                }
            }
        }
    }
}
