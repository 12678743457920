@import 'scss/vars.scss';

.modal-panel.img-bot-modal-panel {
  .modal-dialog {
    .img-bot-modal-panel-body {
      .helper-text {
        font-size: 0.85rem;
        margin: 1rem 0.5rem;
        p {
          margin-bottom: 0.15rem;
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
      > .helper-text:first-child {
        margin-top: 0;
      }
      > section {
        &:not(:first-child) {
          margin-top: 1rem;
        }
        > .title {
          color: $color-grey-500;
          font-size: 1.25rem;
          font-weight: $typography-font-weight-bolder;
          border-bottom: 1px solid $color-grey-200;
          margin-bottom: 0.5rem;
        }

        > .subtitle {
          margin: 0.5rem 0;
          font-size: 0.85rem;
        }

        &.download-list-input {
          > ul {
            @include unstyled-list;
            font-weight: $typography-font-weight-bolder;
          }
        }

        &.custom-bot-config {
          label {
            margin-bottom: 0;
            input[type='checkbox'] + span {
              margin-left: 0.25rem;
            }
          }

          .e2e-run-enable {
            display: inline-flex;
            align-items: center;
            margin: 0.5rem;
            font-weight: $typography-font-weight-bolder;
          }

          .ext-deliver-enable {
            display: inline-flex;
            align-items: center;
            margin: 0.5rem;
            font-weight: $typography-font-weight-bolder;
          }

          .custom-config-actions {
            margin: 0.5rem 0;

            button {
              &:not(:first-child) {
                margin-left: 0.5rem;
              }
            }
          }

          .custom-config-wrap {
            margin-top: 1rem;
            .custom-config-block {
              &:not(:first-child) {
                margin-top: 1rem;
              }

              > .title {
                padding-left: 0.5rem;
                padding-bottom: 0.15rem;
                margin-bottom: 0.5rem;
                color: $color-grey-500;
                font-weight: $typography-font-weight-bolder;
                border-bottom: 1px solid $color-grey-200;
              }

              .config-items {
                @include unstyled-list;
                @include flex;
                flex-flow: column wrap;
                > li {
                  .ids-custom-config-field {
                    padding: 0 0.5rem;
                  }
                }
              }

              &.custom-config-block-assessment {
                .config-items {
                  max-height: 11rem;
                }
              }

              &.custom-config-block-enhancement {
                .config-items {
                  max-height: 7rem;
                }
              }

              &.custom-config-block-output {
                .config-items {
                  max-height: 5rem;
                }
              }

              &.custom-config-block-bool_params {
                .config-items {
                  max-height: 11rem;
                }
              }

              &.custom-config-block-params,
              &.custom-config-block-detection {
                .config-items {
                  max-height: 15rem;
                  display: flex;
                  flex-direction: row;
                  flex-wrap: wrap;
                  > li {
                    width: 50%;
                    .ids-custom-config-field {
                      padding: 0 0.5rem;
                      position: relative;
                      margin-bottom: 1.2rem;

                      .invalid-feedback {
                        //margin-top: -1.1rem;
                        position: absolute;
                        bottom: -1.3rem;
                      }

                      .ids-custom-config-children {
                        padding: 0;
                      }

                      label {
                        font-size: 0.9rem;
                        font-weight: $typography-font-weight-bold;
                        margin-bottom: 0.25em;
                        margin-left: 0.25em;
                      }
                    }
                  }
                }
              }
              &.custom-config-block-detection {
                .config-items {
                  max-height: 14rem;
                }
              }
            }
          }
        }

        &.actions {
          margin-top: 2rem;
          text-align: right;

          button {
            &:not(:first-child) {
              margin-left: 1rem;
            }
          }
          p {
            color: $color-red-errors-negative;
            font-weight: $typography-font-weight-normal;
            font-size: 0.85rem;
          }
        }

        .img-bot-config-obj-info {
          .config-name {
            font-weight: bold;
          }
        }
      }
    }
  }
}
