@import 'scss/vars.scss';

.config-field-label {
  color: $color-grey-500;
  display: flex;
  flex-direction: row;
  align-items: center;
  grid-gap: 4px;
  padding: 2px 0;
  line-height: normal;
  font-weight: 500;
  margin: 10px 0;
  .config-field-label__label {
  }
  .config-field-label__tooltip {
  }

  &.type--section {
    font-size: 18px;
    border-bottom: 1px solid $color-grey-200;
  }

  &.type--subsection {
    font-size: 14px;
    border-bottom: 1px solid $color-grey-200;
  }

  &.type--field {
    color: $color-text;
    font-size: 12px;
    grid-gap: 2px;
    margin: 0 0 2px 0;
  }
}
