@import 'scss/vars.scss';

.sheet-table-actions {
  color: $color-grey-900;
  background-color: $color-white;
  padding: 0.5em 0.25em;
  font-weight: $typography-font-weight-boldest;
  font-size: 1.2em;
  margin: 0 2.5vw;
  border: solid 2px #e8e9eb;
  display: flex;
  flex-wrap: nowrap;
  align-content: center;
  max-width: 92vw;

  .highlight-action {
    color: $color-green-dg;
  }

  .actions-header {
    display: flex;
    order: 1;
    align-self: center;
    div {
      margin: 0 0.25rem;
      align-self: center;
    }

    .actions-icon {
      margin: 0 1rem;
    }
  }
  .actions-button {
    display: flex;
    order: 2;
    align-self: center;
    padding-left: 0.25em;
  }
  .actions-scroll {
    display: flex;
    order: 3;
    flex-wrap: nowrap;
    align-self: center;
    justify-self: flex-end;
    margin-left: auto;
  }
}

.table-wrapper-quality {
  overflow-x: scroll;
  max-width: 100%;
  position: relative;

  .header-secondary {
    color: $color-grey-550;
  }

  table {
    font-size: 1em;
    width: 100%;
    border-collapse: initial;
    border-spacing: 0;

    .row-letter {
      th {
        text-align: center;
      }
    }

    .row-backbone {
      height: 50px;
      th,
      td {
        background-color: $color-white;
      }
    }

    tr {
      th,
      td {
        border: 1px solid #e8e9eb;
        background-color: $color-dg-input;
        padding: 0.5em 0.5em;

        &.cell-ignored {
          background-color: $color-grey-300;
          color: $color-grey-500;
        }
      }
    }
    .cell-wrapper {
      max-height: 100px;
      max-width: 500px;
      min-width: 200px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .cell-cutoff {
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      min-width: 100px;
    }

    .header-original {
      color: $color-grey-500;
    }

    .cell-backbone {
      display: flex;
      flex-wrap: nowrap;
      width: 100%;
      align-items: center;

      .cell-text {
        display: flex;
      }

      .cell-check {
        display: flex;
        color: $color-green-dg;
        margin-left: 0.5em;
      }

      .cell-action {
        display: flex;
        margin-left: auto;
      }
    }

    .cell-error {
      border: $color-red-dg solid 2px !important;
      background-color: $color-red-dg-light !important;
      color: $color-red-dg;

      .cell-error-icon {
        display: flex;
        align-items: center;

        .oi {
          line-height: inherit;
        }
      }
    }

    .cell-warning {
      border: $color-orange-dg solid 2px !important;
      color: $color-orange-dg;
    }

    .cell-letter {
      display: flex;
      flex-wrap: nowrap;
      width: 100%;
      align-items: center;
      justify-items: center;
    }

    th .cell-cutoff {
      -webkit-line-clamp: 2;
    }
    tr th:empty,
    tr td:first-of-type:empty {
      position: initial;
      background-color: initial;
      border: 0;
    }
    input[type='checkbox'] {
      -webkit-appearance: radio; /* Chrome, Safari, Opera */
      -moz-appearance: radio; /* Firefox */
      -ms-appearance: radio; /* not currently supported */
    }
  }
}
.table-shadow-right {
  position: absolute;
  right: 5vw;
  width: 4em;
  z-index: 2;
  background: -webkit-linear-gradient(to right, transparent, white);
  background: -o-linear-gradient(to right, transparent, white);
  background: -moz-linear-gradient(to right, transparent, white);
  background: linear-gradient(to right, transparent, white);
}

.loading-wrapper {
  cursor: wait;
  z-index: 1000;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(255, 255, 255, 0.85);

  &.sheet-loading {
    position: absolute !important;
  }

  .loading-spinner {
    display: block;
    margin: 0;
  }
}

.edit-footer {
  .btn-secondary {
    margin-right: 1em;
  }
}

.edit-body {
  display: flex;
  flex-direction: row;

  .edit-selection {
    display: flex;
    padding: 0.5em;
    flex-direction: column;

    button {
      padding: 8px 15px;
      white-space: nowrap;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid $color-grey-dg-light;
      border-radius: 6px;
      background-color: #fff;
      cursor: pointer;
      margin-bottom: 0.25em;

      &.selected {
        border: $color-orange-dg 1px solid;
        box-shadow: 0 0 3px 1px $color-orange-dg;
        font-weight: 700;
      }
    }
  }
}
