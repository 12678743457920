@import "scss/vars.scss";

.weekly-metrics-card {
    flex: 1 1 auto;
    min-width: 200px;
    min-height: auto;
    margin: 16px 0 0 16px;

    &-header {
        display: inline-flex;
        align-items: center;
        width: 100%;
        min-height: 90px;
        padding: 15px 20px;
        color: $color-white;
        font-size: 18px;
        background-color: $color-green-dg;
        border-radius: 8px 8px 0 0;

        h2 {
            margin: 0;
            color: $color-white;
            font-weight: $typography-font-weight-bolder;
            font-size: 18px;
        }
    }

    &-body {
        padding: 0px 20px;
    }

    &-list {
        @include unstyled-list;
        font-size: 13px;

        p {
            margin: 0;
        }

        li {
            margin: 20px 0;
        }

        &-item {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            color: $color-text;

            &-label {
                font-weight: $typography-font-weight-boldest;
            }

            &-percent--positive {
                color: $color-green-dg;
            }

            &-percent--negative {
                color: $color-red-dg;
            }
        }
    }
}
