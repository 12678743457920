@import 'scss/vars.scss';

.data-ingestion-data-quality {
  @include block;
  margin-top: 1.5em;

  .data-ingestion-data-quality-overview {
    padding: 1em 1em;

    @include flex;

    .left-side {
      flex: 1 1 auto;
      > .title {
        font-size: 1.2em;
        font-weight: $typography-font-weight-bolder;
      }

      > .subtitle {
        font-size: 1em;
        font-weight: $typography-font-weight-bold;
      }

      .data-job-name {
        margin-top: 2em;

        .label {
          font-weight: $typography-font-weight-bolder;
        }

        .value {
          font-size: 1.2em;
        }
      }
    }

    .right-side {
      .errors-warnings-summary {
        @include block;
        @include flex;
        padding: 1em 4em;

        .item {
          @include flex;
          flex-direction: column-reverse;
          border-left: 6px solid transparent;
          padding-left: 1em;
          line-height: 1.1;

          &:not(:first-child) {
            margin-left: 3em;
          }

          &.item-errors {
            border-left-color: $color-red-dg;
          }

          &.item-warnings {
            border-left-color: $color-orange-dg;
          }

          .label {
            font-size: 0.9em;
          }

          .value {
            font-weight: $typography-font-weight-bolder;
          }
        }
      }
    }
  }

  .data-ingestion-data-quality-error-message {
    margin: 1em 1em;
    padding: 0.25em;

    @include flex;
    background-color: $color-red-dg-light;
    border: 1px solid $color-red-dg;
    border-radius: 0.5em;
  }

  .data-ingestion-data-quality-sheet-select {
    margin: 1em 1em 0 1em;
    .sheet-select-list {
      @include unstyled-list;
      @include flex;

      .sheet-select-item {
        &:not(:first-child) {
          margin-left: 0.5em;
        }

        > button {
          @include flex;
          text-align: left;
          border: solid $color-grey-dg;
          border-width: 1px 1px 0 1px;
          border-radius: 8px 8px 0 0;
          background-color: $color-white;
          padding: 0.5em 0.75em;

          .sheet-name {
            flex: 1 1 auto;
            min-width: 8em;
            max-width: 17em;
          }

          .errors-warnings-summary {
            @include flex;
            margin-left: 1em;

            .item {
              &:not(:first-child) {
                margin-left: 0.25em;
              }

              .icon {
                // font-size: 0.8em;
                svg {
                  position: relative;
                  // top: -0.2em;
                }
              }

              .value {
                margin-left: 0.15em;
              }

              &.item-errors {
                color: $color-red-dg;
              }

              &.item-warnings {
                color: $color-orange-dg;
              }

              &.item-ok {
                color: $color-green-dg;
              }
            }
          }
        }

        &.active * {
          cursor: default;
          color: $color-white;
          background-color: $color-blue-dg;
        }
      }
    }
  }

  .data-quality-table-wrap {
    margin: 0 1em;
    .sheet-table-actions {
      margin: 0;
    }

    // TODO: Fix in proper table component if possible, or add className to this div
    > .sheet-table-actions + div {
      position: relative;
    }

    .table-wrapper-columns {
      margin: 0;
    }

    .table-shadow-right {
      right: 0;
    }
  }
}

.tooltip-data-ingestion-data-quality {
  &.tooltip-data-ingestion-data-quality-errors .icon {
    color: $color-red-dg;
  }

  &.tooltip-data-ingestion-data-quality-warnings .icon {
    color: $color-orange-dg;
  }
}
