.score-scale {
  width: 100%;
  padding-bottom: 12px;
  .score-scale-bar {
    width: 100%;
    background: linear-gradient(
      90deg,
      $color-red-dg 0%,
      $color-orange-dg 33%,
      $color-yellow-dg 66%,
      $color-green-dg 100%
    );
    height: 6px;
    position: relative;

    .score-scale-arrow {
      position: absolute;
      bottom: -10px;
      margin-left: -7px;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 8px 8px 8px;
      border-color: transparent transparent $color-grey-700 transparent;
    }
  }
}

.score-scale-min {
  @include flex;
  align-items: center;
  justify-content: space-between;
  width: 8em;
  .score-scale-bar {
    @include flex;

    .scale-block {
      width: 1em;
      height: 1em;
      background: $color-grey-300;
      &.full {
        background: $color-grey-700;
      }
      &.partial {
        .scale-block-remainder {
          background: $color-grey-700;
          height: 100%;
          width: 0;
        }
      }
    }
  }

  &.gap {
    width: 9em;
    .score-scale-bar {
      .scale-block {
        &:not(:first-child) {
          margin-left: 0.25em;
        }
      }
    }
  }

  .score-scale-value {
    flex: 0 0 2.5em;
    font-weight: $typography-font-weight-bolder;
    margin-left: 0.5em;
    font-size: 1.2em;
    line-height: 1;
  }
}

.score-scale-bar-wrap {
  @include flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  .score-scale-progress-bar {
    width: 100%;
    height: 10px;
    background-color: $color-grey-300;
    border-radius: 8px;
    overflow: hidden;
  }

  .score-scale-progress-fill {
    height: 100%;
    transition: width 0.5s ease-in-out;
  }
  .score-scale-value {
    flex: 0 0 2.5em;
    font-weight: $typography-font-weight-bolder;
    margin-left: 0.5em;
    font-size: 1.2em;
    line-height: 1;
    text-align: end;
  }
}
