@import 'scss/vars.scss';

section.img-bot-engine {
  .helper-text {
    @include block;
    padding: 1.5rem 1rem;
    p {
      margin-bottom: 0.5rem;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .img-bot-engine-summary {
    margin-top: 2em;
  }
}
