@import 'scss/vars.scss';

.files-block-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-top: 2rem;

  .tab-selector-container {
    @include flex;

    .tab-selector {
      padding: 13px 20px;
      color: $color-grey-550;
      font-weight: $typography-font-weight-bolder;
      font-size: 15px;
      letter-spacing: 0.3px;
      cursor: pointer;

      &--active {
        position: relative;
        color: $color-text;
        background-color: $color-white;
        border-radius: 8px 8px 0 0;
        box-shadow: 0 1px 5px 0 rgba(30, 44, 71, 0.12);

        &::after {
          position: absolute;
          right: 0;
          bottom: -5px;
          left: 0;
          height: 10px;
          background-color: $color-white;
          content: '';
        }
      }
    }
  }

  .tab-content {
    width: 100%;
    padding: 18px 20px;
    color: $color-text;
    background-color: $color-white;
    border-radius: 8px;
    box-shadow: 0 1px 5px 0 rgba(30, 44, 71, 0.12);
    margin-bottom: 1em;

    .tab-header {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-bottom: 15px;
      align-items: center;

      .tab-header-title {
        font-weight: $typography-font-weight-bolder;
        font-size: 18px;
      }

      .download-buttons {
        display: flex;
        flex-direction: row;
        align-items: center;

        .download-btn {
          display: flex;
          flex-direction: row;
          align-items: center;
          padding: 5px 15px;
          font-weight: $typography-font-weight-bolder;
          font-size: 15px;
          background-color: $color-white;
          border: 1px solid $color-grey-dg;
          border-radius: 6px;
          box-shadow: 0 1px 3px 0 rgba(223, 225, 228, 0.5);
          margin: 0.5em;
          svg {
            margin-right: 10px;
          }

          &:focus {
            outline: none;
          }
        }
      }
    }

    .fif-databots {
      margin-top: 1.5rem;

      .title {
        font-size: 1.25rem;
        font-weight: $typography-font-weight-bold;
      }

      .subtitle {
        margin-top: 0.25em;
      }

      .fif-databots-list {
        $_gap: 1em;
        @include flex;
        flex-wrap: wrap;
        margin: calc(-1 * $_gap) 0 0 calc(-1 * $_gap);
        margin-top: 1.5rem;
        width: calc(100% + $_gap);

        > * {
          flex: 1 1 280px;
          max-width: 340px;
          height: 200px;
          margin: $_gap 0 0 $_gap;

        }

        .databot {
          .databot-header {
            height: auto;
            flex: 0 0 40px;
            padding: 10px;
          }

          .databot-details {
            .databot-status-template-middle {
              top: 30%;
            }
          }
        }
      }
    }
  }

  .attachments-table {
    border: 2px solid $color-grey-200;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    width: 100%;
    box-sizing: border-box;
  }
}
