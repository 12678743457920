@import "scss/vars.scss";

.databots-usage-metrics {
  h3 {
    margin: 1.5rem 0 0.5rem;
  }

  .react-bootstrap-table {
    td.email {
      word-wrap: break-word;
    }
  }
}
