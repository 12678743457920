@import 'scss/vars.scss';

@mixin panel-action {
    @include button-reset;
    padding: 8px 16px;
    font-weight: $typography-font-weight-bolder;
    font-size: 12px;
    background: $color-white;
    border: 1px solid #9aa4ac;
    border-radius: 6px;
    box-shadow: 0px 2px 6px rgba(0, 19, 92, 0.06);

    &--cancel {
        margin-right: 10px;
    }

    &--submit {
        color: $color-white;
        background-color: $color-green-dg;
        border-color: transparent;
    }

    &:not(:first-child) {
        margin-left: 1.5em;
    }
}

.databots-config {
    @mixin _kv-title {
        color: $color-blue-dg;
        font-weight: $typography-font-weight-bolder;
        font-size: 1.2em;
    }

    margin: 30px 0;

    .databots-config-heading {
        color: $color-text;
        font-weight: $typography-font-weight-bold;
        font-size: 32px;
        letter-spacing: 0.01em;
    }

    .databots-config-summary {
        @include flex;
        align-items: stretch;
        justify-content: space-between;
        margin-top: 2em;
        column-gap: 20px;

        > .bai-value-block {
            @include block;
            flex: 1 1 16em;
            padding: 1.3em 1.3em;
            border: none;
            box-shadow: 0px 2px 6px rgba(0, 19, 92, 0.06);

            &:first-child {
              flex: 1 1 66%;
            }

            > .title {
                @include flex;
                align-items: center;
                .label {
                    @include _kv-title;
                    flex: 1 1 auto;
                }

                .title-tooltip {
                    display: flex;
                    flex-flow: row nowrap;
                    align-items: center;
                    justify-content: center;
                    width: 1.5em;
                    height: 1.5em;
                    padding: 0.3em;
                    border: 1px solid $color-grey-400;
                    border-radius: 50%;
                    box-shadow: 0 1px 3px 0 $color-grey-500;
                    cursor: default;

                    .icon {
                        color: $color-grey-550;
                        font-size: 0.85em;
                    }
                }
            }

            > .value {
                margin-top: 0.25em;
                font-weight: $typography-font-weight-bolder;
                font-size: 2.5em;
                line-height: 1.2;
                color: $color-text;
            }

            > .subtitle {
                @include _kv-title;
                color: $typography-font-color-normal;
                font-size: 1em;
                text-transform: uppercase;
            }

            > .additional-data {
                margin-top: 0.5em;
                color: $color-grey-500;
                font-weight: $typography-font-weight-bolder;
                line-height: 1.2;

                .value {
                    font-weight: $typography-font-weight-boldest;
                }
            }
        }
    }

    .databots-config-body {
        display: flex;
        flex-direction: column;
        margin-top: 2em;
        padding: 20px;
        background-color: $color-white;
        border-radius: 6px;
        box-shadow: 0px 2px 6px rgba(0, 19, 92, 0.06);

        p {
            margin: 0;
            color: $color-text;
        }

        &-header {
            color: $color-text;
            font-weight: $typography-font-weight-bold;
            font-size: 13px;
            letter-spacing: 0.05em;
            text-transform: uppercase;
        }

        &-actions {
            display: flex;
            justify-content: flex-end;
            margin-top: 20px;

            &__action {
                @include button-reset;
                padding: 8px 16px;
                font-weight: $typography-font-weight-bolder;
                font-size: 12px;
                background: $color-white;
                border: 1px solid #9aa4ac;
                border-radius: 6px;
                box-shadow: 0px 2px 6px rgba(0, 19, 92, 0.06);

                &--cancel {
                    margin-right: 10px;
                }

                &--submit {
                    color: $color-white;
                    background-color: $color-green-dg;
                    border-color: transparent;
                }
            }
        }
    }
}
