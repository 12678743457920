/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v7.0.29,
* Autoprefixer: v9.7.6
* Browsers: last 4 version
*/

@import 'scss/vars.scss';

.sheet-table-actions-columns {
  color: $color-grey-900;
  background-color: $color-white;
  padding: 0.5em 0.25em;
  font-weight: $typography-font-weight-boldest;
  font-size: 1.2em;
  border: solid 2px #e8e9eb;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -ms-flex-line-pack: center;
  align-content: center;

  .highlight-action {
    color: $color-green-dg;
  }

  .actions-header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
    -ms-flex-item-align: center;
    align-self: center;
    div {
      margin: 0 0.25rem;
      -ms-flex-item-align: center;
      -ms-grid-row-align: center;
      align-self: center;
    }

    .actions-icon {
      margin: 0 1rem;
    }
  }
  .actions-button {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
    -ms-flex-item-align: center;
    align-self: center;
    padding-left: 1.25em;

    .show-type-input {
      display: flex;
      padding-right: 0.5em;
      font-weight: 500;

      .label-disabled {
        color: $color-grey-500;
      }
    }
  }
  .actions-scroll {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-ordinal-group: 4;
    -ms-flex-order: 3;
    order: 3;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -ms-flex-item-align: center;
    align-self: center;
    justify-self: flex-end;
    margin-left: auto;
  }
}
.ingestion-columns {
  display: flex;
  flex-direction: column;
  .label-wrapper {
    position: absolute;
    width: 30px;
    .label-backbone {
      background-color: $color-blue-dg-light;
      border-width: 2px;
      border-style: solid;
      padding: 0 5px;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .label-original {
      background-color: $color-grey-300;
      font-weight: $typography-font-weight-boldest;
      border-width: 2px;
      border-style: solid;
      border-color: $color-grey-550;
      color: $color-grey-550;
      padding: 0 5px;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      > div {
        transform: rotate(-90deg);
        padding: 0.5em;
      }
    }
  }

  .table-wrapper {
    display: flex;
    flex: 1 auto;

    overflow-x: scroll;
    max-width: 100%;
    position: relative;

    .loading-placeholder {
      display: flex;
      flex: 1 0 100%;
    }

    .table-loading-modal {
      float: left;
      z-index: 2;
      height: 100%;
      width: 100vw;
      background-color: white;
      opacity: 0.8;
      position: absolute;
      .loading-spinner {
        margin-top: 200px;
        margin-left: 45%;
        font-size: 5em;
      }
    }
    table {
      font-size: 1em;
      width: 100%;
      min-width: 93.5vw;
      border-collapse: initial;
      border-spacing: 0;

      .header-original {
        color: $color-grey-550;
      }

      .row-letter {
        th {
          text-align: center;
        }
      }

      .row-backbone {
        height: 50px;
        th,
        td {
          background-color: $color-white;

          .label-backbone {
            background-color: $color-blue-dg-light !important;
            padding-bottom: 12px;
          }
        }
      }

      tr {
        th,
        td {
          border: 1px solid #e8e9eb;
          background-color: $color-dg-input;
          padding: 0.5em 0.5em;

          &.cell-disabled {
            background-color: $color-grey-300;
            color: $color-grey-500;
            text-align: center;
          }
        }
      }
      .cell-wrapper {
        max-height: 100px;
        max-width: 500px;
      }
      .cell-cutoff {
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        min-width: 100px;
      }

      .cell-backbone {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
        width: 100%;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;

        .cell-text {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
        }

        .cell-check {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          color: $color-green-dg;
          margin-left: 0.5em;
          width: 1em;
        }

        .cell-action {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          margin-left: auto;
        }
      }

      .cell-error {
        border: $color-red-dg solid 2px !important;
        background-color: $color-red-dg-light !important;
        color: $color-red-dg;
        .cell-check {
          color: $color-red-dg;
        }
      }

      .cell-letter {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
        width: 100%;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        justify-items: center;

        &.disabled-trash {
          background-color: transparent !important;
        }
      }

      th .cell-cutoff {
        -webkit-line-clamp: 2;
      }
      tr th:empty,
      tr td:first-of-type:empty {
        position: initial;
        background-color: initial;
        border: 0;
      }
    }
  }
  .table-shadow-right {
    display: none !important;
    position: absolute;
    right: 4.95vw;
    width: 4em;
    z-index: 10;
    background: -o-linear-gradient(to right, transparent, $color-grey-50);
    background: -webkit-gradient(
      linear,
      left top,
      right top,
      from(transparent),
      to($color-grey-50)
    );
    background: -o-linear-gradient(left, transparent, $color-grey-50);
    background: linear-gradient(to right, transparent, $color-grey-50);
  }
}

.loading-wrapper {
  cursor: wait;
  z-index: 1000;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(255, 255, 255, 0.85);

  .loading-spinner {
    display: block;
    margin: 0;
  }
}

.edit-body {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;

  .edit-selection {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding: 0.5em;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;

    button {
      padding: 8px 15px;
      white-space: nowrap;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      border: 1px solid $color-grey-dg-light;
      border-radius: 6px;
      background-color: #fff;
      cursor: pointer;
      margin-bottom: 0.25em;

      &.selected {
        border: $color-orange-dg 1px solid;
        -webkit-box-shadow: 0 0 3px 1px $color-orange-dg;
        box-shadow: 0 0 3px 1px $color-orange-dg;
        font-weight: 700;
      }
    }
  }
}
