@import "scss/vars.scss";

.template-okModal-button {
  min-width: 5rem;
}
.template-okModal-warning-button {
  .btn-warning {
    background-color: $color-yellow-dg;
    border-color: $color-yellow-dg;
    &:hover {
      background-color: scale-color($color: $color-yellow-dg, $lightness: -10%);
      border-color: scale-color($color: $color-yellow-dg, $lightness: -20%);
    }
  }
}
