@import 'scss/vars.scss';

.user-display {
  display: flex;
  align-items: center;
  white-space: nowrap;
  flex-direction: row;
  word-wrap: anywhere;

  .user-avatar {
    align-self: center;
  }
  .user-name {
    align-self: center;
    margin-left: 0.5em;
    white-space: normal;
    text-align: left;
    word-wrap: break-word;
  }

  .user-company {
    margin-left: 0.5em;
    font-weight: $typography-font-weight-bolder;
    word-wrap: break-word;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}
