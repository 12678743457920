@import 'scss/vars.scss';

.template-passThruModal-button {
  min-width: 5rem;
}
.template-passThruModal-warning-button {
  .btn-warning {
    background-color: $color-yellow-dg;
    border-color: $color-yellow-dg;
    &:hover {
      background-color: scale-color($color: $color-yellow-dg, $lightness: -10%);
      border-color: scale-color($color: $color-yellow-dg, $lightness: -20%);
    }
  }
}

.pass-thru-modal .pass-thru-modal-dialog {
  max-width: 70vw;

  .modal-body {
    margin: 0;
  }
}

.pass-thru-modal .modal-footer {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  height: 100%;
  padding: 20px;
  background-color: $color-grey-100;
  border-style: solid;
  border-width: 2px 0 0 0;
  border-top-color: $color-grey-200;
  border-radius: 0 0 0.5em 0.5em;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.05);

  .footer-button {
    min-width: 138px;
    margin: 1em;
  }
}
