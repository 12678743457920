@import "scss/vars.scss";

.bai-circular-progressbar {

  // Status colors list
  // value: status identifier. Null item specifies default colors
  // path: whole circle path(remaining)
  // trail: progressed circle part
  // status: status pill text color
  // status-bckg: status pill background
  $_status_colors: (
      "value": null,
      "path": $color-grey-400,
      "trail": change-color($color-grey-400, $lightness: 90%),
      "status": $color-grey-400,
      "status-bckg": change-color($color-grey-400, $lightness: 90%),
    ),
    (
      "value": "good",
      "path": $color-green-dg,
      "trail": change-color($color-green-dg, $lightness: 85%),
      "status": $color-green-dg,
      "status-bckg": change-color($color-green-dg, $lightness: 85%),
    ),
    (
      "value": "acceptable",
      "path": change-color($color-yellow-dg, $lightness: 40%),
      "trail": change-color($color-yellow-dg, $lightness: 75%),
      "status": change-color($color-yellow-dg, $lightness: 40%),
      "status-bckg": change-color($color-yellow-dg, $lightness: 75%),
    ),
    (
      "value": "poor",
      "path": $color-red-2,
      "trail": change-color($color-red-2, $lightness: 85%),
      "status": $color-red-2,
      "status-bckg": change-color($color-red-2, $lightness: 85%),
    );

  @mixin status-styles($_c) {
    // .status-[value] selector if available
    &#{if(map-get($_c, "value") != null, ".status-" + map-get($_c, "value"), "")} {
      .CircularProgressbar {
        .CircularProgressbar-path {
          stroke: map-get($_c, "path");
        }
        .CircularProgressbar-trail {
          stroke: map-get($_c, "trail");
        }
      }
      .bai-circular-progressbar-label {
        .status-label-wrap {
          .status-label {
            color: map-get($_c, "status");
            background: map-get($_c, "status-bckg");
          }
        }
      }
    }
  }

  // Traverse status colors and generate mixins for each
  @each $_status_color in $_status_colors {
    @include status-styles($_status_color);
  }

  display: flex;
  align-items: center;
  width: 100%;

  .bai-circular-progressbar-label {
    text-align: center;
    .value {
      font-weight: $typography-font-weight-bolder;
      font-size: 3em;
      line-height: 1;
      .unit {
        font-size: 0.5em;
      }
    }
    .label {
      margin-top: 0.25em;
      font-weight: $typography-font-weight-bolder;
    }
    .status-label-wrap {
      @include flex;
      justify-content: center;
      margin-top: 0.75em;
      .status-label {
        margin: 0 auto;
        padding: 0.3em 1em 0.4em 1em;
        font-weight: $typography-font-weight-bolder;
        font-size: 0.85em;
        line-height: 1;
        border-radius: 1em;
      }
    }
  }

  &.semi-circle {
    position: relative;
    padding-top: 50%;
    overflow: hidden;

    .bai-circular-progressbar-inner-wrap {
      position: absolute;
      top: 0;
      width: 100%;
    }

    .bai-circular-progressbar-label {
      margin-top: -9em;
    }
  }

  &.empty .bai-circular-progressbar-label {
    .value {
      font-weight: $typography-font-weight-boldest;
      font-size: 4em;
      opacity: 0.25;
      .unit {
        display: none;
      }
    }
    .label {
      opacity: 0.5;
    }
    .status-label-wrap .status-label {
      opacity: 0.65;
    }
  }
}
