@import "scss/vars.scss";

.metrics-overview-company {
    display: flex;
    flex: 2 1 auto;
    flex-direction: column;
    min-height: 100%;
    margin-right: 18px;

    h1 {
        margin: 0;
        color: $color-text;
        font-weight: $typography-font-weight-normal;
        font-size: 40px;
    }

    &-header {
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;
    }

    &-card {
        display: flex;
        flex-direction: row;
        margin-top: auto;
        padding: 28px;
        font-size: 18px;
        background-color: $color-white;
        border-radius: 8px;

        span {
            font-weight: 700;
        }

        &-details {
            display: flex;
            flex-direction: column;
            margin-left: 24px;

            &-link {
                color: $color-grey-550;
                font-size: 14px;

                span {
                    font-weight: $typography-font-weight-normal;
                }
            }
        }

        &-btn {
            @include button-reset;
            margin-top: 15px;
            padding: 5px 15px;
            color: $color-white;
            font-weight: $typography-font-weight-bolder;
            font-size: 15px;
            line-height: 30px;
            background-color: $color-green-dg;
            border: 1px solid $color-green-2;
            border-radius: 6px;
            box-shadow: 0px 2px 3px rgba(32, 192, 148, 0.2);
        }

        &-logo {
            width: 160px;
            height: 80px;
            cursor: pointer;

            img {
                width: 160px;
                height: 100%;
                object-fit: contain;
            }

            &--disabled {
                cursor: default;
            }
        }
    }

    &--customer {
        margin-right: 0;
    }
}

@media (max-width: 1300px) {
    .metrics-overview-company {
        width: 100%;
        margin-right: 0;
    }
}
