@import 'scss/vars.scss';

.files-table-container {
  border: 2px solid $color-grey-200;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  width: 100%;
  box-sizing: border-box;
  overflow-x: auto;
}

.load-logo-spinner-wrapper {
  justify-content: space-evenly;
  display: flex;
  position: relative;
  width: 80px;
  height: 100px;
  transform: translateY(0.5em);
  opacity: 0.5;
  margin: auto;
  text-align: center;
}

.files-row-bots-loading-spinner {
  display: flex;
  font-size: 1.5em;
  opacity: 0.5;
}

.files-table-header-row {
  display: flex;
  flex-direction: row;
  flex: 1 0 0px;
  align-items: center;
  min-height: 40px;
  min-width: 920px;
  color: $color-grey-550;
  font-weight: $typography-font-weight-light;
  border-bottom: 2px solid $color-grey-200;
}

.files-table-rows {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  min-width: 100%;
  min-height: 60px;
  color: $color-text;
  font-size: 13px;
  max-height: 400px;
}

.file-row:hover {
  background-color: rgb(240, 240, 240);
}

.files-table {
  ////////////////////////////////////
  // Files Table Styling Structure:
  //
  // &.COLUMN {
  //   .HEADER {}
  //   .CELL {}
  // }
  //
  ////////////////////////////////////

  &.select-column {
    flex: 0 0 80px;
    justify-content: center;
    // margin: auto 5px auto 5px;
    font-size: $typography-font-size-root;
    text-align: center;

    .select-header {
      display: flex;
      align-self: flex-start;
      align-content: center;
      margin-top: 0.75em;
      margin-bottom: 0.75em;
      text-align: left;
    }
  }

  &.filename-column {
    flex: 0 1 300px;
    min-width: 100px;
    max-width: 375px;
    margin-left: 1em;

    &.filename-cell {
      text-align: center;
      margin-top: 0.75em;
      margin-bottom: 0.75em;
      text-align: left;

      .filename-container {
        @include flex;
        min-width: 100px;
        overflow: hidden;

        .filename {
          overflow: hidden;
          font-weight: $typography-font-weight-boldest;
          text-overflow: ellipsis;
        }

        .preview-link {
          margin-top: -0.1em;
          margin-left: 0.5em;
          font-size: 1.2em;

          .link {
            opacity: 0.15;
          }
        }

        &:hover {
          .filename {
            white-space: normal;
            word-wrap: break-word;
          }

          .preview-link {
            .link {
              opacity: 1;
            }
          }
        }
      }
    }
  }

  &.date-column {
    flex: 0 1 190px;
    min-width: 125px;
    margin-left: 1em;

    .date-header {
      min-width: 100px;
    }
    &.date-cell {
      .written-date {
        font-weight: $typography-font-weight-bolder;
        margin-right: 0.5rem !important;
        word-break: break-all;
      }
      .written-time {
        white-space: pre;
      }
    }
  }

  &.comment-column {
    display: flex;
    flex: 0 1 115px;
    min-width: 80px;
    justify-content: center;

    &.comment-cell {
      align-items: center;
      align-content: center;
      box-sizing: border-box;
      // padding-left: 15px;
      cursor: pointer;

      .comments-indicator {
        display: flex;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;
        min-width: 22px;
        // margin-left: 5px;
        // padding: 2px 7px;
        color: $color-white;
        font-size: 12px;
        background-color: #d95a46;
        border-radius: 50%;
      }

      .comments-indicator--inactive {
        background-color: $color-grey-dg;
      }
    }
  }

  &.bots-column {
    flex: 0 0 180px;
    margin-left: 1em;
  }

  &.status-column {
    display: flex;
    flex: 1 1 145px;
    min-width: 175px;
    margin-left: 1em;
    margin-right: 16px;
    justify-content: flex-start;
    text-align: left;

    .icon-circle {
      display: inline-block;
      width: 1.5em;
      height: 1.5em;
      padding: 0.3em;
      border-radius: 50%;
      box-shadow: 0 1px 3px 0 $color-grey-500;
    }
  }

  &.assessment-column {
    flex: 1 0 120px;
    min-width: 90px;
    max-width: 200px;
    margin-right: 1em;
  }

  &.privacy-column {
    flex: 1 1 50px;
    max-width: 200px;
  }

  &.actions-column {
    margin-right: 1em;
    max-width: 100px;
    flex: 0 1 50px;
    margin-left: auto;

    &.actions-dropdown {
      text-align: center;
      cursor: pointer;
      max-width: 75px;

      .dropdown-toggle {
        min-width: unset;
        width: 40px;
        height: 32px;
        color: $color-grey-550 !important;
        background: $color-white !important;
        border: 0.1rem solid $color-grey-300 !important;
        border-radius: 0.5rem !important;
        box-shadow: 0 0 1px $color-grey-400 !important;
        margin: auto;

        &:hover {
          border-color: $color-grey-400 !important;
        }
      }
      .dropdown-toggle::after {
        margin: 0;
        border: none;
      }
    }
  }
}

.attachments-table-row {
  flex: 1 0 0px;
  min-height: 60px;
  //no more flex for table rows - flex and borders don't play well
  min-width: 920px;
  border-bottom: 2px solid $color-grey-200;
}

.attachments-table-row__upload-user,
.attachments-table-comments__infos {
  display: flex;
  align-items: center;
}

.attachments-table-row__avatar {
  width: 20px;
  height: 20px;
  margin-right: 10px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: 10px;
}

.attachments-table-row__comments {
  @extend .files-table;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  cursor: pointer;
}

.attachments-table-row__comments-indicator {
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  min-width: 22px;
  margin-left: 5px;
  padding: 2px 7px;
  color: $color-white;
  font-size: 12px;
  background-color: #d95a46;
  border-radius: 50%;
}

.attachments-table-row__comments-indicator--inactive {
  background-color: $color-grey-dg;
}

.attachments-table-comments {
  flex: 1 0 auto;
  box-sizing: border-box;
  padding: 20px;
  padding-bottom: 0;
  background-color: $color-dg-input;
  border-bottom: 2px solid $color-grey-dg;
}

.attachments-table-comments__title {
  display: block;
  margin-bottom: 20px;
  color: $color-text;
  font-size: 13px;
}

.attachments-table-comments__coment {
  margin-bottom: 20px;
}

.attachments-table-comments__text {
  display: block;
  margin-bottom: 5px;
  color: $color-text;
  font-size: $typography-font-size-root;
}

.attachments-table-comments__name-letter {
  box-sizing: border-box;
  margin-right: 5px;
  padding: 2px 8px;
  color: $color-white;
  font-size: 13px;
  background-color: #d95a46;
  border-radius: 50%;
}

.attachments-table-comments__info {
  color: $color-text;
  font-size: 13px;
}

.attachments-table-comments__add-form {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  margin-right: -25px;
  margin-left: -25px;
  padding: 10px 25px;
  background-color: $color-white;
  border-top: 1px solid $color-grey-dg;
}

.attachments-table-comments__add-form-input {
  flex: 1;
  box-sizing: border-box;
  min-height: 40px;
  margin-right: 15px;
  padding-right: 15px;
  padding-left: 15px;
  font-size: 15px;
  background-color: $color-dg-input;
  border: 1px solid $color-grey-dg;
  border-radius: 8px;
}

.attachments-table-comments__add-form-input:focus {
  background-color: $color-white;
}

.attachments-table-comments__add-form-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  min-width: 100px;
  min-height: 40px;
  color: $color-white;
  font-size: 15px;
  background-color: $color-green-dg;
  border: 1px solid $color-green-2;
  border-radius: 6px;
  box-shadow: 0 2px 3px 0 rgba(32, 192, 148, 0.2);
  cursor: pointer;
}

#overlayer {
  width: 100%;
  height: 100%;
}

.attachments-table-row__actions {
  position: relative;
  left: 75%;
  z-index: 1;
  box-sizing: border-box;
  width: -moz-fit-content;
  width: fit-content;
  background-color: $color-white;
  border: 1px solid $color-grey-dg;
  border-radius: 8px;
  box-shadow: 0 2px 6px 0 rgba(30, 44, 71, 0.2);
}
.attachments-table-row_item_item-deletebutton-confirmbody {
  overflow-wrap: break-word;
}

.actions-button {
  .load-logo-spinner-wrapper {
    width: 1.2em;
    height: 1.2em;
    vertical-align: middle !important;
  }
}
.attachments-table-row__action {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  margin-bottom: 2px;
  padding: 10px 20px;
  color: $color-text;
  font-size: $typography-font-size-root;
  cursor: pointer;
  &-icon {
    width: 1rem !important;
    height: 1rem !important;
  }
}

.attachments-table-row__action:last-child {
  margin-bottom: 0;
}

.data-job-panel-files-block {
  .file-row {
    .distributor-job-comments-2 {
      padding: 0 1em 1.5em 1em;
      background: $color-white;
    }
  }
}
