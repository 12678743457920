@import 'scss/vars.scss';

.data-job-details-selection {
  &-wrapper {
    margin-top: 1.5em;

    .styled-multiselect-container-invalid {
      display: block;
    }
  }

  &-text {
    color: $color-black;
    font-weight: $typography-font-weight-bold;
    font-size: 15px;
  }

  &-text:not(:first-child) {
    margin-top: 1em;
  }

  &-textarea {
    resize: none;

    &-overflow {
      background-position: right 15px top 5px !important;
    }
  }
}
