@import 'scss/vars.scss';

@mixin title {
  font-weight: $typography-font-weight-bold;
  font-size: 1.3em;
  white-space: nowrap;
}

.assessment-admin-panel {
  @include flex;
  align-items: stretch;
  background: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 0.5em;
  display: flex;
  flex-direction: column;
  margin-top: 0.75em;
  padding: 0.75em 1em;
  width: 100%;

  .overview {
    position: relative;
    margin-right: 1.5em;
    padding-right: 1.5em;

    .title {
      @include title;
      margin-bottom: 0.5em;
    }

    .status-text {
      white-space: nowrap;
      .label {
        margin-right: 0.5em;
        font-weight: $typography-font-weight-boldest;
      }
      .value {
        margin-right: 0.25em;
      }
    }
  }

  .actions {
    display: flex;
    justify-items: flex-end;

    .btn-start-ingestion {
      margin-left: auto;
    }
  }
}
