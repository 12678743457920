@import 'scss/vars.scss';

.file-container-group:hover {
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);
}

.file-container-group > :first-child {
  width: 100%;
}
.file-info {
  padding-right: 5px;
}

.file-date-created-at {
  color: $color-grey-500;
  font-size: 12px;
  font-family: Inter;
  font-weight: 700;
  word-wrap: break-word;
}

.file-time-created-at {
  color: $color-grey-500;
  font-size: 12px;
  font-family: Inter;
  font-weight: 500;
  word-wrap: break-word;
}

.file-name {
  color: $color-grey-900;
  font-size: 14px;
  font-family: Inter;
  font-weight: 500;
  word-wrap: break-word;
}

.download-item {
  padding: 10px;
  border-left: 1px $color-grey-400 solid;
}
