@import 'scss/vars.scss';

.section-attribute-analytics {
  @include block;
  display: flex;
  flex-direction: column;
  margin-top: 0.75em;
  padding: 0.75em 1em;
  width: 100%;

  .attributes-title {
    width: 100%;
    line-height: 1.5;
    display: flex;
    align-items: baseline;

    .attribute-legend {
      display: flex;

      .icon-valid {
        display: flex;
        align-items: center;
        margin-left: 1em;
        .color-box {
          width: 1em;
          height: 1em;
          display: inline-block;
          background-color: $color-green-dg;
          margin-right: 0.5em;
        }
      }
      .icon-fill {
        display: flex;
        align-items: center;
        margin-left: 0.5em;
        .color-box {
          width: 1em;
          height: 1em;
          display: inline-block;
          background-color: $color-green-dg-light;
          margin-right: 0.5em;
        }
      }
    }
  }
  .attributes-display {
    margin-top: 1em;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    flex: 1 0 auto;

    .attr-item {
      flex: 0 0 50%;
      min-width: 475px;
      padding: 0.5em;
    }
  }

  .attributes-footer {
    display: flex;
    justify-content: center;
    margin-top: 1.5em;

    button {
      font-weight: 700;
      background: none !important;
      border: none;
      padding: 0 !important;
      color: $color-text;
      cursor: pointer;
    }
  }
}
