@import "scss/vars.scss";

.secondary-btn {
  box-sizing: border-box;
  padding: 8px 15px;
  white-space: nowrap;
  display: flex;
  align-items: center;
  border: 1px solid $color-grey-dg;
  border-radius: 6px;
  background-color: $color-white;
  box-shadow: 0 1px 3px 0 rgba(223, 225, 228, 0.5);
  cursor: pointer;
  &.disabled {
    opacity: 0.5;
    cursor: default;
  }
  &.sm {
    padding: 4px 8px;
    font-size: 0.8em;
  }
  .btn-icon {
    margin-right: 10px;
  }
  .btn-label {
    color: $color-grey-900;
    font-weight: $typography-font-weight-bolder;
  }
}
.bai-btn-label {
  font-size: .7em;
  word-wrap: none;
}
