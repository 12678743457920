@import "scss/vars.scss";

.company-metrics-card {
    flex: 1 1 auto;
    width: 100%;
    max-width: 250px;
    min-height: auto;
    background-color: $color-white;
    border: 1px solid $color-grey-dg;
    border-radius: 8px;
    box-shadow: 0px 2px 6px rgba(30, 44, 71, 0.04);

    &-header {
        min-height: 90px;
        padding: 15px 20px;
        color: $color-white;
        font-size: 28px;
        background-color: $color-green-dg;
        border-radius: 8px 8px 0 0;

        span {
            font-weight: $typography-font-weight-bolder;
        }

        h2 {
            margin: 0;
            color: $color-white;
            font-weight: $typography-font-weight-normal;
            font-size: 18px;
        }
    }

    &-body {
        padding: 0 20px;
    }

    &-list {
        @include unstyled-list;
        font-size: 13px;

        p {
            margin: 0;
        }

        li {
            margin: 20px 0;
        }

        &-item {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            &-label {
                font-weight: 700;
            }
        }
    }
}
