@import 'scss/vars.scss';

.sheet-disabled {
  background-color: $color-black;
  opacity: 0.5;
}
.sheet-table-actions-rows {
  color: $color-grey-900;
  background-color: $color-white;
  padding: 0.5em 0.25em 0.5em 0;
  font-weight: $typography-font-weight-boldest;
  font-size: 1.2em;
  border: solid 2px $color-grey-dg;
  display: flex;
  flex-wrap: nowrap;
  align-content: center;

  &.step-0 {
    .highlight-action {
      color: $color-green-dg;
    }
    .highlight-sheet {
      color: $color-green-dg;
    }
  }
  &.step-1 {
    .highlight-action {
      color: $color-blue-dg;
    }
    .highlight-sheet {
      color: $color-blue-dg;
    }
  }
  .actions-header {
    display: flex;
    order: 1;
    align-self: center;
    align-items: center;
    text-align: center;
    div {
      align-self: center;
    }

    .actions-icon {
      margin: 0 1rem;
    }
  }
  .actions-button {
    display: flex;
    order: 2;
    align-self: center;
    padding-left: 0.25em;
  }
  .actions-scroll {
    display: flex;
    order: 3;
    flex-wrap: nowrap;
    align-self: center;
    justify-self: flex-end;
    margin-left: auto;
  }
}
.ingestion-rows {
  display: flex;
  flex-direction: column;
  .label-wrapper {
    position: absolute;
    .label-backbone {
      border-width: 2px;
      border-style: solid;
      padding: 0 5px;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;

      &.header-prediction {
        background-color: $color-green-dg-light;
      }

      &.row-prediction {
        background-color: $color-blue-dg-light;
      }
    }
    .label-check {
      background-color: $color-green-dg-light;
      border-width: 2px;
      border-style: solid;
      border-color: $color-green-dg;
      color: $color-green-dg;
      padding: 0 5px;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .table-wrapper {
    overflow-x: scroll;
    max-width: 100%;
    position: relative;

    .table-ingestion-rows {
      font-size: 1em;
      width: 100%;
      min-width: 93.5vw;
      th,
      td {
        border: 1px solid #e8e9eb;
        background-color: $color-white;
        padding: 0.5em 0.25em;
      }
      .cell-wrapper {
        max-height: 100px;
        max-width: 400px;
      }
      .cell-cutoff {
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        min-width: 100px;
      }

      .selected-header {
        font-weight: $typography-font-weight-boldest;
        td {
          background-color: $color-green-dg-light;
        }
      }
      .confirmed-header {
        font-weight: $typography-font-weight-boldest;
        td {
          background-color: $color-green-dg-light;
        }
      }
      .selected-first-row {
        td {
          background-color: $color-blue-dg-light;
        }
      }
      .tutorial-row-highlight {
        z-index: 2000;
        position: relative;
        background-color: $color-red-dg-light !important;
        border: solid $color-red-dg 1px !important;
      }
    }
  }
  .table-shadow-right {
    display: none !important;
    position: absolute;
    width: 4em;
    z-index: 10;
    background: -webkit-linear-gradient(to right, transparent, $color-grey-50);
    background: -o-linear-gradient(to right, transparent, $color-grey-50);
    background: -moz-linear-gradient(to right, transparent, $color-grey-50);
    background: linear-gradient(to right, transparent, $color-grey-50);
  }
}
