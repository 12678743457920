@import "./vars-generic.scss";
@import "./vars-colors.scss";
@import "./vars-typography.scss";
@import "./vars-mixins.scss";

$_exported_vars_colors: (
    black: $color-black,
    errorRed: $color-red-errors-negative,
    signalWarning: $color-signal-word-warning,
    orangeMain: $color-orange-main,
    selectOrange: $color-orange-dg,
    selectOrangeLight: $color-orange-dg-light,
    grey: $color-grey-5,
    /** ------ Design guide colors ------ */
    designWhite: $color-white,
    designBlack: $color-grey-900,
    designGrey200: $color-grey-200,
    designGrey: $color-grey-dg,
    designGreyLight: $color-grey-dg-light,
    designGrey50: $color-grey-50,
    designGrey550: $color-grey-550,
    designGreen: $color-green-dg,
    designGreenLight: $color-green-dg-light,
    designYellow: $color-yellow-dg,
    designYellowLight: $color-yellow-dg-light,
    designOrange: $color-orange-dg,
    designOrangeLight: $color-orange-dg-light,
    designRed: $color-red-dg,
    designRedLight: $color-red-dg-light,
    designBlue: $color-blue-dg,
    designLogoBlue: $color-logo-blue,
    designBlueLight: $color-blue-dg-light,
    designInput: $color-dg-input,
    designSearch: $color-dg-search,
  );
$_exported_vars_typography: (
    fontSizeNormal: $typography-font-size-normal,
    fontSizeRoot: $typography-font-size-root,
  );

/* stylelint-disable */
:export {
  @each $key, $value in $_exported_vars_colors {
    colors_#{unquote("" + $key)}: #{unquote("" + $value)};
  }
  @each $key, $value in $_exported_vars_typography {
    typography_#{unquote("" + $key)}: #{unquote("" + $value)};
  }
}
/* stylelint-enable */