@import 'scss/vars.scss';

.scroll-actions {
  display: flex;
  flex-wrap: nowrap;
  align-self: center;
  justify-self: flex-end;
  margin-left: auto;

  &.scroll-tutorial {
    z-index: 2000;
    position: relative;
    background-color: $color-white !important;
    border: solid $color-grey-50 1px !important;
  }

  button {
    border: 0;
    background-color: inherit;
  }

  button:disabled {
    color: $color-grey-500;
  }

  .scroll-label {
    font-weight: $typography-font-weight-light;
    font-size: 0.8em;
  }
}
