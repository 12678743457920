@import "scss/vars.scss";

.view-image-quality-assessments-faq {
  $_block-padding: 1.5rem;

  > section:not(:first-child) {
    margin-top: $_block-padding;
  }

  > section.heading-stats {
    .data-items {
      @include unstyled-list;
      @include flex;
      align-items: stretch;

      .data-item {
        flex: 1 1 16em;
        padding: $_block-padding;

        &:first-child {
          flex: 1 1 66%;
        }

        &:not(:first-child) {
          margin-left: 2em;
        }

        .title {
          .label {
            white-space: nowrap;
          }
        }
      }
    }
  }

  > section.title {
    @include flex;
    align-items: flex-start;
    justify-content: space-between;

    .label {
      font-size: 2.2rem;
    }

    .actions {
      margin: 0.5em 0 0 1.5em;
      white-space: nowrap;
    }
  }

  > section.subtitle {
    margin-top: 0;
  }
}
