@import "scss/vars.scss";

.weekly-company-card {
    display: flex;
    flex-direction: column;
    padding: 20px 30px;
    background-color: $color-white;
    border-radius: 8px;
    box-shadow: 0px 2px 6px rgba(30, 44, 71, 0.04);

    &-header {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        &-title {
            margin: 0;
            margin-right: 15px;
            color: $color-text;
            font-weight: $typography-font-weight-bolder;
            font-size: 28px;
        }

        &-group {
            display: inline-flex;
            align-items: center;
        }
    }

    &-btn {
        @include button-reset;
        min-width: 150px;
        height: 40px;
        padding: 5px 15px;
        color: $color-text;
        font-weight: $typography-font-weight-bolder;
        font-size: 15px;
        background: $color-white;
        border: 1px solid $color-grey-dg;
        border-radius: 6px;
        box-shadow: 0px 1px 3px rgba(223, 225, 228, 0.5);
    }

    &-toggle {
        @include button-reset;
        display: inline-flex;
        align-items: center;
        margin: 0 10px;
        color: $color-text;
        font-size: 15px;

        svg {
            margin-left: 10px;
            font-size: 11px;
        }

        &:focus,
        &:active {
            outline: none;
        }
    }

    &-table {
        margin-top: 20px;

        .metrics-table {
            margin-bottom: 10px;

            &-period--positive {
                color: $color-green-dg;
            }

            &-period--negative {
                color: $color-red-dg;
            }
        }

        .react-bootstrap-table {
            border-top-left-radius: 8px;
            border-top-right-radius: 8px;

            thead tr th:first-child,
            tbody tr td:first-child,
            tfoot tr th:first-child {
                padding-left: 1.5em;
            }

            thead tr th:not(:first-child),
            tbody tr td:not(:first-child),
            tfoot tr th:not(:first-child) {
                text-align: right;
            }

            thead tr th:last-child,
            tbody tr td:last-child,
            tfoot tr th:last-child {
                padding-right: 1.5em;
                font-weight: $typography-font-weight-bolder;

                .label {
                    font-weight: $typography-font-weight-bolder;
                }
            }

            td {
                padding: 1em 0.25em;
            }

            thead {
                th {
                    padding: 0.75em 0.25em;

                    &:not(:first-child) {
                        .th-wrap {
                            justify-content: flex-end;
                        }
                    }
                }
            }
        }
    }
}
