@import "scss/vars.scss";

.assessments-card {
  display: flex;
  flex-direction: column;
  padding: 16px 20px;
  background-color: $color-white;
  border-radius: 6px;

  &-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;

    &-title {
      margin: 0;
      font-weight: $typography-font-weight-bolder;
      font-size: 16px;

      a {
        color: $color-text;

        &:hover {
          color: $color-text;
        }
      }

      &:hover {
        text-decoration: underline;
      }
    }
  }

  &-btn {
    @include button-reset;
    padding: 10px 20px;
    color: $color-text;
    font-weight: $typography-font-weight-bolder;
    font-size: 12px;
    background-color: $color-white;
    border: 1px solid $color-grey-dg;
    border-radius: 6px;
    box-shadow: 0px 1px 3px rgba(223, 225, 228, 0.5);

    svg {
      margin-left: 8px;
    }

    &:hover {
      background-color: linear-gradient(180deg, rgba(255, 255, 255, 0.5) 0%, rgba(243, 243, 247, 0.499167) 100%),
        $color-white;
    }
  }
}

.assessments-table {
  display: flex;
  flex-direction: column;
  margin-bottom: 35px;
  font-size: 13px;

  &-file-details {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    text-overflow: ellipsis;

    &__name {
      font-weight: $typography-font-weight-bolder;
      word-wrap: anywhere;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &__size {
      margin-top: 4px;
      color: $color-grey-550;
    }
  }

  &-user-img {
    display: inline-flex;
    align-items: center;
    overflow: hidden;

    &-name {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    img {
      width: 24px;
      height: 24px;
      margin-right: 10px;
      object-fit: contain;
      border-radius: 50%;
    }
  }

  &-uploaded-at__date {
    font-weight: $typography-font-weight-bolder;
  }

  &-view-assessment {
    @include button-reset;
    padding: 10px 15px;
    color: $color-white;
    font-weight: $typography-font-weight-bolder;
    font-size: 15px;
    background-color: $color-green-dg;
    border: 1px solid $color-green-2;
    border-radius: 6px;
    box-shadow: 0px 2px 3px rgba(32, 192, 148, 0.198885);
  }

  .react-bootstrap-table {
    border: 1px solid $color-grey-dg;
    border-radius: 0;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    box-shadow: none;

    .table {
      table-layout: auto;
    }

    thead tr th:first-child,
    tbody tr td:first-child,
    tfoot tr th:first-child {
      padding-left: 1em;
    }

    thead {
      border-bottom: 1px solid $color-grey-dg;
    }

    th,
    td {
      min-width: 120px;
      padding: 0.75em 0.25em;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;

      &:hover {
        @include break-word-wrap;
        white-space: normal;
      }
    }

    .file_name {
      overflow: hidden;
      max-width: 250px;
      min-width: 250px;
      padding-right: 1.5em;
    }

    .transformation_status {
      font-weight: $typography-font-weight-bolder;
    }

    .actions {
      display: flex;
      justify-content: flex-end;
    }

    .uploaded_by,
    .transformation_status,
    .uploaded_at {
      width: 200px;
    }

    th .th-wrap .label {
      color: $color-grey-550;
      font-size: 13px;
    }

    tbody tr:not(:last-child) {
      border-bottom: 1px solid $color-grey-dg;
    }

    tr {
      color: $color-text;
      font-size: 13px;

      .user {
        display: inline-flex;
        align-items: center;
      }
    }
  }
}
