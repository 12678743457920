@import 'scss/vars.scss';

.img-bot-engine-dashboard {
  .new-job-section {
    margin-bottom: 2em;
  }
}

.custom-width {
  width: 90%;
}
.row-space {
  margin-bottom: 1.7em;
}

.ids-filters-row {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  margin-bottom: 2em;

  .ids-filters-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 0.75em 2em;
    grid-template-areas: 'kw usr cd ud';

    flex: 1 1 auto;
    align-items: flex-end;

    margin-right: 1em;

    .note {
      font-weight: 600;
    }

    &.admin {
      grid-template-columns: 1fr 1fr 1fr;
      grid-template-areas:
        'kw cd ud'
        'usr mfr cli';
    }

    &.self-service {
      grid-template-columns: 1fr 1fr 1fr;
      grid-template-areas: 'kw cd ud';
    }

    .ids-filter {
      &.keyword {
        grid-area: kw;
        input {
          min-height: 38px;
        }
      }
      &.started-by {
        grid-area: usr;
      }
      &.manufacturer {
        grid-area: mfr;
      }
      &.client {
        grid-area: cli;
      }
      &.created-at {
        grid-area: cd;
      }
      &.updated-at {
        grid-area: ud;
      }
    }
  }
}
