@import 'scss/vars.scss';

.slightlyGrow {
  transition: all 0.2s ease-in-out;
  &:hover {
    transform: scale(1.02);
  }
}

@keyframes slide-in {
  0% {
    transform: translateX(1000%) rotate(1080deg);
  }
  100% {
    transform: translateX(0%);
  }
}

// TODO: It is a bad practice to use decorating classes. Extract to mixins and include
// into semantic classes where needed.
.slightlyTumbleIn {
  &:hover {
    .prototype-card {
      animation: slide-in 1s forwards;
    }
  }
}

.slightlyBorder {
  transition: all 0.2s ease-in-out;
  &:hover {
    transform: scale(1.02);
    border: 1px solid $color-orange-dg;
    border-radius: 0.5em;
  }
}

.slightlyBoxShadow {
  transition: all 0.2s ease-in-out;
  &:hover {
    box-shadow: 0 0 6px rgba($color-orange-dg, 0.7);
  }
}

.slightlyDarken {
  &:hover {
    background-color: darken($color-white, 2%);
  }
}

.prototype-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin: auto;
  justify-content: space-evenly;
  &-header {
    width: 100%;
    margin-top: 10px;
    margin-bottom: 10px;
    text-align: center;
  }
}

.prototype-row {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin: auto;
  justify-content: space-evenly;
}
