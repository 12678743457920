@import 'scss/vars.scss';

.slate-rte-text-editor-wordcount {
  display: inline-block;
  width: 100%;
  text-align: right;
  color: $color-grey-500;
  &-red {
    width: 100%;
    text-align: right;
    color: $color-red-dg;
    animation: pulse 2s 3;
  }
}
.slate-rte-text-editor {
  background-color: $color-white;
  padding: 5px;
  border: 0.1rem solid $color-grey-400;
  border-radius: 0.5rem;
  min-height: 15rem;
  height: 15rem;
  display: flex;
  flex-direction: column;
  .slate-rte-text-editable {
    height: 70%;
    overflow-y: auto;
    overflow-wrap: break-word;
    word-wrap: break-word;

    .comment-mention {
      padding: 3px 3px 2px;
      margin: 0 1px;
      vertical-align: baseline;
      display: inline-block;
      border-radius: 4px;
      background-color: $color-grey-300;
      font-size: 0.9em;
      font-weight: $typography-font-weight-boldest;
      :focus {
        box-shadow: 0 0 0 2px #b4d5ff;
      }
    }
  }

  &--disabled {
    @extend .slate-rte-text-editor;
    pointer-events: none;
    opacity: 0.5;
    min-height: 15rem;
    height: 15rem;
    .slate-rte-text-editable {
      height: 80%;
      overflow-y: auto;
    }
  }
  &:hover {
    box-shadow: 0 0 1px $color-grey-550;
    border-radius: 0.5rem;
    border-color: $color-grey-400;
    border-width: 0.1rem;
  }
  &:focus-within {
    border-width: 0.1rem;
    border-radius: 0.5rem;
    box-shadow: 0 0 1px 0.1rem $color-orange-dg;
    border-color: $color-orange-dg;
  }
}
