@import 'scss/vars.scss';

.header-notifications {
  .dropdown-toggle {
    position: relative;
    &::after {
      display: none;
    }

    .loading-spinner {
      display: none;
      position: absolute;
      top: 0.25em;
      left: 0.25em;
      font-size: 0.5em;
      opacity: 0.5;
    }
  }

  &.loading .loading-spinner {
    display: block;
  }

  &.has-new-items .dropdown-toggle {
    position: relative;
    &::after {
      display: block;
      position: absolute;
      width: 0.5em;
      height: 0.5em;
      background: $color-orange-dg;
      content: '';
      border: none;
      margin: 0;
      top: 0.5em;
      right: 0.6em;
      border-radius: 50%;
      box-shadow: 0 0 1px 2px $color-white;
    }
  }

  &.empty .dropdown-toggle .icon {
    opacity: 0.25;
  }

  .dropdown-menu {
    width: 25em;
    padding-bottom: 0;

    > .header {
      @include flex;
      font-size: 0.85em;
      padding: 0 0.75em 1em 0.75em;

      .title {
        font-weight: $typography-font-weight-boldest;
        flex: 1 1 0;
      }

      .new-count {
        font-weight: $typography-font-weight-boldest;
        color: $color-orange-dg;
      }
    }

    .notifications-list {
      @include unstyled-list;
      border-top: 1px solid $color-grey-300;
      > li {
        border-bottom: 1px solid $color-grey-300;
      }

      .header-notification-item {
        cursor: pointer;
        background: $color-grey-50;
        padding: 0.25em 0.75em;
        @include flex;

        .left-wrap {
          position: relative;
          align-self: center;
          flex: 0 1 3rem;
          .notif-image-wrapper .notif-image {
            display: block;
            object-fit: contain;
            width: 3rem;
            height: 3rem;
            box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 1);
            border-radius: 50%;

            &.placeholder {
              opacity: 0.25;
            }
          }
        }

        .center-wrap {
          flex: 1 1 auto;
          font-size: 0.85em;
          margin: 0 1em;
          min-width: 10%;
          overflow: hidden;

          .data-job-title {
            font-weight: $typography-font-weight-boldest;

            overflow-wrap: break-word;
          }

          .ellipsis {
            color: $color-grey-500;
          }
        }

        .right-wrap {
          font-size: 0.85em;
          align-self: stretch;
          position: relative;
          min-height: 6em;
          .date {
            white-space: nowrap;
          }
          .icon {
            display: block;
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
          }
        }

        &.is-new-item {
          background: $color-white;
          .left-wrap::after {
            display: block;
            position: absolute;
            width: 0.75em;
            height: 0.75em;
            background: $color-orange-dg;
            content: '';
            bottom: 0;
            left: 0;
            border-radius: 50%;
            box-shadow: 0 0 1px 2px $color-white;
          }

          .center-wrap {
            font-weight: $typography-font-weight-boldest;
          }
        }
      }
    }

    .view-all {
      font-size: 0.85em;
      padding: 0.5em 0.75em;
      text-align: center;
      cursor: pointer;
    }
  }
}

@include ie11-hack {
  .header-notifications .dropdown-menu .notifications-list .header-notification-item .left-wrap {
    flex-shrink: 0;
    .notif-image-wrapper {
      display: flex;
      height: 3rem;
      box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 1);
      align-items: center;
      border-radius: 50%;
      overflow: hidden;
      .notif-image {
        padding-top: 0.1rem;
        margin: auto;
        border-radius: 0;
        box-shadow: none;
        height: auto;
      }
    }
  }
}
