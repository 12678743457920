body[data-page="view-products"] {
  background: $color-grey-50;
}

.view-products {
  .loading-ovly {
    display: none;
  }

  .react-bootstrap-table {
    margin-top: 1.5em;

    tbody {
      tr:hover {
        cursor: pointer;
      }
    }
  }

  &.loading {
    .loading-ovly {
      @include loading-ovly
    }
  }

  .keyword-search-block {
    position: relative;
    margin: 1.5em 0 1em 0;
    max-width: 30em;
  }

  .bootstrap-table-wrap {
    position: relative;
  }

  .product-block {
    display: flex;
    flex-flow: row nowrap;

    .product-image {
      margin-right: 0.5em;
      width: 3.5em;
      height: 3.5em;
      flex: 0 0 3.5em;
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;
      border: 1px solid $color-grey-200;
      background: $color-white;

      img {
        display: block;
        width: 90%;
        height: 90%;
        object-fit: contain;
      }

      svg {
        font-size: 2.5em;
        color: $color-grey-200;
      }
    }

    .product-info {
      .product-name {
        font-size: 1.1em;
        font-weight: $typography-font-weight-bolder;
        a {
          color: $typography-font-color-normal;
          &:hover {
            text-decoration: underline;
          }
        }
      }

      .product-identifiers {
        margin-top: 0.5em;
        font-size: 0.9em;
        color: $color-grey-500;
        .kv-pair > span + span {
          font-weight: $typography-font-weight-bolder;
        }
      }
    }
  }

  td.un {
    font-size: 1.25rem;
    font-weight: $typography-font-weight-bolder;
    color: $color-grey-700;
  }

  .react-bootstrap-table {
    margin-top: 1.5em;

    thead {
      /* prettier-ignore */
      tr {
        th.name { width: 20em; }
        th.manufacturer { width: 12em; }
        th.un { width: 6em; }
        th.status { width: 7em; }
        th.updated_at { width: 7.5em; }
        th.actions { width: 3em; }
      }
    }

    tbody {
      tr {
        td.actions {
          text-align: center;
          opacity: 0.7;

          a {
            display: inline-block;
            color: $color-white;
            background: $color-blue-light;
            border-radius: 2em;
            width: 2.2em;
            height: 2.2em;
            line-height: 2;

            &:hover {
              background: $color-blue-dark;
            }
          }
        }

        &:hover {
          td.actions {
            opacity: 1;
          }
        }
      }
    }
  }

  .react-bootstrap-table-pagination {
    @include flex;
    align-items: center;
    margin-top: 1.5em;
    margin-bottom: 1.5em;

    > ul.pagination {
      margin: 0 1em 0 0;
      flex: 1 0 auto;
    }

    .results-summary {
      margin-right: 1em;
    }
  }
}

.results-summary {
  color: $color-grey-550;
}

@media print {
  .view-products .react-bootstrap-table tbody tr td.actions {
    a,
    a:hover {
      background: $color-white;
      color: $color-black;
    }
  }
}
@include ie11-hack {
  .view-products {
    &.loading {
      .loading-ovly {
        justify-content: flex-start;
      }
    }
  }
}
