@import 'scss/vars.scss';

.pass-thru-details-container {
  display: flex;
  width: 100%;
  justify-content: space-between;
  border: 0px;
  h2 {
    font-size: 1.5em;
  }
  h3 {
    font-size: 1em;
    font-style: italic;
  }
  .data-job-uploadfile-container {
    width: 100%;
  }

  .data-job-create-section {
    @include flex;
    flex-direction: column;
    align-items: stretch;
    flex: 1 0 40%;

    &.left {
      margin-right: 1em;
    }
  }

  .pass-thru-file-uploadfile-container {
    width: 100%;
    border-color: $color-red-dg;
    border-style: solid;
    border-width: 0.1rem;
    border-radius: 0.5rem;
    box-shadow: 0 0 2px $color-red-dg;
    &-invalid {
      border-color: $color-red-dg;
    }
    &:hover {
      border-color: $color-grey-400;
      box-shadow: 0 0 1px $color-grey-400;
    }
    &:focus-within {
      border-color: $color-red-dg;
      border-width: 0.1rem;
      border-radius: 0.5rem;
      box-shadow: 0 0 3px $color-red-dg;
    }
  }

  .pass-thru-file-invalid-feedback {
    display: block;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #dc3545;
  }

  .data-job-file-list-container {
    margin-top: 1.5em;
  }

  .tips-carousel {
    margin-top: 1em;
  }

  .data-job-create-details-footer {
    margin-right: -1.5em;
    margin-bottom: -2em;
    margin-left: -1.5em;
  }
}

.data-job-file-bots-container {
  align-items: initial;
  justify-content: initial;
  margin-top: 1.5em;
  overflow: auto;

  .data-job-file-bots-list {
    display: flex;
    flex-wrap: wrap;

    .bot-file-chip {
      margin: 4px 0;
      margin-right: 8px;
    }
  }

  .data-job-file-bots-info {
    margin-bottom: 5px;
    color: $color-text;
    font-size: 15px;
  }

  .data-job-file-bots-expand {
    @include button-reset;
    margin-top: 8px;
    color: $color-text;
    font-weight: $typography-font-weight-bold;

    svg {
      margin-left: 5px;
    }
  }

  b {
    font-weight: $typography-font-weight-bolder;
  }
}
