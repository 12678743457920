@import 'scss/vars.scss';

.bai-due-date-picker-value-input__control {
  box-sizing: border-box;
  width: 100%;
  padding: 3px 10px;
  color: $color-grey-900;
  font-size: 15px;
  background-color: $color-white;
  border: 1px solid lightgrey;
  border-radius: 4px;
  min-height: 38px;
  &:focus-within {
    border-color: $color-orange-dg;
    border-width: 1px;
    border-radius: 4px;
    box-shadow: 0 0 3px $color-orange-dg;
  }
  &--invalid {
    @extend .bai-due-date-picker-value-input__control;
    border-color: $color-red-dg;
    border-style: solid;
    border-width: 0.1rem;
    border-radius: 0.5rem;
    box-shadow: 0 0 1px $color-red-dg;
    &:hover {
      border-color: $color-grey-400;
      box-shadow: 0 0 1px $color-grey-400;
    }
    &:focus-within {
      border-color: $color-red-dg;
      border-width: 0.1rem;
      border-radius: 0.5rem;
      box-shadow: 0 0 3px $color-red-dg;
    }
  }
}
.bai-due-date-picker-value-input__date-highlight {
  color: $color-black;
}
.bai-due-date-picker-value-input__date-strikethrough{
  text-decoration: line-through;
  text-decoration-thickness: 2px;
}
