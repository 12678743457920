@import 'scss/vars.scss';

.ingestion-header {
  font-family: Inter;

  h1 {
    margin-top: 18px;
    font-size: 28px;
    color: $color-text;
    height: 40px;
  }

  .ingestion-job-link-container {
    display: flex;
    align-items: center;

    .onboading-job-link {
      font-size: 18px;
      color: $color-blue-dg;
      height: 30px;
    }

    .ingestion-link-icon {
      color: $color-blue-dg;
      margin: 3px 0 5px 8px;
    }
  }

  .ingestion-items {
    display: flex;

    .ingestion-border-box {
      box-sizing: border-box;
      margin: 20px 0 20px 0;
      align-items: center;
      height: 40px;
      min-width: 361px;
      border: 1px solid $color-grey-dg;
      border-radius: 8px;
      background-color: $color-white;
      box-shadow: 0 1px 3px 0 rgba(223, 225, 228, 0.5);
      display: inline-flex;
      font-size: 15px;
      font-weight: $typography-font-weight-bolder;
      color: $color-text;
      padding: 5px 10px 5px 10px;

      .file-icon {
        margin: 0 15px 0 25px;
        font-weight: $typography-font-weight-normal;
      }

      .attachment-name {
        font-weight: $typography-font-weight-lighter;
      }
    }

    .ingestion-errors {
      margin-left: auto;
      display: flex;
      flex-direction: column;
      background-color: $color-red-dg-light;
      padding: 0.5em 1em 0.25em 1em;
      border-radius: 0.5em;
      min-width: 475px;

      &.warnings {
        background-color: $color-yellow-dg-light;
        max-width: 300px;
      }

      .title {
        color: $color-red-dg;
        font-size: 1.2em;
        font-weight: 700;
      }

      .error-toggle {
        align-self: center;
        margin: auto;
        text-decoration: underline;
      }
    }
  }
}
