@import 'scss/vars.scss';

.assign-brand-names {
    &-header {
        color: $color-text;
        font-weight: $typography-font-weight-bold;
        font-size: 13px;
        letter-spacing: 0.05em;
        text-transform: uppercase;
    }


    &-table {
        display: flex;
        flex-direction: column;
        margin-top: 20px;

        &-header {
            padding: 10px 15px;
            color: $color-text;
            font-weight: $typography-font-weight-bolder;
            font-size: 13px;
            background-color: $color-white;
            border: 1px solid $color-grey-dg;
            border-bottom: 0;
            border-top-left-radius: 8px;
            border-top-right-radius: 8px;
        }

        &-main-header {
            display: flex;
            flex-direction: row;
            align-items: flex-end;
            justify-content: space-between;
            color: $color-text;

            label {
                margin: 0;
            }

            &-tab {
                @include button-reset;
                width: 180px;
                margin-right: 10px;
                padding: 10px 15px;
                font-size: 15px;
                text-align: left;
                background-color: $color-white;
                border: 1px solid $color-grey-dg;
                border-bottom: 0;
                border-radius: 6px 6px 0 0;
                box-shadow: 0px 1px 3px rgba(223, 225, 228, 0.5);

                &:focus,
                &:active {
                    outline: none;
                }

                &--active {
                    color: $color-white;
                    background-color: $color-green-dg;
                    box-shadow: 0px 1px 3px rgba(223, 225, 228, 0.5);
                }
            }
        }

        .react-bootstrap-table {
            border-top-left-radius: 8px;
            border-top-right-radius: 8px;

            .form-control,
            .assign-brand-names-table-editor {
                width: 80%;
                height: 32px;
                color: #111827;
                font-weight: $typography-font-weight-bold;
                line-height: 1.4;
                border: 0.5px solid #64748b;
                border-radius: 6px;
                box-shadow: 0px 2px 6px rgba(0, 19, 92, 0.06);

                span {
                    opacity: 50%;
                }
            }

            .current_brand_name,
            .updated_brand_name {
                color: #111827;
                font-weight: $typography-font-weight-bold;
            }

            .number_of_skus {
                color: #6b7280;
            }

            .actions button {
                @include button-reset;
                color: $color-blue-dg;
            }

            &-pagination {
                margin-top: 20px;
                margin-right: 6px;
                margin-left: 6px;
                padding-top: 15px;
                border-top: 1px solid #e5e7eb;

                .pagination-total {
                    color: #374151;
                    span {
                        font-weight: $typography-font-weight-bold;
                    }
                }
            }

            thead {
                border-bottom: 1px solid #e5e7eb;
                th {
                    padding: 1em 0.25em;
                }
                tr {
                    font-size: 12px;
                    letter-spacing: 0.05em;
                    text-transform: uppercase;
                    background-color: #f9fafb;
                }
            }

            thead tr th .th-wrap .label {
                color: #6b7280;
                font-weight: $typography-font-weight-bold;
            }

            thead tr th:first-child,
            tbody tr td:first-child,
            tfoot tr th:first-child {
                padding-left: 1.5em;
            }

            td {
                padding: 1em 0.25em;
            }
          }
        }

        thead {
          border-bottom: 1px solid #e5e7eb;
          th {
            padding: 1em 0.25em;
          }
          tr {
            font-size: 12px;
            letter-spacing: 0.05em;
            text-transform: uppercase;
            background-color: #f9fafb;
          }
        }

        thead tr th .th-wrap .label {
          color: #6b7280;
          font-weight: $typography-font-weight-bold;
        }

        thead tr th:first-child,
        tbody tr td:first-child,
        tfoot tr th:first-child {
          padding-left: 1.5em;
        }

        td {
          padding: 1em 0.25em;
        }
}
