@import 'scss/vars.scss';

.file-upload-template-wrapper {
  .template-file-modal-panel {
    .modal-body {
      .share_url {
        @include flex;
        align-items: center;

        > input {
          flex: 1 1 auto;
        }

        > button {
          flex: 0 0 auto;
          margin-left: 0.5em;
          white-space: nowrap;
        }
      }
    }
  }

  .template-upload-attachment__btn {
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    width: 180px;
    min-height: 45px;
    margin-right: 1rem;
    margin-bottom: 0.5rem;
    margin-left: 1rem;
    padding: 10px 15px;
    color: $color-text;
    font-weight: $typography-font-weight-normal;
    font-size: 15px;
    background-color: $color-white;
    border: 1px solid $color-grey-dg;
    border-radius: 6px;
    box-shadow: 0 1px 3px 0 rgba(223, 225, 228, 0.5);
    cursor: pointer;
    &:hover {
      background-color: $color-grey-50;
    }
  }
}
