@import 'scss/vars.scss';

.data-job-create-upload-list {
  .headertext {
    font-size: $typography-font-size-normal;
    font-weight: $typography-font-weight-light;
    color: $color-grey-550;
    margin-bottom: 1em;
  }

  .itemlink {
    max-width: 25rem;
    text-overflow: ellipsis;
    overflow: hidden;
    font-size: $typography-font-size-normal;
    font-weight: $typography-font-weight-bolder;
    color: $color-blue-main;
    &:hover,
    &:focus {
      word-wrap: break-word;
    }
  }

  .list-group {
    border-top: 1px solid rgba($color-grey-550, 0.2);
    border-bottom: 1px solid rgba($color-grey-550, 0.2);
    .list-group-item {
      padding-left: 0;
      padding-right: 0;
      @include flex;
      align-items: center;

      .itemtext {
        max-width: 25rem;
        font-size: $typography-font-size-normal;
        font-weight: $typography-font-weight-bolder;
        color: $color-black;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }

      .itemdetail {
        margin-left: 2em;
        margin-right: .2em;
        color: $color-grey-550;
        font-style: italic;
      }

      .itemselect {
        width: 220px;
        margin-left: auto;
        margin-right: .1em;
      }

      .item-deletebutton {
        margin-left: 2em;
        border: 1px solid rgba($color-grey-550, 0.3);
        box-shadow: 0 1px 3px 0 rgba(223, 225, 228, 0.5);
        color: $color-grey-900;
        &:hover {
          color: $color-white;
        }
      }
    }
  }
}
