@import "scss/vars.scss";

.user-comment {
  .comment-body {
    width: 100%;
    margin-bottom: 1rem;
    white-space: pre-line;
    word-wrap: break-word;

    & > * {
      word-wrap: break-word;
    }

    &.plain-text {
      line-height: 1.25;

      a {
        line-height: 1;
        font-weight: $typography-font-weight-normal;
      }
    }
  }

  .comment-meta {
    @include flex;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 0.5em;
    font-size: 0.85em;

    .comment-author {
      @include flex;
      align-items: center;
      white-space: nowrap;
      flex-direction: row;

      .user-profile-circle {
        align-self: center;

        .name-placeholder {
          font-size: 0.85em;
        }
      }
      .author-name {
        align-self: center;
      }
    }

    .comment-created-at {
      white-space: nowrap;
      &::before {
        content: "-";
        display: inline-block;
        margin: 0 0.25em;
      }
    }
  }
}
