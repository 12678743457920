@import 'scss/vars.scss';

.pricing-update {
  .title-block {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .title {
    margin-top: 2em;
  }

  .title,
  .subtitle {
    color: $color-text;
  }

  .bot-main {
    display: flex;
    flex-direction: column;
    background-color: $color-white;
    border: 1px solid #e0e0e0;
    border-radius: 0.5em;
    box-shadow: 0 1px 3px 0 rgba(223, 225, 228, 0.5);
    margin-top: 2em;
    padding: 1em 1.5em 2em 1.5em;

    .bot-form {
      display: flex;
      justify-content: space-between;
      align-content: stretch;
      align-items: flex-start;

      .bot-upload {
        display: flex;
        flex-direction: column;
        flex: 1 1 45%;
        margin-right: 2em;
      }

      .bot-select {
        display: flex;
        flex: 1 1 45%;
      }
    }

    .bot-invalid {
      margin-top: 0.25em;
      color: $color-red-dg;
      display: flex;
      flex-wrap: nowrap;
    }

    .bot-actions {
      display: flex;
      justify-content: flex-end;
      margin-top: 1em;

      button {
        margin-left: 0.5em;
      }
    }
  }
}
