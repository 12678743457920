@import 'scss/vars.scss';

.ids-short-description {
  @include block;
  @include flex;

  align-items: center;
  justify-content: space-between;
  padding: 1.5rem 1rem;

  .ids-short-description-content {
    max-width: 80%;
    p {
      margin-bottom: 0.5rem;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
