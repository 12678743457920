.view-data-job-create {
  .title-block {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .title {
      font-size: 2.6rem;
    }

    .pass-btn {
      color: #00135c;
      background-color: $color-white;
      border: 1px solid $color-grey-dg;
      box-shadow: 0 1px 3px 0 rgba(223, 225, 228, 0.5);
      &:hover {
        background-color: $color-grey-50;
      }
    }
  }
}

.passThruModal-container {
  width: 900px !important;
}
