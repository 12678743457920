@import "scss/vars.scss";

.weekly-metrics-details {
  margin: 15px 0;

  .lazyload-wrapper {
    margin: 30px 0;

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.weekly-metrics-row-loading-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
}

.weekly-metrics-row-loading {
  flex: 0 0 5em;
  margin: auto;
}
