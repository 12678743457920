@import "scss/vars.scss";

.bai-date-picker-container {
    position: relative;

    .fa-calendar-alt {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        margin-top: auto;
        margin-right: 10px;
        margin-bottom: auto;
        color: #b4bdcc;
    }
}
