@import 'scss/vars.scss';

.cds-job-modal-panel {
  .modal-dialog {
    min-width: 550px;
    max-width: 600px;
  }

  .modal-footer {
    background-color: #fff;

    .btn-disabled {
      opacity: 0.5;
      cursor: default;
    }
  }

  .btn-cancel {
    color: $color-blue-dg;
    text-decoration: underline;
    text-underline-offset: 3px;
    font-weight: $typography-font-weight-bold;
  }
}

.modal-panel {
  .modal-panel-body-text {
    overflow-wrap: break-word;
  }

  .modal-button {
    display: flex;
    padding: 5px 10px;
    justify-content: center;
    align-items: center;
    gap: 4px;
  }
}

.cds-description {
  flex: 1 0 0;
  margin: 0 16px;
  color: $color-grey-900;
  font-size: 14px;
  font-style: normal;
  font-weight: $typography-font-weight-bold;
  line-height: normal;

  .cds-description-strong {
    font-weight: 700;
  }
}

.separator {
  margin: 8px;
  width: 100%;
}

.info-selected {
  border-left: 3px solid $color-green-dg;
}

.info-default {
  border-left: 3px solid $color-blue-dg;
}
