@import 'scss/vars.scss';

.manufacturer-select {
  .manufacturer,
  .child-company {
    .title {
      color: $color-blue-dg;
      font-weight: $typography-font-weight-bolder;
      font-size: 1.2em;
    }

    .value {
      margin-top: 0.25em;
      font-weight: $typography-font-weight-bolder;
      font-size: 2.5em;
      line-height: 1.2;
    }
  }
}
