$color-white: #ffffff;
$color-black: #000000;

$color-grey-1: #222;
$color-grey-2: #444;
$color-grey-3: #666;
$color-grey-4: #888;
$color-grey-5: #d9d9d9;

$color-grey-25: #fdfcfc;
$color-grey-50: #f6f7fa; // Design guide
$color-grey-100: #eceff1;
$color-grey-200: #eeeeee;
$color-grey-300: #e0e0e0;
$color-grey-400: #c8c9ca;
$color-grey-500: #9e9e9e;
$color-grey-550: #757d8d; // Design guide
$color-grey-700: #616161;
$color-grey-900: #1e2c47; // Design guide
$color-orange-main: #fa7c28;
$color-orange-warnings: #ff5722;
$color-red-errors-negative: #f44336;
$color-blue-light: #304fff;
$color-blue-main: #0903a5;
$color-blue-dark: #07027f;
$color-blue-link: #0275d8;
$color-signal-word-danger: #f44336;
$color-signal-word-warning: #fd7b03;

$color-green-1: #6b8e23;
$color-green-2: #16ad83;
$color-red-1: #dc143c;
$color-red-2: #fa4e4e;
$color-alt-1: #faffcf;
$color-alt-2: #e6f6ff;
$color-text: #00135c;
$color-logo-blue: #00135c;

// --- Design guide --- //

$color-green-dg: #20c094;
$color-green-dg-light: #daf9e8;
$color-yellow-dg: #ffb658;
$color-yellow-dg-light: #ffe7aa;
$color-orange-dg: #fa7d27;
$color-orange-dg-light: #ffceac;
$color-red-dg: #ed594b;
$color-red-dg-light: #faeae9;
$color-blue-dg: #3278eb;
$color-blue-dg-light: #d8e7ff;
$color-grey-dg: #dfe1e4;
$color-grey-dg-light: #ebecf0;
$color-dg-input: #f9f9f9;
$color-dg-search: #f3f3f7;
