@import "scss/vars.scss";

.ids-config-action-btns {
  @include flex;
  align-items: center;
  justify-content: flex-end;

  > :not(:first-child) {
    margin-left: 0.3em;
  }

  .header-selector {
    min-width: 8em;
    margin-right: 0.5em;
  }

  .btn {
    min-width: 3em;

    &.btn-default,
    &.btn-details {
      background-color: $color-blue-dg;
      border-color: $color-blue-dg;
      &:hover,
      &:active,
      &:focus {
        border-color: scale-color($color: $color-blue-dg, $lightness: -20%);
        background: linear-gradient(180deg, $color-blue-dg 0%, $color-blue-dg-light 500%);
        box-shadow: 0 1px 3px 0 $color-blue-dg;
      }
    }
    &.btn-delete {
      background-color: $color-red-dg;
      border-color: $color-red-dg;
      &:hover,
      &:active,
      &:focus {
        border-color: scale-color($color: $color-red-dg, $lightness: -20%);
        background: linear-gradient(180deg, $color-red-dg 0%, $color-red-dg-light 500%);
        box-shadow: 0 1px 3px 0 $color-red-dg;
      }
    }
  }
}
