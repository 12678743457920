@import 'scss/vars.scss';

.distributor-job-links {
  width: 100%;
  vertical-align: middle;

  .job-links-list-title {
    color: $color-grey-900;
    font-size: $typography-font-size-root;
    padding-left: 2rem;
  }

  .job-links-list {
    @include unstyled-list;
    border: 1px solid $color-grey-200;
    border-radius: 0.5em;

    .job-link-item {
      display: flex;
      align-items: center;
      padding: 0 0.5em;

      &:not(:first-child) {
        border-top: 1px solid $color-grey-200;
      }

      .job-link {
        display: inline-flex;
        width: 100%;
        margin: auto 1em auto auto;
        padding: 0.75em 0;
        overflow: hidden;
        &--disable {
          @extend .job-link;
        }
        .job-link-badge-wrapper {
          margin-left: 1rem;
          flex: 3;
          .job-link-badge {
            vertical-align: middle;
            line-height: 1rem;
            margin-right: 1rem;
          }
          .job-link-badge-status {
            @extend .job-link-badge;
            background-color: $color-blue-dg;
            color: $color-white;
          }
          .badge-success {
            @extend .job-link-badge;
            background-color: $color-green-dg;
            color: $color-text;
          }
          .badge-warning {
            @extend .job-link-badge;
            background-color: $color-yellow-dg;
            color: $color-text;
          }
          .badge-danger {
            @extend .job-link-badge;
            background-color: $color-red-dg;
          }
        }
        .job-link-text {
          margin: auto;
          line-height: 1.3rem;
          flex: 6;
          color: $color-blue-dg;
          max-width: 35rem;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          &:hover {
            white-space: normal;
            text-overflow: unset;
            word-wrap: break-word;
            line-height: auto;
          }
        }
        &--disable .job-link-text {
          @extend .job-link-text;
          color: $color-black;
        }
      }
    }
  }
}
