@import 'scss/vars.scss';

.before-after-img-table-wrap {
  margin-top: 2rem;
  .title {
    h2 {
      font-size: 1.5rem;
    }
  }
  .empty-notice {
    margin-top: 1rem;
  }
  .before-after-img-table {
    min-height: 10em;

    .react-bootstrap-table {
      /* prettier-ignore */
      thead {
        tr {
          th {
            .th-wrap {
              justify-content: center;
              .label {
                color: $color-grey-550;
              }
            }
          }
          th.original_name { width: 25%;
            .th-wrap {
              justify-content: flex-start;
            }
          }
        }
      }

      tbody {
        tr {
          td {
            text-align: center;
            &.original_name {
              text-align: left;
              .file-block {
                .file-block-name {
                  @include flex;
                  .fname {
                    flex: 0 1 auto;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: inline-block;
                    vertical-align: middle;
                    white-space: nowrap;
                  }
                  .fext {
                    vertical-align: middle;
                  }
                }

                .file-block-img-count {
                  font-size: 0.85em;
                  margin-top: 0.25em;
                  .label {
                    color: $color-grey-500;
                  }
                  .value {
                    margin-left: 0.5em;
                    font-weight: $typography-font-weight-bolder;
                    color: $color-grey-500;
                  }
                }
              }
            }
            &.user {
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }

            &.status {
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;

              .status-block {
                &-phase {
                  font-weight: $typography-font-weight-bolder;
                }

                &-status {
                  margin-top: 0.25em;
                  .icon {
                    margin-right: 0.25em;
                    .svg-inline--fa {
                      color: $color-grey-500;
                    }

                    &.failed .svg-inline--fa {
                      color: $color-red-dg;
                    }

                    &.completed .svg-inline--fa {
                      color: $color-green-dg;
                    }
                  }
                }
              }
            }
            &.market_ready {
              .market-ready-block {
                @include flex;
                align-items: center;

                .percentage-current {
                  padding-right: 0.5em;
                  min-width: 5em;
                  border-right: 1px solid $color-grey-500;
                  .value {
                    font-size: 2.25em;
                    font-weight: $typography-font-weight-bolder;
                    color: $color-grey-500;
                  }
                  .unit {
                    font-size: 1.25em;
                    font-weight: $typography-font-weight-bolder;
                    color: $color-grey-500;
                  }
                }

                .counts-block {
                  margin-left: 0.5em;
                  .label {
                    color: $color-grey-500;
                    display: inline-block;
                    min-width: 3.5em;
                  }
                  .value {
                    color: $color-grey-500;
                    font-weight: $typography-font-weight-bolder;
                  }
                }
              }
            }
          }
        }
      }
    }

    .loading-spinner-wrap {
      display: none;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }

    &.is-loading {
      .react-bootstrap-table {
        opacity: 0.35;
      }

      .loading-spinner-wrap {
        display: block;
        .load-logo-spinner-wrapper {
          margin-top: 2em;
        }
      }
    }
  }
}

.img-wrap {
  max-width: 1280px;
}
