body[data-page="view-statistics"] {
  background: $color-grey-50;
}

.wrap-statistics-global {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  .view-statistics-global {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: stretch;
    margin: 0 0 0 -1em;
    width: calc(100% + 1em);

    .block-item {
      flex: 0 0 14em;
      background: $color-white;
      margin: 0 0 0 1em;
      border-radius: 0.5em;
      overflow: hidden;

      .color-strip {
        height: 0.8em;
        background: $color-blue-main;
      }

      .value-container {
        background: $color-grey-300;
        position: relative;
        padding: 0.5em 1.5em 1em 1.5em;
        color: $color-black;

        .value {
          font-size: 2em;
          font-weight: $typography-font-weight-bold;
        }

        .icon {
          color: $color-grey-500;
          display: block;
          position: absolute;
          font-size: 1.25em;
          top: 0.25em;
          right: 0.75em;
        }
      }

      .description-container {
        padding: 0.75em 1.5em;
        .description {
          font-size: 0.75em;
        }
      }
    }
  }
}

.view-statistics {
  margin-top: 2em;

  .product_count,
  .sds_count,
  .last_updated,
  .last_crawled {
    text-align: right;
    .th-wrap {
      justify-content: flex-end;
    }
  }

  .react-bootstrap-table {
    margin-top: 1.5em;

    thead {
      /* prettier-ignore */
      tr {
        th.name { width: 18em; }
        th.un_code { width: 6em; }
        th.status { width: 7em; }
        th.updated_at { width: 7.5em; }
        th.actions { width: 3em; }
      }
    }

    tbody {
      tr {
        // td.name .product-block { display: inline-flex; min-width: 18em; max-width: 25em; }
      }
    }
  }

  .supplier-name-block {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-start;

    .supplier-logo {
      display: inline-flex;
      vertical-align: middle;
      width: 6em;
      height: 3em;
      margin-right: 0.5em;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;
      background: $color-white;
      border: 1px solid $color-grey-300;
      padding: 0.25em;
      border-radius: 0.25em;

      > img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }
}
