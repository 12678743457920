@import "scss/vars.scss";

.search {
    position: relative;
    display: inline-flex;
    width: 100%;
    margin: 15px 0;

    &-input {
        width: 100%;
        padding: 10px 15px;
        color: $color-text;
        font-size: 13px;
        background: $color-white;
        border: 1px solid $color-grey-dg;
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;

        &::placeholder {
            color: $color-grey-550;
        }

        &--full {
            border-radius: 8px;
        }

        &-container {
            position: relative;
            display: flex;
            width: 100%;
            color: $color-grey-400;
        }
    }

    &-clear-btn {
        @include button-reset;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        margin-right: 15px;
    }

    &-btn {
        padding: 0 10px;
        color: $color-white;
        background-color: $color-green-dg;
        border: 1px solid $color-green-2;
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;

        &:hover {
            background-color: $color-green-2;
        }
    }
}
