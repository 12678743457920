.view-product-details,
.view-product-details__sm {
  .product-info-row {
    display: flex;
    flex-flow: row nowrap;
    align-items: stretch;
    justify-content: flex-start;
    margin-bottom: 1.6em;

    p {
      margin: 0;
    }

    ul {
      margin: 0;
      padding: 0;
    }
  }
}

@media (max-width: 1050px) {
  .view-product-details {
    .product-info-row {
      flex-flow: row wrap;
    }
  }
}

.view-product-details__sm {
  margin-bottom: 340px;
}
