@import "scss/vars.scss";

// TODO: after/before tree line node connecting support for several items
.taxonomy {
    border: 1px solid $color-grey-dg;
    border-radius: 8px;
    padding: 25px 20px;
    background-color: $color-dg-input;
}

.taxonomy-item {
    @include user-select-none;
    display: inline-flex;
    cursor: pointer;
    align-items: center;
    position: relative;
    background-color: $color-white;
    border: 1px solid $color-grey-dg;
    border-radius: 8px;
    padding: 5px 10px;
    box-shadow: 0 1px 3px 0 rgba(223, 225, 228, 0.5);

    .taxonomy-item-icon {
        margin-right: 8px;

        &--active {
            color: $color-orange-dg;
        }
    }

    .taxonomy-item-label {
        color: $color-text;
        font-size: 15px;
        font-weight: $typography-font-weight-bolder;
        line-height: 30px;
    }

    &::after {
        position: absolute;
        content: "";
        height: 1px;
        width: 16px;
        background-color: $color-grey-dg;
        top: 50%;
        left: -16px;
    }

    &--active::after {
        background-color: $color-orange-dg;
    }

    &-parent::after {
        content: none;
    }
}

.taxonomy-item-child {
    margin-left: 34px;
    position: relative;
    margin-top: 10px;

    &::before {
        position: absolute;
        content: "";
        background-color: $color-orange-dg;
        height: 31px;
        width: 1px;
        top: -10px;
        left: -15px;
    }
}
