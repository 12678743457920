@import "scss/vars.scss";

$_slug: "pricing-validation";
$_block_padding: 1.5em;

.pricing-validation-bot-wrap {
  .databot-config-panel.databot-config-panel-#{$_slug} {
    .databot-config-panel-body {
      padding: 0;
      position: relative;

      .pricing-data-overview {
        .heading {
          @include flex;
          justify-items: flex-start;
          align-items: center;
          padding: $_block_padding $_block_padding 0 $_block_padding;

          .title {
            margin-right: 1rem;
            font-size: 1.5em;
            font-weight: $typography-font-weight-bolder;
          }

          .last-run {
            color: $color-grey-550;
            white-space: nowrap;
          }
        }

        .overview {
          @include flex;
          justify-content: space-between;
          margin-top: 3.5rem;
          padding-right: $_block_padding;

          .overview-item {
            &.fill-rate {
              border-bottom-left-radius: 0.5em;
              overflow: hidden;
              flex: 0 0 27em;
              margin-right: 3rem;
              .bai-circular-progressbar.semi-circle .bai-circular-progressbar-label {
                margin-top: -11em;
              }
              .bai-circular-progressbar .bai-circular-progressbar-label .value {
                font-size: 4.5em;
              }
            } 
          }
        }

        .missing-msrp, .missing-ed, .missing-pricing, .missing-ped {
          width: 100%;
          height: 150px;
          margin: 0 0.25rem;
        }

        .missing-pricing .request-update-card {
          border-radius: 0.5rem;
        }

        .bai-value-block {
          height: 100%;
        }
      }

      .databot-config-panel-actions {
        margin-top: 0;
        position: absolute;
        right: $_block_padding;
        top: $_block_padding;
      }
    }
  }

  .pricing-data-info-section {
    @include block;
    padding: $_block_padding;
    margin-top: $_block_padding;

    .heading {
      @include flex;

      .title {
        flex: 1 1 auto;
        font-size: 1.5em;
        color: $color-text;
        font-weight: $typography-font-weight-bolder;
        margin-bottom: 0.5em;
      }

      .actions button {
        display: inline-flex;
        align-items: center;
        padding: 0.35rem 0.75rem;

        .icon {
          margin-left: 0.5em;
          font-size: 0.675rem;
        }
      }


    }

    p {
      color: $color-text;
    }

    .pricing-data-tables {
      &:not(.expanded) {
        display: none;
      }

      .pricing-data-table {
        &:not(:first-child) {
          margin-top: 2em;
        }

        .title {
          color: $color-blue-dg;
          font-weight: $typography-font-weight-bolder;
          margin-bottom: 0.5em;
        }

        .react-bootstrap-table {
          thead {
            background: $color-grey-50;
            tr th {
              padding-top: 0.75em;
              padding-bottom: 0.75em;
              .th-wrap .label {
                font-weight: $typography-font-weight-bolder;
                color: $color-grey-550;
              }
            }
          }

          tbody {
            tr:nth-child(2n) {
              background: $color-grey-50;
            }
            tr td {
              padding-top: 1.25em;
              padding-bottom: 1.25em;
              &.attr {
                font-weight: $typography-font-weight-bolder;
              }
            }
          }
        }
      }
    }
  }

  .pricing-validation-progress-bar {
    .cancel {
      margin-top: 1em;
      color: $color-text;
    }
  }
}
