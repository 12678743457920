@import 'scss/vars.scss';

.reclassify-attributes {
  .title-block {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .title {
      font-size: 2.6rem;
    }
  }

  .reclassify-attributes-main {
    display: flex;
    flex-direction: column;
    background-color: $color-white;
    border: 1px solid #e0e0e0;
    border-radius: 0.5em;
    box-shadow: 0 1px 3px 0 rgba(223, 225, 228, 0.5);
    margin-top: 1em;
    padding: 1em 1.5em 2em 1.5em;

    .title {
      color: $color-blue-main;
      font-size: 1.25em;
    }

    .reclassify-select {
      display: flex;
      justify-content: space-between;
      align-content: stretch;
      align-items: stretch;

      .separator {
        font-size: 2rem;
        padding: 1rem;
        display: flex;
        align-content: flex-start;
        justify-content: center;
        align-self: flex-start;
        flex-grow: 1;
      }
    }

    .reclassify-invalid {
      margin-top: 0.25em;
      color: $color-red-dg;
      display: flex;
      flex-wrap: nowrap;
    }

    .reclassify-errors {
      width: 54.5%;
      background-color: $color-red-dg-light;
      display: flex;
      padding: 0.5em 0.25em;
      font-size: 1.2em;
      display: flex;
      flex-wrap: nowrap;
      border: solid 2px #e8e9eb;
      border-radius: 0.5em;
    }

    .reclassify-actions {
      display: flex;
      justify-content: flex-end;
      margin-top: 1em;

      button {
        margin-left: 0.5em;
      }
    }
  }
}
