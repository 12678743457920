@import 'scss/vars.scss';

thead {
  tr {
    th {
      .label {
        color: $color-grey-550;
      }
    }
    th.id {
      width: 5em;
    }
    th.started {
      width: 13em;
    }
    th.type {
      width: 13em;
    }
    th.status {
      width: 10em;
    }
  }
}

.template-upload-attachment {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 20rem;
  padding: 2rem;
  background: $color-grey-50;
  border: 2px dashed $color-grey-400;
  border-radius: 8px;
  cursor: pointer;
  &:hover {
    border-color: $color-grey-500;
  }
}

.imports-table {
  margin: 2rem 1rem;
  .react-bootstrap-table tr {
    td {
      $row-padding: 0.75rem;
      padding-top: $row-padding;
      padding-bottom: $row-padding;
    }
  }
}

.main-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.left-content {
  display: flex;
  flex: 1;
}

.filter-select {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  width: 50%;
  flex: 1;
}

.center-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.button-container {
  margin-top: auto;
}

.right-content {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  .file-upload-template-wrapper {
    max-width: 30em;
  }
}

@media (max-width: 768px) {
  .main-container {
    flex-direction: column;
  }
}

@media (max-width: 768px) {
  .center-content {
    margin-top: 2rem;
  }
}

@media (max-width: 768px) {
  .left-content {
    margin-top: 2rem;
  }
}

@media (max-width: 768px) {
  .right-content {
    margin-top: 2rem;
  }
}

.file-block-name {
  @include flex;

  .fname {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    vertical-align: middle;
  }
  .fext {
    white-space: nowrap;
    vertical-align: middle;
  }
}
