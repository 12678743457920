@import 'scss/vars.scss';

.img-bot-engine-log-table {
  position: relative;
  min-height: 10em;

  .react-bootstrap-table {
    /* prettier-ignore */
    thead {
      tr {
        th {
          .label {
            color: $color-grey-550;
          }
        }
        th.job_id { width: 4em; }
        th.file { width: 13em; }
        th.updated_at { width: 7em; }
        th.user { width: 14em;}
        th.status { width: 13em; }
        th.images { width: 6em; }
        th.market_ready { width: 15em; }
        th.ids-actions { width: 22em; .th-wrap { justify-content: flex-end; } }
      }
    }

    tbody {
      tr {
        td {
          &.file {
            .file-block {
              .file-block-name {
                @include flex;
                .fname {
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  display: inline-block;
                  vertical-align: middle;
                }
                .fext {
                  white-space: nowrap;
                  vertical-align: middle;
                }
              }

              .file-block-img-count {
                font-size: 0.85em;
                margin-top: 0.25em;
                .label {
                  color: $color-grey-500;
                }
                .value {
                  margin-left: 0.5em;
                  font-weight: $typography-font-weight-bolder;
                  color: $color-grey-500;
                }
              }
            }
          }
          &.user {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          &.status {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

            .status-block {
              white-space: normal;
              &-phase {
                font-weight: $typography-font-weight-bolder;
              }

              &-status {
                margin-top: 0.25em;
                display: flex;
                align-items: center;

                .icon {
                  margin-right: 0.25em;
                  .svg-inline--fa {
                    color: $color-grey-500;
                  }

                  &.failed .svg-inline--fa {
                    color: $color-red-dg;
                  }

                  &.rejected .svg-inline--fa {
                    color: $color-red-dg;
                  }

                  &.completed .svg-inline--fa {
                    color: $color-green-dg;
                  }

                  &.pending .svg-inline--fa {
                    color: $color-orange-dg;
                  }

                  &.running .svg-inline--fa {
                    color: $color-blue-dg;
                  }

                  &.under_review .svg-inline--fa {
                    color: $color-blue-dg;
                  }
                }

                &-info {
                  display: flex;
                  flex-direction: column;

                  font-weight: 600;
                  line-height: 1.2rem;
                  color: $color-grey-500;

                  &-progress {
                    margin-left: 0.25rem;
                  }
                }

                .tooltip-icon {
                  margin-left: 0.25em;
                  color: $color-grey-500;
                }
              }
            }
          }
          &.market_ready {
            .market-ready-block {
              @include flex;
              align-items: center;
              .percentage-current {
                padding-right: 0.5em;
                min-width: 5em;
                border-right: 1px solid $color-grey-500;
                .value {
                  font-size: 2.25em;
                  font-weight: $typography-font-weight-bolder;
                  color: $color-grey-500;
                }
                .unit {
                  font-size: 1.25em;
                  font-weight: $typography-font-weight-bolder;
                  color: $color-grey-500;
                }
              }

              .counts-block {
                margin-left: 0.5em;
                .label {
                  color: $color-grey-500;
                  display: inline-block;
                  min-width: 3.5em;
                }
                .value {
                  color: $color-grey-500;
                  font-weight: $typography-font-weight-bolder;
                }
              }
            }
          }
          &.ids-actions {
            .btn {
              .label {
                display: none;
              }
            }
          }
        }
      }
    }
  }

  .loading-spinner-wrap {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  &.is-loading {
    .react-bootstrap-table {
      opacity: 0.35;
    }

    .loading-spinner-wrap {
      display: block;
      .load-logo-spinner-wrapper {
        margin-top: 2em;
      }
    }
  }
}

.btn-start-first-ids-job {
  margin-left: 0.5em;
  .label {
    display: none;
  }
}
