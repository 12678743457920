.flex-form {
  display: flex;
  padding: 0;
  border-radius: 3px;
  flex-direction: column;

  .flex-row {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }

  .flex-group {
    display: flex;
    flex: 1 1;
  }

  .nowrap {
    flex-wrap: nowrap;
  }

  label {
    padding: 0.5em 1em 0.5em 0;
    flex: 1 2;
  }

  input {
    flex: 2 1 !important;
    border-radius: 0.5rem !important;
  }
}

.flex-form-vertical-labels {
  display: flex;
  padding: 0;
  border-radius: 3px;
  flex-direction: column;
  width: 100%;

  .flex-row {
    flex: 1 1;
    display: flex;
    flex-direction: row;
  }

  .flex-column {
    flex: 1 1;
  }

  .flex-group {
    display: flex;
    flex: 1 1;
    flex-direction: column;
    margin: 4px;
  }

  .nowrap {
    flex-wrap: nowrap;
  }

  label {
    padding: 0.5em 1em 0.5em 0;
  }

  input,
  select {
    border-radius: 0.5rem !important;
  }
}
