@import 'scss/vars.scss';

button:disabled,
button[disabled] {
  background-color: $color-grey-500;
}

$_block_padding: 0.75rem;
$ids-modal-padding: 14px;

section {
  &.header-mapping {
    > .content {
      .header-mapping-table {
        width: 100%;
      }
    }
  }

  .content-guidelines {
    display: flex;
    gap: 5px;
    > section {
      flex-grow: 1;
      flex-shrink: 1;
      flex-basis: 0;
    }
  }

  &.header-selection {
    > .content {
      @include flex;
      flex-direction: column;
      justify-content: space-between;
    }

    .header-selector {
      width: 100%;
      vertical-align: middle;

      .styled-multiselect-container {
        width: 100%;
        margin-top: 0.5em;
      }
    }
  }
}
