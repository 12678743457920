@import 'scss/vars.scss';

.light {
  margin-top: 0.25em;
  color: $color-grey-500;
  font-weight: $typography-font-weight-bolder;
  font-size: 0.75em;
  line-height: 1.2;
}

.title-tooltip {
  margin-top: 0.25em;
  margin-left: 0.75em;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  flex: 0 0 1.5em;
  width: 1.5em;
  height: 1.5em;
  padding: 0.3em;
  border: 1px solid $color-grey-400;
  border-radius: 50%;
  box-shadow: 0 1px 3px 0 $color-grey-500;
  cursor: default;

  .icon {
    color: $color-grey-550;
    font-size: 0.85em;
  }
}
