@import 'scss/vars.scss';

.header-selection {
  > .note {
    @include flex;
    color: $color-grey-550;
  }

  > .content {
    @include flex;
    flex-direction: column;
    justify-content: space-between;

    .header-selector {
      min-width: 25%;
      flex: 0 0 25%;
      vertical-align: middle;

      .file-label {
        font-weight: $typography-font-weight-bold;
      }

      .styled-multiselect-container {
        margin-top: 0.5em;
      }
    }

    > .icon-divider {
      padding: 0 1.5rem;
      .icon {
        color: $color-grey-500;
        font-size: 2rem;
      }
    }
  }
}
