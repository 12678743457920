$font-family-sans-serif: $typography-font-family-normal;
$font-family-monospace: $typography-font-family-monospace;

$font-size-base:              $typography-font-size-normal;
$font-size-lg:                $font-size-base * 1.25;
$font-size-sm:                $font-size-base * .875;

$font-weight-lighter:         $typography-font-weight-lighter;
$font-weight-light:           $typography-font-weight-light;
$font-weight-normal:          $typography-font-weight-normal;
$font-weight-bold:            $typography-font-weight-bold;
$font-weight-bolder:          $typography-font-weight-bolder;

$font-weight-base:            $typography-font-weight-normal;
$line-height-base:            1.5;

@import '~bootstrap/scss/bootstrap';
