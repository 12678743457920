@import 'scss/vars.scss';

.data-lakehouse-dashboard.data-lakehouse-dashboard-supplier {

    .manufacturer-select {
        max-width: 50%;
        .child-company{        
            padding-right: 1em;
        }
    }
}
