body[data-page="view-flash-assessment"] {
  background: $color-grey-50;
}

.view-flash-assessment {
  .flash-assessment-grid {
    > .row > div > section {
      @include block;
      padding: 0.75em 1em;
      height: 100%;

      .title {
        font-size: 1.2em;
        font-weight: $typography-font-weight-bold;
        position: relative;
        top: -0.25em;
      }
    }
  }

  .section-general-attrs,
  .section-detailed-attrs {
    ul.attr-list {
      @include unstyled-list;
      margin-top: 0.5em;
      li .attr-item {
        @include flex;
        &:not(:first-child) {
          margin-top: 0.25em;
        }

        justify-content: space-between;
        align-items: center;
        height: 2.7em;

        .suggestion {
          flex: 0 0 1.5em;
          height: 1.5em;
          line-height: 1.1em;
          text-align: center;
          border-radius: 50%;
          border: 1px solid $color-grey-300;
          margin: 0 0.5em;

          .icon {
            font-size: 0.75em;
            opacity: 0.2;
          }

          &.clickable {
            border: 1px solid $color-grey-550;
            cursor: pointer;
            background: $color-white;
            animation: greyToOrangeNotifyPulse 3s 3;

            .icon {
              opacity: 0.75;
            }
            &:hover .icon {
              opacity: 1;
            }
          }
        }

        .label {
          flex: 0 0 9em;
          line-height: 1.2;
          margin-right: 1em;
          font-size: 1.2em;
        }
        .score-scale-min {
          flex: 1 0 auto;
          width: auto;
        }
      }
    }

    /* prettier-ignore */
    .score-scale-min {
      justify-content: flex-start;
      .score-scale-bar {
        flex: 1 0 auto;
        .scale-block {
          width: 20%;
          height: 0.5em;
        }
      }

      .score-scale-value {
        flex: 0 0 3.5em;
        margin-left: 0;
        text-align: right;
      }

      &.good {
        .scale-block.full, .scale-block-remainder { background: $color-green-dg; }
      }
      &.acceptable {
        .scale-block.full, .scale-block-remainder { background: $color-yellow-dg; }
      }
      &.poor {
        .scale-block.full, .scale-block-remainder { background: $color-red-2; }
      }
    }
  }

.not-found {
  text-align: center;
}

// TODO: ViewFlashAssessmentAttributes wrongly uses this stylesheet. Must not mix.
.error-list {
  @include unstyled-list;
  margin-bottom: 1em;
  li {
    margin-top: 0.25em;
    font-size: 0.85em;
    color: $color-red-2;
  }
}

@include ie11-hack {
  .view-flash-assessment
    .section-overview
    .aggregate-score
    .aggregate-score-value
    svg.CircularProgressbar
    + div {
    margin-top: 0 !important;
    top: 0 !important;
  }
  .view-flash-assessment
    .section-overview
    .aggregate-score
    .aggregate-score-value
    .circular-progressbar-label
    .status-label-wrap {
    flex-direction: column;
  }
}

.popover-attr-tooltip {
  max-height: 400px;
  max-width:800px;
}
}