.header-mapping-table {
  .header-mapping-row {
    display: flex;
    flex-direction: row;
    align-items: center;

    &:not(:last-child) {
      margin-bottom: 0.25em;
    }

    .header-mapping-row-cell {
      &--target {
        flex: 1;
        font-weight: bold;
      }

      &--separator {
        flex: 0;
        padding-right: 2em;
        font-weight: bold;
        align-items: center;
        font-size: 16px;
      }

      &--field {
        flex: 2;
        width: 100%;
      }
    }

    &--invalid {
      td:not(:last-child) {
        margin-bottom: 20px;
      }
    }
  }
}
