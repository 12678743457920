@import "scss/vars.scss";

.ingestion-footer {
  display: inline-flex;
  align-items: center;
  font-family: Inter;
  // position: absolute;
  // bottom: 3rem;
  padding: 20px;
  background-color: $color-dg-input;
  border-width: 1px 0 0 0;
  border-top-color: $color-grey-dg;
  border-radius: 0 0 8px 8px;
  width: 100%;
  height: 60px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.05);
  // margin-top: 88.5vh;

  .footer-content {
    display: flex;
    width: 100%;

      .border-box {
        box-sizing: border-box;
        margin-left: 14px;
        align-items: center;
        height: 40px;
        min-width: 361px;
        border: 1px solid $color-grey-dg;
        border-radius: 8px;
        background-color: $color-white;
        box-shadow: 0 1px 3px 0 rgba(223, 225, 228, 0.5);
        display: inline-flex;
        font-size: 15px;
        color: $color-text;

        .icon {
          margin: 0 15px 0 25px;
        }

        .job-link {
          text-decoration: underline;
          margin: 0 15px 0 20px;
        }
      }

      .back-button {
        margin-left:auto;
      }
      
      .next-button {
        margin-left: 20px;
        .arrow {
          margin-right: 8px;
        }
      }
    }
  }