@import 'scss/vars.scss';

.tutorial-row-highlight {
  border: 2px solid $color-blue-dg;

  th,
  td {
    z-index: 2000;
    position: relative;
    border: solid $color-blue-dg !important;
    border-width: 2px 1px !important;
  }
}

.tutorial-column-highlight {
  z-index: 2000;
  position: relative;
}

.tutorial-disable-highlight {
  z-index: 2000;
  position: relative;
  background-color: $color-red-dg-light !important;
  border: solid $color-red-dg 1px !important;

  &.letter-header {
    background-color: $color-red-dg !important;
    color: $color-white !important;

    .header-secondary {
      color: $color-white;
    }
  }
}

.ingestion-tutorial-modal {
  .modal-content {
    max-width: 500px;
    min-width: 30vw !important;
  }
  .progress-wrapper {
    justify-self: flex-start;
    flex-grow: 1;
  }
  .arrow-bottom:before {
    position: relative;
    left: 50px;
    top: 10px;
    border-bottom: 15px solid $color-white;
    border-right: 15px solid transparent;
    border-left: 15px solid transparent;
    border-top: none;
  }

  .arrow-top:before {
    content: ' ';
    position: relative;
    left: 40px;
    top: -12px;
    border-top: none;
    border-right: 15px solid transparent;
    border-left: 15px solid transparent;
    border-bottom: 15px solid $color-white;
  }

  .arrow-left:after {
    content: ' ';
    position: absolute;
    left: -14px;
    top: 15px;
    border-top: 15px solid transparent;
    border-right: 15px solid $color-white;
    border-left: none;
    border-bottom: 15px solid transparent;
  }

  .arrow-right:before {
    content: ' ';
    position: absolute;
    right: -14px;
    top: 15px;
    border-top: 15px solid transparent;
    border-right: none;
    border-left: 15px solid $color-white;
    border-bottom: 15px solid transparent;
    z-index: 20;
  }

  .edit-footer {
    display: flex;
    .oi {
      margin-right: 0.5em;
    }
    .tutorial-reload {
      margin-right: 10px;
      background-color: transparent;
      border-color: black;
      color: black;
    }
  }
}
