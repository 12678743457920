@import 'scss/vars.scss';

.column-edit-panel {
  .modal-content {
    min-width: 50vw;

    .search-label {
      margin-bottom: 0.25em;

      div[class*='control'] {
        background-color: transparent;
      }

      > div {
        background-color: $color-dg-search;
      }

      div[class*='IndicatorsContainer'] {
        display: none;
      }
    }
  }

  .modal-footer {
    display: flex;
    width: 100%;
    border-top: 1px solid $color-grey-dg;
    background-color: $color-dg-input;

    button {
      padding: 8px 15px;
      white-space: nowrap;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 6px;
      color: #ffffff;
      border: 1px solid #16ad83;
      background-color: #20c094;
      cursor: pointer;
      margin-left: auto;
      margin-bottom: 0.25em;
      font-weight: 300;

      &:focus {
        outline: none;
      }

      &:disabled {
        background-color: $color-grey-300;
        border: 1px solid $color-grey-400;
      }
    }
  }
}

.edit-error {
  color: $color-red-dg;
  font-weight: 600;
}

.edit-modal-dialog {
  max-width: 900px !important;
}

.edit-body {
  display: flex;
  flex-direction: row;

  span {
    font-weight: 700;
  }

  .edit-comments {
    display: flex;
    padding: 1em;
    flex-direction: column;
    width: 50%;
  }

  .edit-selection {
    display: flex;
    padding: 1em;
    flex-direction: column;
    width: 50%;

    button {
      padding: 8px 15px;
      white-space: nowrap;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid $color-grey-dg-light;
      border-radius: 6px;
      background-color: #fff;
      cursor: pointer;
      margin-bottom: 0.25em;
      font-weight: 300;

      &:focus {
        outline: none;
      }

      &.selected {
        border: $color-orange-dg 1px solid;
        box-shadow: 0 0 3px 1px $color-orange-dg;
        font-weight: 700;
      }
    }
  }

  .add-comment-textarea {
    color: #1e2c47;
    font-size: 15px;
    border: 1px solid $color-grey-dg-light;
    border-radius: 4px;
    background-color: #ffffff;
    box-sizing: border-box;
    padding: 3px 10px;
    width: 100%;
    flex-grow: 1;
  }
}
