@import 'scss/vars.scss';

.analytics-detail {
  display: flex;
  flex-wrap: nowrap;
  width: 100%;

  .detail-label {
    flex: 3;
    text-align: end;
    font-weight: 600;
  }

  .detail-tooltip {
    margin: 0px 0.5em;
    .icon-circle {
      display: inline-block;
      width: 1.5em;
      height: 1.5em;
      padding: 0.3em;
      border-radius: 50%;
      box-shadow: 0 1px 3px 0 $color-grey-500;
    }
  }

  .detail-values {
    flex: 5;
    display: flex;
    flex-direction: column;

    .detail-chart {
      background-color: $color-grey-dg-light;
      width: 100%;
      height: 1em;
      display: flex;
      flex-wrap: nowrap;

      .chart-bar-valid {
        background-color: $color-green-dg;
        height: 1em;
      }
      .chart-bar-fill {
        background-color: $color-green-dg-light;
        height: 1em;
      }
    }
  }
}
