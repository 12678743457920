.modal-panel.img-bot-modal-panel {
  .modal-dialog {
    .img-bot-modal-panel-body {
      .ids-config-tabs {
        &.hide-nav {
          display: none;
        }
        .nav-item {
          &.invalid {
            .err-icon {
              color: red;
              margin-left: 0.5rem;
              margin-top: 0;
            }
          }
        }
      }

      .tooltip-icon {
        margin-left: 0.25em;
      }

      > section.download-list-input {
        > ul.ids-config-initial-file {
          margin-left: 0.5rem;
        }
      }
    }
  }
}
