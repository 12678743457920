@import 'scss/vars.scss';

.pricing-update-section {
  display: flex;
  justify-content: space-between;

  .header-card {
    display: flex;
    flex-direction: column;
    background-color: $color-white;
    border: 1px solid #e0e0e0;
    border-radius: 0.5em;
    box-shadow: 0 1px 3px 0 rgba(223, 225, 228, 0.5);
    margin-top: 1em;
    padding: 1em 1.5em 2em 1.5em;
    width: 100%;

    .title {
      color: $color-blue-dg;
      font-size: 1.1rem;
      font-weight: $typography-font-weight-boldest;
      margin-top: 0.25em;
    }

    .content {
      font-size: 1.8rem;
      font-weight: $typography-font-weight-bolder;
    }

    &.card-manufacturer {
      flex: 3 1;
      margin-right: 1em;
    }

    &.card-total {
      flex: 2 2;
    }
  }
}
