@import 'scss/vars.scss';

.export-functionality-advanced {
  section.block {
    &:not(:first-child) {
      margin-top: 1em;
    }

    .title {
      margin-bottom: 0.5em;
    }

    &.block-manufacturer-select {
      @include flex;

      > div:not(:first-child) {
        margin-left: 2em;
      }

      .manufacturer {
        flex: 0 0 20em;
      }

      .child-company {
        flex: 0 0 20em;
      }

      .product-count {
        flex: 0 0 10em;
        margin-left: 2em;

        .product-count-value {
          font-weight: $typography-font-weight-boldest;
          font-size: 1.5em;
        }
      }
    }

    &.block-manufacturer-error-message {
      width: 100%;

      .filter-btn-container {
        display: flex;
        flex-wrap: wrap;
        width: 100%;

        .deselect-btn {
          height: 38px;
        }

        > .alert {
          margin-right: 1em;
          margin-bottom: 0px;
          align-self: initial;
          font-size: 0.875rem;
          border-radius: 0.5rem;
          font-weight: 500;
          height: 38px;
        }
      }
    }

    &.block-template-select {
      width: 42em;
    }

    &.block-attributes-table-filter {
      width: 42em;

      .filter-attribute-types {
        @include unstyled-list;
        @include flex;

        align-items: center;
        justify-content: left;
        height: 38px;
        border: 0.1rem solid $color-grey-300;
        border-radius: 0.5rem;
        box-shadow: 0 0 1px $color-grey-300;
        margin-right: 1em;
        margin-bottom: 0.5em;

        .filter-attribute-types-item {
          @include flex;
          align-items: center;
          margin-left: 1em;

          .custom-control {
            z-index: 0;
          }

          &:not(:first-child) {
            margin-left: 5em;
          }

          &:last-child {
            margin-right: 0.8em;
          }

          &.is-changed .form-check .form-check-input {
            box-shadow: 0 0 4px $color-orange-dg;
          }
        }
      }

      > .alert {
        margin-top: 1em;
      }
    }

    &.block-attributes-table {
      margin-top: 2em;
      .react-bootstrap-table {
        thead {
          th.export {
            width: 10em;
          }

          th.type {
            width: 12em;
          }

          th.product_count {
            width: 12em;
          }

          th.output_format {
            width: 20em;
          }
        }
        tbody {
          tr.is-changed td:first-child {
            position: relative;

            &::before {
              position: absolute;
              top: 0;
              bottom: 0;
              left: 0;
              display: block;
              width: 3px;
              background: $color-orange-dg-light;
              border-radius: 0 3px 3px 0;
              box-shadow: 0 0 4px $color-orange-dg-light;
              content: '';
            }
          }

          tr:not(.is-exported) {
            color: $color-grey-500;
          }

          td.export .form-check.is-changed .form-check-input {
            box-shadow: 0 0 4px $color-orange-dg;
          }

          td.name .attribute-name-key-cell .attribute-key {
            color: $color-grey-400;
            font-weight: $typography-font-weight-bolder;
            font-size: 0.85em;

            .icon {
              margin-left: 0.5em;
              &.duplicate-icon {
                color: $color-orange-dg;
              }
            }
          }

          td.output_format .form-control.is-changed {
            border-color: $color-orange-dg-light;
            box-shadow: 0 0 4px $color-orange-dg-light;
          }
        }
      }
    }

    &.block-actions {
      @include flex;
      align-items: center;
      justify-content: space-between;

      margin-top: 2em;
      margin-bottom: 2em;
      padding: 1em;
      background: $color-grey-50;
      border-radius: 0.5em;

      .btn {
        &:not(:first-child) {
          margin-left: 0.5em;
        }

        .icon {
          margin-right: 0.5em;
        }
      }
    }
  }
}

.modal-export-functionality-advanced-create-template {
  .modal-body {
    > div:not(:first-child) {
      margin-top: 1em;
      .title {
        margin-bottom: 0.5em;
      }
    }

    .block-manufacturer-select {
      @include flex;
      justify-content: space-between;

      > div {
        flex: 1 1 50%;

        &:not(:first-child) {
          margin-left: 1em;
        }
      }
    }

    .template-name {
      .alert {
        margin-top: 1em;
      }
    }
  }
}
