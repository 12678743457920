.prod-filter-wrap {
  margin: 2em 0;
  .prod-filter-main {
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-start;
    justify-content: flex-start;

    .prod-filter-text-search {
      flex: 2 1 0;
    }

    .products-search,
    .mfr-search {
      position: relative;

      .search {
        margin: 0;
      }

      .search-suggestions {
        margin-top: 5px;
      }
    }

    .prod-filter-companies {
      margin-left: 1.5em;
      .dropdown-menu {
        min-width: 300px;
        padding: 0;
      }

      ul {
        @include unstyled-list;
        padding: 1.5em;
        color: $color-text;
        font-size: 14px;

        li {
          margin: 8px 0;

          &:first-child {
            margin-top: 0;
          }

          &:last-child {
            margin-bottom: 0;
          }
        }
      }

      &-item {
        display: inline-flex;
        align-items: center;
        width: 100%;

        &:first-child {
          font-weight: $typography-font-weight-bolder;
        }
      }

      &-input {
        @include button-reset;
        display: inline-flex;
        align-items: center;
        margin-right: 8px;

        &:focus,
        &:active {
          outline: none;
        }
      }

      &-footer {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        padding: 10px 20px;
        font-weight: $typography-font-weight-bolder;
        font-size: 14px;
        line-height: 30px;
        background-color: $color-dg-input;
        border-top: 1px solid $color-grey-dg;
        border-radius: 0 0 8px 8px;

        &-btn {
          @include user-select-none;
          padding: 0.2rem 0.8rem;
          border-radius: 0.5rem;
          cursor: pointer;

          &--secondary {
            background-color: $color-white;
            border: 1px solid $color-grey-dg;
            box-shadow: 0 1px 3px 0 rgba(223, 225, 228, 0.5);
          }

          &--primary {
            color: $color-white;
            background-color: $color-green-dg;
            border: 1px solid $color-green-2;
            box-shadow: 0 2px 3px 0 rgba(32, 192, 148, 0.2);

            &:hover,
            :focus,
            :active {
              background: linear-gradient(180deg, #30d0a4 0%, #20c094 100%);
            }
          }

          &:first-child {
            margin-right: 10px;
          }
        }
      }
    }

    .prod-filter-supplier-selector {
      flex: 1 1 0;
      margin-left: 2em;

      .react-select {
        .react-select__control {
          min-height: 33px;
          border-radius: 3px;
        }
        .react-select__indicator {
          padding: 6px;
        }
      }
    }
    .prod-filter-product-selector {
      flex: 1 1 25em;

      .react-select {
        .react-select__control {
          min-height: 33px;
          border-radius: 3px;
        }
        .react-select__indicator {
          padding: 6px;
        }
      }
    }
    .prod-filter-taglist {
      margin-top: 0.5em;
      .badge {
        margin: 0.25em 0.15em;
        padding: 0.4em 0.75em;

        .icon {
          margin-left: 0.5em;
          cursor: pointer;
        }
      }
    }
  }

  .prod-filter-info-bar {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-start;
    margin-top: 2em;

    .prod-filter-status {
      flex: 1 1 auto;

      .prod-filter-summary {
        display: inline-block;
        color: $color-grey-550;
      }

      .prod-filter-clear {
        display: inline-block;
      }

      > :not(:first-child) {
        margin-left: 0.5em;
      }
    }

    .prod-filter-export-link {
      margin-right: 1em;
      a {
        text-transform: uppercase;
      }
    }

    .prod-filter-advanced-toggle {
      .link {
        text-transform: uppercase;
        .icon {
          margin-left: 0.25em;
          > svg {
            transform: rotate(0);
            transition: all 200ms ease-in;
          }
        }
        &.active .icon svg {
          transform: rotate(90deg);
        }
        &.disabled {
          display: none;
        }
      }
    }
  }
  .prod-filter-advanced-wrap {
    max-height: 800px;
    margin-top: 1em;
    padding: 1em 2em;
    overflow: hidden;
    background: $color-grey-100;
    border-top: 1px solid $color-grey-400;
    transition: all 200ms ease-in;

    &:not(.active) {
      max-height: 0;
      padding-top: 0;
      padding-bottom: 0;
    }

    .prod-filter-form-wrap {
      max-width: 60em;
      margin: 1em auto;

      .form-label {
        font-size: 1.2em;
        text-align: right;
      }

      .actions {
        margin-top: 2em;
        text-align: right;
      }
    }
  }
}
