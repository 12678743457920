@import 'scss/vars.scss';

.template-passThruModal-button {
  min-width: 5rem;
}
.template-passThruModal-warning-button {
  .btn-warning {
    background-color: $color-yellow-dg;
    border-color: $color-yellow-dg;
    &:hover {
      background-color: scale-color($color: $color-yellow-dg, $lightness: -10%);
      border-color: scale-color($color: $color-yellow-dg, $lightness: -20%);
    }
  }
}
.pass-thru-modal-dialog {
  .modal-body {
    display: flex;
    margin: 1em;
    justify-content: space-evenly;
  }
}

.pass-thru-create-details-bottom {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  height: 100%;
  padding: 20px;
  background-color: $color-grey-100;
  border-style: solid;
  border-width: 2px 0 0 0;
  border-top-color: $color-grey-200;
  border-radius: 0 0 0.5em 0.5em;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.05);

  .footer-button {
    min-width: 138px;
    margin: 1em;
  }
}

.selection-modal {
  width: 80%;
  color: $typography-font-color-normal;
}
.options-cards {
  .title h2 {
    font-size: 2rem;
  }
}

.options-list {
  @include flex;
  // align-items: stretch;
  justify-content: space-evenly;
  margin-top: 1em;

  .option-wrap {
    @include flex;
    @include block;
    flex: 0 1 45%;
    padding: 1em 1.5em;
    background: $color-grey-50;

    &.active {
      background: $color-white;
      box-shadow: 0 0 3px 0 $color-orange-dg;
    }

    &.invalid {
      border-color: #dc3545;
    }

    &:not(.active) {
      cursor: pointer;

      &:hover {
        border-color: $color-grey-500;
      }
    }

    .option-input {
      margin-right: 1em;
    }

    .form-check-label {
      font-weight: $typography-font-weight-bold;
      font-size: 1.1em;
      line-height: 1.3;
      color: $typography-font-color-normal;
    }

    .option-label {
      .title {
        font-weight: $typography-font-weight-bold;
        font-size: 1.1em;
        line-height: 1.3;
      }

      .subtitle {
        margin-top: 0.5em;
      }
    }
  }
  .option-wrap-isIE {
    flex-basis: 29%;
  }

  .invalid-feedback {
    margin-top: 1em;
  }

  &.invalid {
    .invalid-feedback {
      display: block;
    }
  }
}
