/* prettier-ignore */
.loading-spinner {
  justify-content: space-evenly;
  display: inline-block;
  position:relative;
	width: 1em;
	height: 1em;
  margin:auto;
  vertical-align: middle;

  > span {
    position:absolute;
    background-color:rgb(255,255,255);
    height:0.2em;
    width:0.2em;
    border-radius:0.1em;
    animation-name: loading-spinner;
    animation-duration:1.2s;
    animation-iteration-count:infinite;
    animation-direction:normal;

    &:nth-child(1) { left:0; top:0.41em; animation-delay:0.45s; }
    &:nth-child(2) { left:0.12em; top:0.12em; animation-delay:0.6s; }
    &:nth-child(3) { left:0.41em; top:0; animation-delay:0.75s; }
    &:nth-child(4) { right:0.12em; top:0.12em; animation-delay:0.9s; }
    &:nth-child(5) { right:0; top:0.41em; animation-delay:1.05s; }
    &:nth-child(6) { right:0.12em; bottom:0.12em; animation-delay:1.2s; }
    &:nth-child(7) { left:0.41em; bottom:0; animation-delay:1.35s; }
    &:nth-child(8) { left:0.12em; bottom:0.12em; animation-delay:1.5s; }
  }

  &.fast > span {
    animation-duration:0.6s;

    &:nth-child(1) { animation-delay:0.22s; }
    &:nth-child(2) { animation-delay:0.3s; }
    &:nth-child(3) { animation-delay:0.37s; }
    &:nth-child(4) { animation-delay:0.45s; }
    &:nth-child(5) { animation-delay:0.52s; }
    &:nth-child(6) { animation-delay:0.6s; }
    &:nth-child(7) { animation-delay:0.67s; }
    &:nth-child(8) { animation-delay:0.75s; }
  }
}

@keyframes loading-spinner {
  0% {
    background-color: rgb(0, 0, 0);
  }

  100% {
    background-color: rgb(255, 255, 255);
  }
}
