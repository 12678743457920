@import "scss/vars.scss";

$_slug: "alt-text-creator";
$_block_padding: 1.5em;

.alt-text-creator-bot-wrap {
    .databot-config-panel.databot-config-panel-#{$_slug} {
        .databot-config-panel-body {
            position: relative;

            .databot-config-panel-body-content.description {
                color: $color-text;
            }

            .databot-config-panel-actions {
                margin-top: 0;
                position: absolute;
                right: $_block_padding;
                top: $_block_padding;
            }
        }
    }

    .alt-text-creator-progress-bar {
        .cancel {
            margin-top: 1em;
            color: $color-text;
        }
    }
}
