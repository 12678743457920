@import "scss/vars.scss";

.attributes {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.attributes-body {
    width: 100%;
    padding: 18px 20px;
    color: $color-text;
    background-color: $color-white;
    border-radius: 8px;
    box-shadow: 0 1px 5px 0 rgba(30, 44, 71, 0.12);
}

.attributes-header {
    @include flex;

    .attributes-title {
        padding: 13px 20px;
        color: $color-grey-550;
        font-weight: $typography-font-weight-bolder;
        font-size: 15px;
        letter-spacing: 0.3px;
        cursor: pointer;

        &--active {
            position: relative;
            color: $color-text;
            background-color: $color-white;
            border-radius: 8px 8px 0 0;
            box-shadow: 0 1px 5px 0 rgba(30, 44, 71, 0.12);

            &::after {
                position: absolute;
                right: 0;
                bottom: -5px;
                left: 0;
                height: 10px;
                background-color: $color-white;
                content: "";
            }
        }
    }
}

.attributes-filter {
    display: flex;
    flex-direction: row;
    align-items: center;

    label {
        @include user-select-none;
        margin: 0;
        color: $color-text;
    }

    .attributes-filter-row {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin: 12px 0;
    }

    .attributes-filter-checkbox {
        display: flex;
        align-items: center;
        margin-right: 10px;
    }

    .attributes-filter-toggle {
        display: flex;
        align-items: center;
        margin-right: 30px;
        font-size: 15px;

        svg {
            margin-right: 12px;
        }
    }

    .label {
        margin-right: 5px;
        font-weight: $typography-font-weight-bolder;
        font-size: 15px;
    }

    .dropdown-toggle {
        height: 100%;
        padding: 8px 14px;
    }

    input[type="checkbox"] {
        margin-right: 10px;
        cursor: pointer;
    }

    .dropdown-menu {
        min-width: 330px;
        margin: 10px 0;
        padding: 0;
        color: $color-text;
        border: 1px solid $color-grey-dg;
        border-radius: 8px;
        box-shadow: 0 2px 6px 0 rgba(30, 44, 71, 0.2);
    }

    .filter-header {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 10px 20px 0 20px;

        .title {
            font-weight: $typography-font-weight-bolder;
            font-size: 18px;
            line-height: 30px;
        }

        .close-icon {
            color: $color-grey-dg;
            cursor: pointer;
        }
    }

    .filter-categories {
        margin: 20px 0;
        padding: 0px 20px 10px 20px;
        font-size: 13px;

        .filter-categories-header {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            margin-bottom: 10px;
            font-weight: $typography-font-weight-bolder;

            &__select {
                color: $color-green-dg;
                cursor: pointer;
                -webkit-user-select: none;
                -ms-user-select: none;
                user-select: none;
            }
        }
    }

    .filter-footer {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        padding: 10px 20px;
        font-weight: $typography-font-weight-bolder;
        font-size: 15px;
        line-height: 30px;
        background-color: $color-dg-input;
        border-top: 1px solid $color-grey-dg;
        border-radius: 0 0 8px 8px;

        .filter-footer-btn {
            @include user-select-none;
            padding: 0.375rem 0.8rem;
            border-radius: 0.5rem;
            cursor: pointer;

            &--secondary {
                background-color: $color-white;
                border: 1px solid $color-grey-dg;
                box-shadow: 0 1px 3px 0 rgba(223, 225, 228, 0.5);
            }

            &--primary {
                color: $color-white;
                background-color: $color-green-dg;
                border: 1px solid $color-green-2;
                box-shadow: 0 2px 3px 0 rgba(32, 192, 148, 0.2);

                &:hover,
                :focus,
                :active {
                    background: linear-gradient(180deg, #30d0a4 0%, #20c094 100%);
                }
            }

            &:first-child {
                margin-right: 10px;
            }
        }
    }
}

.attributes-options {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 15px;
}

.attributes-table {
    display: flex;
    flex-direction: column;
    width: 100%;
    border: 1px solid $color-grey-dg;
    border-radius: 6px 6px 0 0;

    .attributes-table-header {
        @include flex;
        color: $color-grey-550;
        font-size: 13px;
        border-bottom: 1px solid $color-grey-dg;
    }

    .attributes-table-header-category {
        @include user-select-none;
        flex: 1;
        padding: 10px 15px;

        span:hover {
            cursor: pointer;
        }

        svg {
            margin-left: 8px;
        }
    }

    .attributes-table-header-info {
        flex: 5;

        li {
            padding: 10px 20px;
            border-left: 1px solid transparent;
        }
    }

    .attributes-table-header-cell {
        @include flex;
        gap: 12px;
        justify-content: space-between;

        .attributes-table-header-attribute {
            flex: 1;
        }
        .attributes-table-header-value {
            flex: 2;
        }

        li {
            padding: 5px 20px;
            border-bottom: 1px solid $color-grey-dg;
            border-left: 1px solid $color-grey-dg;

            &:last-child {
                border-bottom: none;
            }
            &:nth-child(odd) {
                background: $color-white;
            }
        }
    }

    .attributes-table-row {
        @include flex;
        border-bottom: 1px solid $color-grey-dg;

        &:last-child {
            border-bottom: none;
        }

        &--none {
            padding: 10px 15px;
        }
    }

    .attributes-table-row-category {
        @include break-word-wrap;
        flex: 1;
        width: 100%;
        max-width: 260px;
        padding: 5px 15px;
        font-weight: $typography-font-weight-bolder;
        -webkit-hyphens: auto;
        -ms-hyphens: auto;
        hyphens: auto;
    }

    .attributes-table-row-info {
        flex: 5;

        li {
            padding: 5px 20px;
            border-bottom: 1px solid $color-grey-dg;
            border-left: 1px solid $color-grey-dg;
            &:last-child {
                border-bottom: none;
            }
        }
    }

    .attributes-table-row-cell {
        @include flex;
        @include break-word-wrap;
        gap: 12px;
        justify-content: space-between;
        background: $color-white;

        .attributes-table-row-attribute {
            flex: 1;
            font-weight: $typography-font-weight-bolder;
        }

        .attributes-table-row-value {
            flex: 2;
            white-space: pre-line;
        }

        &--alt {
            background: $color-dg-input;
        }
    }
}

.feedback-info {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 5px 15px;
    font-weight: $typography-font-weight-bolder;
    font-size: 15px;
    background-color: $color-white;
    border: 1px solid $color-grey-dg;
    border-radius: 6px;
    box-shadow: 0 1px 3px 0 rgba(223, 225, 228, 0.5);

    svg {
        margin-right: 10px;
    }

    &:focus {
        outline: none;
    }
}
