@import 'scss/vars.scss';

$_slug: 'attribute-compression';
$_block_padding: 1.5rem;



button:disabled,
button[disabled]{
      background-color: $color-grey-500;
  }

.form-control-custom-value {
    width: 50%;
    margin-top: 1.5rem;
  }

.form-control-custom-value button {
    margin-top: 1.5rem;
    margin-left: 5%;
    width: 40%;
  }

.databot-config-panel.databot-config-panel-#{$_slug} {
  color: $color-text;
  .databot-config-panel-body {
    .databot-config-panel-body-content {
      div.note {
        color: $color-grey-550;
        font-size: 0.85em;
      }

      div.error-note {
        margin-top: 5px;
        color: $color-red-2;
        font-size: 0.85em;
      }
      div.config-panel {
        @include flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;

        div.column {
          flex: 40%;

          &:first-child {
            margin-right: $_block-padding;
          }
          &:not(:first-child) {
            margin-left: $_block-padding;
          }

          .styled-multiselect-container {
            width: 50%;
          }
          
          .styled-multiselect-container-invalid {
            width: 50%;
          }

          section.configuration {
            section {
              &:not(:first-child) {
                margin-top: $_block-padding;
              }
              .label {
                font-weight: $typography-font-weight-bolder;
              }
              .helper {
                color: $color-grey-550;
                font-size: 0.85em;
              }
            }
          }

          > section {
            &:not(:first-child) {
              margin-top: #{$_block-padding * 2};
            }

            .styled-multiselect-container {
              overflow-wrap: anywhere;
              width: 50%;
            }

            > .title {
              font-size: 1.5rem;
              font-weight: $typography-font-weight-bolder;
            }

            > .content {
              margin-top: $_block-padding;
            }

            &.primary-file-selection,
            &.secondary-file-selection {
              > .content {
                > :not(:first-child) {
                  margin-left: $_block-padding;
                }

                .styled-multiselect-container {
                  width: 50%;
                  vertical-align: middle;
                }

              }
            }

            &.header-selection {
              > .content {
                @include flex;
                flex-direction: column;
                justify-content: space-between;

                .header-selector {
                  width: 100%;
                  vertical-align: middle;

                  .file-label {
                    font-weight: $typography-font-weight-bold;
                  }

                  .styled-multiselect-container {
                    width: 100%;
                    margin-top: 0.5em;
                  }
                }

                > .icon-divider {
                  padding: 0 $_block-padding;
                  .icon {
                    color: $color-grey-500;
                    font-size: 2rem;
                  }
                }
              }
            }
            .table-wrapper {
              @include flex;
              width: 100%;
              .drag-table {
                width: 100%;
              }
            }
          }
        }
      }
      div.databot-config-panel-footer {
        @include flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 2rem;

        .error-wrapper {
          color: $color-red-2;
          font-size: 1em;
        }

        .powered-by-wrapper {
          span {
            img {
              margin-left: 0.4em;
              width: 2em;
              height: 2em;
            }
          }
        }
      }
    }

    .databot-config-panel-actions {
      margin-top: 4rem;
    }
  }
}
