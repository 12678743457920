@import 'scss/vars.scss';

.export-functionality-panel {
  .export-functionality-filter {
    @include flex;

    > section:not(:first-child) {
      margin-left: 2em;
    }

    section.filter-mfr-select {
      flex: 0 0 20em;
    }

    section.actions {
      margin-top: auto;
    }

    section .title {
      margin-bottom: 0.5em;
    }
  }

  .exports-table {
    margin-top: 2em;
  }
}

.log-table-keys{
  display: block;
}
.log-table-key{
  margin-bottom: 1em;
}
.detailed-attributes-bot {
  @mixin _kv-title {
    color: $color-blue-dg;
    font-weight: $typography-font-weight-bolder;
    font-size: 1.2em;
  }
  section.block {
    &.databots-summary {
      @include flex;
      align-items: stretch;
      justify-content: space-between;
      margin-top: 2em;

      > .bai-value-block {
        @include block;
        width: 31%;
        max-width: 30em;
        padding: 1em;

        > .title {
          @include flex;
          align-items: center;
          .label {
            @include _kv-title;
            flex: 1 1 auto;
          }

          .title-tooltip {
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            justify-content: center;
            width: 1.5em;
            height: 1.5em;
            padding: 0.3em;
            border: 1px solid $color-grey-400;
            border-radius: 50%;
            box-shadow: 0 1px 3px 0 $color-grey-500;
            cursor: default;

            .icon {
              color: $color-grey-550;
              font-size: 0.85em;
            }
          }
        }

        > .value {
          margin-top: 0.25em;
          font-weight: $typography-font-weight-bolder;
          font-size: 2.5em;
          line-height: 1.2;
        }

        > .subtitle {
          @include _kv-title;
          color: $typography-font-color-normal;
          font-size: 1em;
          text-transform: uppercase;
        }

        > .additional-data {
          margin-top: 0.5em;
          color: $color-grey-500;
          font-weight: $typography-font-weight-bolder;
          line-height: 1.2;

          .value {
            font-weight: $typography-font-weight-boldest;
          }
        }
      }
    }

    &:not(:first-child) {
      margin-top: 1em;
    }

    .title {
      margin-bottom: 0.5em;
    }

    &.block-manufacturer-select {
      @include flex;

      > div:not(:first-child) {
        margin-left: 2em;
      }

      .manufacturer {
        flex: 0 0 20em;
      }

      .child-company {
        flex: 0 0 20em;
      }

      .product-count {
        flex: 0 0 10em;
        margin-left: 2em;

        .product-count-value {
          font-weight: $typography-font-weight-boldest;
          font-size: 1.5em;
        }
      }
    }

    &.block-manufacturer-error-message {
      width: 42em;
    }

    &.block-template-select {
      width: 42em;
    }

    &.block-attributes-table-filter {
      width: 42em;

      .filter-attribute-types {
        @include unstyled-list;
        @include flex;

        align-items: center;
        justify-content: left;
        height: 38px;
        border: 0.1rem solid $color-grey-300;
        border-radius: 0.5rem;
        box-shadow: 0 0 1px $color-grey-300;

        .filter-attribute-types-item {
          @include flex;
          align-items: center;
          margin-left: 1em;

          &:not(:first-child) {
            margin-left: 5em;
          }

          &.is-changed .form-check .form-check-input {
            box-shadow: 0 0 4px $color-orange-dg;
          }
        }
      }

      > .alert {
        margin-top: 1em;
      }
    }

    &.block-attributes-table {
      margin-top: 2em;
      .react-bootstrap-table {
        thead {
          th.export {
            width: 10em;
          }

          th.type {
            width: 12em;
          }

          th.product_count {
            width: 12em;
          }

          th.output_format {
            width: 20em;
          }
        }
        tbody {
          tr.is-changed td:first-child {
            position: relative;

            &::before {
              position: absolute;
              top: 0;
              bottom: 0;
              left: 0;
              display: block;
              width: 3px;
              background: $color-orange-dg-light;
              border-radius: 0 3px 3px 0;
              box-shadow: 0 0 4px $color-orange-dg-light;
              content: '';
            }
          }

          tr:not(.is-exported) {
            color: $color-grey-500;
          }

          td.export .form-check.is-changed .form-check-input {
            box-shadow: 0 0 4px $color-orange-dg;
          }

          td.name .attribute-name-key-cell .attribute-key {
            color: $color-grey-400;
            font-weight: $typography-font-weight-bolder;
            font-size: 0.85em;

            .icon {
              margin-left: 0.5em;
              &.duplicate-icon {
                color: $color-orange-dg;
              }
            }
          }

          td.output_format .form-control.is-changed {
            border-color: $color-orange-dg-light;
            box-shadow: 0 0 4px $color-orange-dg-light;
          }
        }
      }
    }

    &.block-actions {
      align-items: center;
      justify-content: space-between;

      margin-top: 2em;
      margin-bottom: 2em;
      padding: 1em;
      background: $color-grey-50;
      border-radius: 0.5em;

      .btn {
        &:not(:first-child) {
          margin-left: 0.5em;
        }

        .icon {
          margin-right: 0.5em;
        }
      }
    }
    &.block-log {
      padding-bottom: 9em;
    }
  }
}

.modal-detailed-bot {
  .modal-body {
    > div:not(:first-child) {
      margin-top: 1em;
      .title {
        margin-bottom: 0.5em;
      }
    }
    .corrected-label-title {
      color: #3363ff;
      font-weight: 600;
      font-size: 12px;
      text-align: center;
    }
    .corrected-label-row {
      margin-bottom: 10px;
      text-align: center;
    }
    .block-manufacturer-select {
      @include flex;
      justify-content: space-between;

      > div {
        flex: 1 1 50%;

        &:not(:first-child) {
          margin-left: 1em;
        }
      }
    }

    .template-name {
      .alert {
        margin-top: 1em;
      }
    }
  }
}
