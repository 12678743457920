@import 'scss/vars.scss';

.drag-table {
  padding: 10px;

  table {
    width: 100%;
    border-collapse: collapse;

    > tbody {
      overflow: auto;
      cursor: grabbing;
    }

    > thead th,
    > tbody td {
      padding: 5px;
      text-align: left;
      color: $color-text;

      button,
      button:hover {
        color: $color-text;
      }
    }
  }
}
