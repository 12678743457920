@import 'scss/vars.scss';

body[data-page='view-data-jobs'] {
  background: $color-grey-50;
}

// Need to add this block, which is a duplicate of styling inside – element escapes DOM
.djl-filter-dropdown-menu .filter-dropdown-wrap {
  min-width: 35em;
  max-width: 492px;

  .reset-button {
    padding: 0;
  }

  .title {
    margin-bottom: 10px;
    font-weight: $typography-font-weight-bolder;
  }
}

.view-data-jobs {
  .title {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: space-between;

    h1 {
      font-size: 2rem;
    }
  }

  .data-jobs-filter {
    @include flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: space-between;
    padding-top: 1.5em;

    .filter-left-side {
      @include flex;
      flex-grow: 1;
      align-items: center;
    }

    .filter-right-side {
      @include flex;
      flex-grow: 4;
      line-height: 3em;
      & > div:not(:last-child) {
        margin-right: 15px;
      }
    }

    .filter-block-text-search {
      position: relative;
      display: inline-flex;
      width: 100%;

      .form-control {
        padding-left: 15px;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }

      &-btn {
        padding: 0 10px;
        color: $color-white;
        background-color: $color-green-dg;
        border: 1px solid $color-green-2;
        border-top-right-radius: 0.5rem;
        border-bottom-right-radius: 0.5rem;

        &:hover {
          background-color: $color-green-2;
        }
      }
    }

    .filter-block-show-type,
    .filter-block {
      .show-type-input-wrap {
        @include flex;
        align-items: center;
        height: 100%;
        line-height: 1.7rem;

        .show-type-input-wrap-label {
          margin-top: -0.2em;
          color: $color-grey-550;
        }

        .show-type-input {
          @include flex;
          &:not(:first-child) {
            margin-left: 1.5em;
          }
        }
      }
    }

    .filter-block-advanced {
      height: 100%;
      line-height: 1.7rem;

      .icon {
        display: inline-flex;
        align-items: center;
        margin-right: 0.45em;
        font-size: 12px;

        &--advanced-filter {
          color: $color-green-dg;
        }
      }

      .dropdown-toggle {
        display: inline-flex;
        align-items: center;
      }

      .filter-toggle .dropdown {
        .dropdown-toggle {
          height: 35.2px;
          font-weight: normal;
        }

        .filter-dropdown-wrap {
          min-width: 35em;
        }
      }
    }

    .filter-block-advanced.filter-toggle .dropdown .filter-dropdown-wrap {
      min-width: 35em;

      .reset-button {
        padding: 0;
      }

      .title {
        margin-bottom: 10px;
        font-weight: $typography-font-weight-bolder;
      }
    }

    .filter-block-time-period {
      @include flex;
      height: 100%;
      line-height: 1.7rem;

      .filter-block-time-period-label {
        margin-right: 5px;
        color: $color-grey-550;
      }
    }

    & + .results-summary {
      margin-top: 1em;

      & + .data-jobs-tabs {
        margin-top: 1em;
      }
    }
  }

  .results-summary {
    color: $color-grey-550;
  }

  .data-jobs-tabs {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    color: $color-text;
    max-width: 100%;
    position: relative;
    bottom: -1px;
    border-bottom: 0px;
    text-align: center;
    margin-top: 2em;

    &-tab {
      @include button-reset;
      width: 180px;
      margin-right: 10px;
      padding: 10px 15px;
      font-size: 15px;
      text-align: center;
      background-color: $color-grey-dg;
      border: 1px solid $color-grey-dg;
      border-bottom: 0;
      border-radius: 6px 6px 0 0;
      box-shadow: 0px 1px 3px rgba(223, 225, 228, 0.5);

      &:focus,
      &:active {
        outline: none;
      }

      &:hidden {
        margin-top: 50px;
      }
      &--active {
        background-color: $color-white;
        border-bottom-color: $color-white;
        position: relative;
        border-bottom: 0px;
        box-shadow: 0px 0px 0px rgba(223, 225, 228, 0.5);
        font-weight: $typography-font-weight-bolder;
      }
    }
  }

  .react-bootstrap-table {
    border-radius: 0px 0.5em 0.5em 0.5em;
    border-color: $color-grey-dg;

    .data-jobs-table-unread {
      font-weight: $typography-font-weight-bolder;
    }

    .data-jobs-table-read {
      background-color: $color-dg-input;
    }

    .pass-thru-file-row-cursor {
      cursor: default !important;
    }

    /* prettier-ignore */
    thead {
      tr {
        th {
          .label {
            color: $color-grey-550;
          }
        }
        th.status { width: 7em; }
        th.name { width: 10em; }
        th.updated_at { width: 8em; }
        th.id { width: 6em; }
        th.due_date { width: 7em;}
        th.distributor { width: 10em; }
        th.manufacturer { width: 10em; }
        th.requestor { width: 13em; }
        th.recipient { width: 13em; }
        th.sender { width: 13em; }
        th.created_at { width: 7em; }
        th.priority { width: 6em; }
        th.actions { width: 5em; .th-wrap { justify-content: center; } }
        th.pass_thru_filename { width: 30%; }
        th.pass_thru_created_at { width: 10%; }
        th.pass_thru_sender { width: 15%; }
        th.pass_thru_recipient { width: 15%; }
        th.file_download { width: 15%; }
      }
    }

    tbody {
      tr {
        &:hover {
          cursor: pointer;
        }

        td {
          &.pass_thru_created_at {
            word-wrap: break-word;
          }
          &.pass_thru_filename {
            word-break: break-word;
          }

          .download-file {
            @include button-reset;
            padding: 10px 30px;
            color: $color-text;
            font-weight: $typography-font-weight-bolder;
            font-size: 15px;
            background-color: $color-white;
            border: 1px solid #9aa4ac;
            border-radius: 6px;
            box-shadow: 0px 2px 6px rgba(0, 19, 92, 0.06);

            &:hover {
              color: $color-white;
              background-color: $color-blue-dg;
              border-color: $color-blue-dg;
            }
          }

          &.actions {
            text-align: center;
            button {
              padding: 0.25em;
              background: transparent;
              border: none;
              &:active,
              &:focus,
              &::-moz-focus-inner {
                background: transparent;
                box-shadow: none !important;
              }
              &::after {
                display: none;
              }

              svg {
                display: block;
              }
            }
          }

          &.status {
            text-transform: capitalize;
          }

          &.name {
            @include break-word-wrap;
          }

          &.priority span {
            display: inline-block;
            width: 100%;
            padding: 0.15em 0.5em;
            overflow: hidden;
            color: $color-grey-500;
            font-weight: $typography-font-weight-bolder;
            font-size: 0.85em;
            text-align: center;
            text-transform: uppercase;
            text-overflow: ellipsis;
            text-shadow: 0 0 1px $color-white, 0 0 1px $color-white, 0 0 1px $color-white;
            background: $color-grey-50;
            border-radius: 0.5em;

            & {
              &.priority-1 {
                color: change-color($color-green-dg, $lightness: 40%);
                background: change-color($color-green-dg, $lightness: 90%);
              }
              &.priority-2 {
                color: change-color($color-yellow-dg, $lightness: 40%);
                background: change-color($color-yellow-dg, $lightness: 90%);
              }
              &.priority-3 {
                color: change-color($color-red-2, $lightness: 40%);
                background: change-color($color-red-2, $lightness: 90%);
              }
            }
          }
        }
      }
    }

    .user-info {
      overflow: hidden;
      word-wrap: break-word;
    }

    .pass-thru-user-info {
      overflow: hidden;
    }

    .pass-thru-name-link {
      text-align: left;
    }
  }

  .no-jobs-found-alert {
    margin-left: 1em;
    font-weight: $typography-font-weight-bolder;
  }

  .react-bootstrap-table-pagination {
    @include flex;
    align-items: center;
    margin-top: 1.5em;
    margin-bottom: 1.5em;

    > ul.pagination {
      flex: 1 0 auto;
      margin: 0 1em 0 0;
    }

    .results-summary {
      margin-right: 1em;
    }
  }

  .loading-ovly {
    @include loading-ovly;
  }
}

.confirm-publish-job-modal {
  font-size: medium;
  font-weight: $typography-font-weight-normal;
  color: $color-text;
}

@media screen and (min-width: 900px) {
  .filter-right-side {
    justify-content: flex-end !important;
  }
}
