@import "scss/vars.scss";

.data-job-additional-items-details-box {
  @include block;
  box-sizing: border-box;
  width: 100%;
  max-width: 100%;
  margin-top: 1em;
  margin-right: 20px;
  padding: 1em 1.5em 2em 1.5em;
  background-color: $color-white;
  border-radius: 8px;
  box-shadow: 0 1px 5px 0 rgba(30, 44, 71, 0.12);

  h2 {
    font-size: 2em;
  }

  h3 {
    font-size: 1em;
    font-style: italic;
  }

  .details-top {
    padding: 15px;
  }

  .details-bottom {
    padding: 15px;
    background-color: $color-dg-search;
    border-style: solid;
    border-width: 2px 0 0 0;
    border-top-color: #f6f7f7;
  }

  button {
    margin-left: 8px;
  }

  .data-job-link-btn {
    margin-top: 14px;
    margin-left: 0;
  }

  .data-job-create-details-footer {
    margin-right: -1.5em;
    margin-bottom: -2em;
    margin-left: -1.5em;
  }

  .data-job-additional-details-right {
    width: calc(100% + 3em);
    margin-right: -1.5em;
    margin-left: -1.5em;
  }

  .data-job-additional-details-left {
    width: calc(100% + 3em);
    margin-right: -1.5em;
    margin-left: -1.5em;
  }

  .data-job-additional-items-title {
    margin-left: 15px;
  }
}

.data-job-create-link-search-title {
  width: 100%;
  margin-top: 5px;
  margin-bottom: -15px;
}

.data-job-create-job-link-modal-search-input {
  width: 100%;
  height: 100%;
  margin-top: 1rem;
  margin-bottom: auto;
  margin-left: auto;
}

.data-job-create-job-details-box-link-data-box {
  box-sizing: border-box;
  margin: 10px 5px 0 5px;
  padding: 10px;
  border-color: rgba(30, 44, 71, 0.12);
  border-style: solid;
  border-width: 0.1rem;
  border-radius: 8px;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.05);
}
