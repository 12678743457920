@import "scss/vars.scss";

.progress-dot {
  height: 0.8em;
  width: 0.8em;
  background-color: $color-grey-dg;
  border-radius: 50%;
  display: inline-block;
  margin-right: 0.8em;

  &.current {
    background-color: $color-green-dg;
  }
}
