.cds-attribute-compression-configuration {
  section {
    &:not(:last-child) {
      margin-bottom: 1em;
    }
  }
  .length-threshold-selector {
    > .content {
      .form-control-length-threshold {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        gap: 1em;

        &-select {
          flex: 1;
        }

        &-value {
          flex: 1;
          input {
            height: 40px;
          }
        }
      }
    }
  }
}
