@import 'scss/vars.scss';

.sheet-tab-container {
  display: flex;
}

button:disabled,
button[disabled] {
  background-color: $color-grey-300;
  color: $color-grey-550;
}

// .tab-wrapper {
//   overflow: hidden;

//   &.active-wrapper {
//     overflow: visible;
//     z-index: 2000;
//     margin-bottom: -5px;
//     max-width: 20rem;
//     box-sizing: border-box;
//   }
// }

.sheet-tab {
  min-width: 11rem;
  max-width: 20rem;
  margin: 20px 10px 0 0;
  align-items: center;
  border: solid $color-grey-dg;
  border-width: 1px 1px 0 1px;
  border-radius: 8px 8px 0 0 !important;
  background-color: $color-white;
  font-size: 15px;
  font-weight: $typography-font-weight-lighter;
  color: $color-text;
  padding: 5px 10px 5px 10px;
  display: flex;

  &:focus {
    outline: none;
  }

  &.active-tab {
    font-weight: $typography-font-weight-bolder;
    border-width: 2px 2px 0px 2px;
    margin-bottom: -2px;
    z-index: 3;

    &.disabled-tab {
      border-width: 2px 2px 0px 2px;
      border-bottom-color: $color-yellow-dg-light;
      background-color: $color-yellow-dg-light;
    }
  }

  .error {
    order: 1;
    display: flex;
    color: $color-orange-dg;
  }
  .sheet-name {
    order: 2;
    overflow: hidden;
    margin-left: 0.625rem;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 18rem;
    display: block;
  }
  .disable-tab {
    order: 3;
    display: flex;
    margin-left: auto;
    padding-left: 1rem;
  }
  .validated-tab {
    order: 3;
    display: flex;
    margin-left: auto;
    padding-left: 1rem;
    color: $color-green-dg;
  }
}

.tutorial-highlight-tabs {
  z-index: 2000 !important;
  position: relative;
  background-color: $color-white !important;
  border: solid $color-red-dg 1px !important;
}

.sheet-error-banner {
  width: 100%;
  background-color: $color-red-dg-light;
  display: flex;
  padding: 0.5em 0.25em;
  font-weight: $typography-font-weight-black;
  font-size: 1.2em;
  display: flex;
  flex-wrap: nowrap;
  align-content: center;
  border: solid 2px #e8e9eb;
  height: 51px;

  &.disabled-banner {
    background-color: $color-yellow-dg-light;
  }

  .error-icon {
    color: $color-red-dg;
    order: 1;
    align-self: center;
    justify-self: center;
    margin: 0 1rem;

    &.disabled-icon {
      color: $color-yellow-dg;
    }

    :nth-child(1) {
      font-size: 1.5rem;
    }
    :nth-child(2) {
      font-size: 0.75rem;
    }
  }
  .error-header {
    order: 2;
    color: $color-grey-900;
    align-self: center;
  }
}

.sheet-error-body {
  width: 100%;
  background-color: #e5e5e5;
  display: flex;
  font-weight: $typography-font-weight-black;
  font-size: 1.2em;
  display: flex;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  border: solid 2px #e8e9eb;

  .error-message {
    background-color: $color-white;
    padding: 3rem;
    margin: 2rem;
    border-radius: 1rem;

    button {
      font-size: 1em;
      font-weight: $typography-font-weight-boldest;
    }
  }
}

.modal-container {
  width: 100%;
  height: 70px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.modal-footer {
  button {
    margin-left: 1em;
  }
}

.btn-container {
  display: flex;
  justify-content: center;
  button {
    display: flex;
    margin: 8px;
  }
}

.btn-link {
  border: none;
  background-color: inherit;
  text-decoration: underline;
}

.modal-on-top {
  z-index: 2001;
}

.loading-placeholder {
  min-height: 20em;
  background-color: $color-white;
  display: flex;
  flex: 1 1 1em;
  align-content: center;
  justify-content: center;

  .loading-spinner {
    margin: auto !important;
  }
}
