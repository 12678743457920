@import "scss/vars.scss";

.metrics-overview-main {
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    max-width: 290px;
    min-height: 100%;
    margin-right: 16px;

    h1 {
        margin: 0;
        color: $color-text;
        font-weight: $typography-font-weight-normal;
        font-size: 40px;
    }

    p {
        color: $color-grey-900;
        font-size: 15px;
        line-height: 30px;
    }

    &-details {
        display: inline-flex;
    }

    &-btn {
        @include button-reset;
        margin-top: 15px;
        padding: 5px 15px;
        color: $color-white;
        font-weight: $typography-font-weight-bolder;
        font-size: 15px;
        line-height: 30px;
        background-color: $color-green-dg;
        border: 1px solid $color-green-2;
        border-radius: 6px;
        box-shadow: 0px 2px 3px rgba(32, 192, 148, 0.2);
        white-space: nowrap;

        &:not(:first-child) {
          margin-left: 1em;
        }
    }
}

@media (max-width: 1300px) {
    .metrics-overview-main {
        width: 100%;
        max-width: none;
        margin-right: 0;
    }
}
