@import 'scss/vars.scss';

@mixin _field-label {
  color: $color-text;
  font-size: 1.25em;
  font-weight: $typography-font-weight-bolder;
}

.data-job-panel-details-block .block-content > form {
  .form-group {
    margin-bottom: 0;

    .form-label {
      @include _field-label;
    }

    textarea:hover:read-only,
    input:hover:read-only,
    styled-multiselect-container > div > div,
    styled-multiselect-container-invalid,
    > div > div {
      background-color: inherit;
    }

    > input,
    > textarea,
    > .form-control-plaintext,
    > .styled-multiselect-container,
    > .user-display,
    > .job-link-modal-search-input,
    > .distributor-job-links,
    > .ml-auto .bai-due-date-picker-value-input__control {
      font-size: 1.25em;
    }
  }
  > section {
    &:not(:first-child) {
      margin-top: 2em;
    }

    &.title-section {
      @include flex;

      .title-block {
        flex: 1 1 auto;

        .label-wrap {
          @include flex;
          align-items: flex-end;

          > .label {
            @include _field-label;
          }

          > .last-updated {
            margin-left: 0.5em;
            margin-bottom: 0.3em;
            line-height: 1;
            color: $color-grey-550;
          }
        }

        .title {
          margin-top: 0.5em;

          .input-field-name input {
            font-size: 1.5em;
            font-weight: $typography-font-weight-bolder;
          }
        }
      }

      .actions-block {
        margin-left: 2em;
      }
    }

    &.description-section {
      .data-job-description {
        max-height: 14.25em;
        overflow-y: auto;
      }
      .form-control-plaintext {
        max-height: 14.25em;
        overflow-y: auto;
        border: 1px solid $color-grey-dg;
        border-radius: 0.5em;
        padding: 0.375rem 0.75rem;
        white-space: break-spaces;
      }
    }

    &.type-subtype-section {
      @include flex;
      align-items: stretch;
      justify-content: space-between;

      > section {
        justify-content: space-between;
        width: calc(100% / 2 - 0.75em);
      }

      .subtype-textarea {
        resize: none;
      }
    }

    &.links-priority-section {
      @include flex;
      align-items: stretch;
      justify-content: space-between;

      > section {
        justify-content: space-between;
        width: calc(100% / 2 - 0.75em);

        &.links-block {
          .job-link-modal-search-input {
            width: auto;
            height: auto;
            margin: 0 0 1em 0;
          }
        }

        &.priority-block {
          .input-field-priority .form-control-plaintext {
            .priority {
              display: inline-block;
              width: 10em;
              padding: 0.15em 0.5em;
              color: $color-grey-500;
              font-weight: $typography-font-weight-bolder;
              text-align: center;
              text-transform: uppercase;
              text-shadow: 0 0 1px $color-white, 0 0 1px $color-white, 0 0 1px $color-white;
              background: $color-grey-50;
              border-radius: 0.5em;

              & {
                &.priority-1 {
                  color: change-color($color-green-dg, $lightness: 40%);
                  background: change-color($color-green-dg, $lightness: 90%);
                }
                &.priority-2 {
                  color: change-color($color-yellow-dg, $lightness: 40%);
                  background: change-color($color-yellow-dg, $lightness: 90%);
                }
                &.priority-3 {
                  color: change-color($color-red-2, $lightness: 40%);
                  background: change-color($color-red-2, $lightness: 90%);
                }
              }
            }
          }
        }
      }
    }

    &.requestor-assignee-section {
      display: flex;
      justify-content: space-between;

      .input-field-created-at .form-control-plaintext,
      .input-field-due-date .form-control-plaintext {
        font-weight: $typography-font-weight-bolder;
      }

      .requestor-block,
      .assignee-block {
        display: flex;
        width: 50%;
        margin-right: 0.75em;

        .company-name {
          margin-left: 1em;
          max-width: 20em;
          word-wrap: break-word;
        }

        .due-date-subblock {
          margin-left: 1em;
        }
      }

      .assignee-block {
        margin-left: 2em;
      }

      .requestor-block {
        border-right: 2px solid $color-grey-300;
        .created-at {
          margin-left: 2em;
        }
      }
      .requestor-name,
      .assignee-name {
        flex: 0 1 33%;
        word-wrap: break-word;
      }

      .creator-company {
        margin-left: 3em;
      }

      .input-field-requestor,
      .input-field-assignee {
        > .user-display {
          margin-top: 0.4em;
        }
      }

      .requestor-logo-subblock,
      .assignee-logo-subblock {
        display: flex 1 1 33%;
      }

      .due-date-space {
        width: 50%;
      }
    }

    &.actions-section {
      @include flex;
      justify-content: flex-end;
      align-items: center;

      > button {
        &:not(:first-child) {
          margin-left: 0.75em;
        }
      }
    }
  }
  .due-date-msg-container {
    display: flex;
    width: 100%;
    .due-date-msg {
      width: 16%;
      margin-left: auto;
      font-size: 0.8rem;
    }
  }
}
