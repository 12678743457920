@import "scss/vars.scss";

.small-progress-bar {
  flex-wrap: nowrap;
  justify-content: space-between;

  .small-progress-bar-section {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    flex-wrap: nowrap;
    align-items: flex-start;
    justify-content: center;
    overflow: hidden;
    white-space: nowrap;
    text-align: center;
  }

  .small-progress-bar-done {
    width: 100%;
    height: 5px;
    background-color: $color-green-dg;
  }

  .small-progress-bar-todo {
    width: 100%;
    height: 5px;
    background-color: $color-grey-550;
  }
  .small-progress-label {
    &-current {
      margin-top: 0.7rem;
      padding-right: 10rem;
      font-weight: $typography-font-weight-boldest;
    }

    &-todo {
      margin-top: 0.7rem;
      padding-right: 10rem;
      color: $color-grey-550;
    }
  }
}

.view-data-job-create .small-progress-bar {
  margin: 30px 0;
}
