@import 'scss/vars.scss';

.bot-form-header-section {
  color: $color-grey-900;
  font-style: normal;
  line-height: normal;

  &-title {
    font-size: 16px;

    font-weight: 700;
    margin-bottom: 4px;
  }

  &-description {
    font-size: 12px;
    font-weight: 400;
    line-height: 1.5;

    p {
      margin-bottom: 0.15rem;

      &:last-child {
        margin-bottom: 0;
      }
    }

    strong {
      font-weight: 700;
    }
  }
}
