@import "scss/vars.scss";

.general-info {
    display: flex;
    flex-direction: column;
    width: 100%;
    color: $color-text;
    background-color: $color-white;
    font-size: 15px;
    border-radius: 8px;
    padding: 10px 20px 20px 20px;
    box-shadow: 0 1px 5px 0 rgba(30, 44, 71, 0.12);
}

.general-info-assets {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-wrap: wrap;

    &-carousel-container {
        display: flex;
    }

    &-carousel {
        width: 290px;
        text-align: center;

        &-caption {
            font-size: 13px;
            color: $color-text;
            padding: 10px;
        }

        &-preview {
            display: flex;
            flex-direction: column;
            margin-right: 20px;
        }
        &-preview__card {
            @include flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            border: 1px solid $color-grey-dg;
            border-radius: 4px;
            margin-bottom: 8px;
            height: 70px;
            text-align: center;
            width: 70px;

            &--active {
                border-color: $color-orange-dg;
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: contain;
            }

            svg {
              font-size: 2.5em;
              color: $color-grey-200;
            }
        }
    }

    .carousel-inner {
        width: 250px;
        border-radius: 6px;
        height: 250px;
        margin-left: auto;
        margin-right: auto;
    }

    .carousel-item {
        height: 250px;

        .general-info-assets-carousel-img-wrapper {
            display: flex;
            height: 100%;
            align-items: center;
            justify-content: center;
            text-align: center;
        }

        img {
            border-radius: 6px;
            width: 100%;
            height: 100%;
            object-fit: contain;
        }

        svg {
          font-size: 5.5em;
          color: $color-grey-200;
        }
    }

    .carousel-control-prev,
    .carousel-control-next {
        color: $color-grey-900;
        width: 3%;
        font-size: 10px;
    }
}

.general-info-body-info {
    flex: 3 1 100%;

    .general-info-body-info-row {
        display: flex;
        flex-direction: row;
        margin: 10px 0;
        line-height: 30px;
        flex-wrap: wrap;

        &:first-child {
            margin-top: 0;
        }

        .general-info-body-info-col__label {
            flex: 1;
            font-weight: $typography-font-weight-bolder;
        }

        .general-info-body-info-col__value {
            display: flex;
            flex: 3;

            span {
                cursor: pointer;
                color: $color-grey-550;
            }
            svg {
                margin-left: 3px;
                color: $color-grey-900;
            }
            &--description {
                flex-direction: column;
            }

            &--logo {
                display: flex;
                align-items: center;
                text-align: center;
                height: 28px;
                border-radius: 50%;
                width: 28px;
                background-color: $color-white;
                border: 1px solid $color-grey-dg;
                margin-right: 10px;

                img {
                    border-radius: 50%;
                    display: block;
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                }
            }

            .show-more {
                display: inline-flex;
                align-items: center;
                span {
                    margin-left: 7px;
                    font-size: 10px;
                }
            }
        }
    }
}

.general-info-body {
    display: flex;
    flex-direction: row;
}

.general-info-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
    color: $color-text;

    > h1 {
        margin: 0;
        font-size: 18px;
        font-weight: $typography-font-weight-bolder;
    }

    &-date {
        border: 1px solid $color-grey-dg;
        border-radius: 6px;
        background-color: $color-white;
        padding: 8px 15px;
        box-shadow: 0 1px 3px 0 rgba(223, 225, 228, 0.5);
        span {
            color: $color-grey-550;
        }
    }
}
