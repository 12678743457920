@import 'scss/vars.scss';

$_wrap-class: 'data-job-panel';
$_block-margin: 2rem;
$_block-padding: 1.5rem;

.#{$_wrap-class} {
  color: $color-text;
  font-family: 'Inter', sans-serif;

  > .panel-block {
    > .block-title {
      font-size: 1.5em;
      font-weight: $typography-font-weight-bolder;

      .loadingspinner {
        display: flex;
        font-size: 1em;
        opacity: 0.5;
      }

      + .block-content {
        margin-top: 1.5rem;
      }
    }
  }

  .job-not-found {
    text-align: center;
  }

  .form-control-plaintext,
  .label {
    color: $color-text;
    font-family: 'Inter', sans-serif;
  }

  .#{$_wrap-class} {
    // Title section
    &-title-block {
      .block-content {
        @include flex;
        align-items: center;
        margin-top: $_block-margin;

        .title {
          flex: 1 1 auto;
          font-size: 2em;
          font-weight: $typography-font-weight-bolder;
        }

        .actions {
          margin-left: $_block-margin;

          .data-job-panel-status-selector {
            width: 18em;

            .selected-value-wrapper {
              .label {
                color: $color-text;
              }
            }
          }
        }
      }
    }

    &-status-progress-block {
      margin-top: $_block-margin;
    }

    &-details-block {
      @include block;
      margin-top: $_block-margin;
      padding: $_block-padding;
    }

    &-files-block {
      margin-top: $_block-margin;
    }

    &-comments-block {
      @include block;
      margin-top: $_block-margin;
      padding: $_block-padding;

      .loadingspinner {
        display: flex;
        font-size: 1.5em;
        opacity: 0.5;
      }
    }

    &-history-block {
      @include block;
      margin-top: $_block-margin;
      padding: $_block-padding;
      .block-title {
        @include flex;
        align-items: center;

        .title {
          flex: 1 1 auto;
        }

        .actions {
          margin-left: $_block-margin;

          .data-job-panel-status-selector {
            width: 12em;

            .selected-value-wrapper {
              .label {
                color: $color-text;
              }
            }
          }
        }
      }
      .block-content {
        // TODO: Wrapper class of component

        .history-items {
          @include unstyled-list;
          .history-item-wrap {
            &:not(:first-child)::before {
              content: '';
              display: block;
              margin: 0.5rem 1.45rem;
              width: 0.1rem;
              height: 2rem;
              background: $color-grey-dg;
            }

            .history-item {
              @include flex;

              .info-block {
                margin-left: 1em;

                .action-text-wrap {
                  word-break: break-word;
                  .user-name {
                    font-weight: $typography-font-weight-boldest;
                  }
                }

                .action-date {
                  font-size: 0.85;
                  color: $color-grey-550;
                }
              }
            }
          }
        }
      }

      &:not(.expanded) .block-content {
        display: none;
      }
    }
  }
}
