@import "scss/vars.scss";
.search-bar-with-dropdown-template {
  width: 100%;
  display: inline-flex;
  margin-top: auto;
  margin-bottom: auto;
  &-icon-wrapper-icon {
    background-color: $color-white;
    box-shadow: 0 0 1px $color-grey-300;
    border-color: $color-grey-300;
    border-width: 0.1rem;
    border-radius: 0.5rem;
    border-style: solid;
  }
  &:hover {
    box-shadow: 0 0 1px $color-grey-400;
    border-radius: 0.5rem;
    border-color: $color-grey-400;
  }
  &:focus-within {
    border-width: 0.1rem;
    border-radius: 0.5rem;
    box-shadow: 0 0 3px $color-orange-dg;
    border-color: $color-orange-dg;
  }
}
.search-bar-with-dropdown-template-invalid {
  .invalid-feedback {
    display: block;
    color: $color-red-dg;
  }
}

.styled-search-bar-container {
  border-color: $color-grey-300;
  border-width: 0.1rem;
  border-radius: 0.5rem;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-style: solid;
  margin: auto;
  height: 100%;

  &:focus-within {
    border-width: 0.1rem;
    border-radius: 0.5rem;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    box-shadow: 0 0 3px $color-orange-dg;
    border-color: $color-orange-dg;
  }
}
.styled-search-bar-container-invalid {
  box-shadow: 0 0 1px $color-red-dg;
  border-color: $color-red-dg;
  border-width: 0.1rem;
  border-radius: 0.5rem;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-style: solid;
  margin: auto;
  &:hover {
    box-shadow: 0 0 1px $color-grey-400;
    border-radius: 0.5rem;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-color: $color-grey-400;
  }
  &:focus-within {
    border-width: 0.1rem;
    border-radius: 0.5rem;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    box-shadow: 0 0 3px $color-red-dg;
    border-color: $color-red-dg;
  }
}
