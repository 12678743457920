@import 'scss/vars.scss';

.maintenance-wrapper {
  text-align: left;
  padding: -1rem;

  .maintenance-card{
    display: flex;
    flex-direction: column;
    width: 400px;
    margin: 8px;
    padding: 1.5rem 1.5rem;
    color: $color-text;
    text-align: center;
    background-color: $color-white;
    border: 1px solid $color-grey-dg;
    border-radius: 8px;
    box-shadow: 0 2px 6px 0 rgba(30, 44, 71, 0.2);
  }

  .maintenance-branding {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: auto;
    margin-bottom: 16px;

    a {
      flex: 1;
    }

    img {
      height: 72px;
    }
  }

  .maintenance-heading {
    color: $color-grey-900;
    font-weight: $typography-font-weight-bolder;
    font-size: 28px;
  }

  .maintenance-subheading {
    font-weight: $typography-font-weight-bolder;
    color: $color-text;
    font-size: 14px;
    opacity: 0.75;
  }

  .maintenance-form {
    margin: 24px;
    background-color:$color-grey-50;
    border-radius: 6px;
    padding-bottom: 24px;
  }

  .maintenance-group {
    display: flex;
    flex-direction: column;
    margin-top: 30px;
    padding: 1rem;
    text-align: left;
    margin: 0;
  }

  .maintenance-form--submit {
    margin-bottom: 0;
    color: $color-white;
    padding: 0.5rem 40px;
    font-size: 14px;
    font-weight: $typography-font-weight-boldest;
    background-color: $color-orange-dg;
    border: 1px solid $color-orange-dg;
    border-radius: 6px;
    box-shadow: 0 2px 3px 0 $color-orange-dg;
  }

  .maintenance-form--text {
    font-weight: $typography-font-weight-bold;
    margin-bottom: 0;
    text-align:center;
    margin:auto;
  }

  p {
    margin: 0;
  }
}
