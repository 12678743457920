@import 'scss/vars.scss';

.reclassify-from-actions {
  display: flex;
  flex-direction: column;
  flex-grow: 4;
  padding: 0.5em;

  .from-radio {
    padding: 0.5em 0;

    label {
      display: inline-flex;
      align-items: baseline;
      font-size: 1.25rem;
      margin-right: 1rem;

      input {
        margin-right: 0.25rem;
      }
    }
  }
}
