@import 'scss/vars.scss';

.pagination-button-wrapper {
  display: flex;
  justify-content: center;
  font-family: Inter;
  background-color: $color-dg-input;
  border-top-color: $color-grey-dg;
  width: 100%;
  height: 30px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.05);

  .button-paginate {
    padding: 10px 15px;
    white-space: nowrap;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    color: $color-text;
    border: 1px solid rgb(223, 225, 228);
    background-color: $color-white;
    cursor: pointer;
    margin-bottom: 0.25em;
    font-weight: 300;
    &:hover {
      box-shadow: rgba(223, 225, 228, 0.5) 0px 1px 3px 0px;
    }
  }
}
.ingestion-footer {
  display: inline-flex;
  align-items: center;
  font-family: Inter;
  // position: absolute;
  // bottom: 3rem;
  padding: 20px;
  background-color: $color-dg-input;
  border-width: 1px 0 0 0;
  border-top-color: $color-grey-dg;
  border-radius: 0 0 8px 8px;
  width: 100%;
  height: 60px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.05);
  // margin-top: 88.5vh;

  .footer-content {
    display: flex;
    width: 100%;
    justify-content: space-around;

    .border-box {
      box-sizing: border-box;
      margin-left: 14px;
      align-items: center;
      height: 40px;
      min-width: 361px;
      border: 1px solid $color-grey-dg;
      border-radius: 8px;
      background-color: $color-white;
      box-shadow: 0 1px 3px 0 rgba(223, 225, 228, 0.5);
      display: inline-flex;
      font-size: 15px;
      color: $color-text;

      .icon {
        margin: 0 15px 0 25px;
      }

      .job-link {
        text-decoration: underline;
        margin: 0 15px 0 20px;
      }
    }

    .button-filter {
      padding: 10px 15px;
      white-space: nowrap;
      align-items: center;
      justify-content: center;
      border-radius: 6px;
      color: $color-text;
      border: 1px solid rgb(223, 225, 228);
      background-color: $color-white;
      cursor: pointer;
      margin-bottom: 0.25em;
      font-weight: 300;
      &:hover {
        box-shadow: rgba(223, 225, 228, 0.5) 0px 1px 3px 0px;
      }
    }

    .button-download {
      padding: 8px 15px;
      white-space: nowrap;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .button-ingestion {
      padding: 10px 15px;
      white-space: nowrap;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 6px;
      color: $color-text;
      border: 1px solid rgb(223, 225, 228);
      background-color: $color-white;
      cursor: pointer;
      margin-bottom: 0.25em;
      font-weight: 300;
      &:hover {
        box-shadow: rgba(223, 225, 228, 0.5) 0px 1px 3px 0px;
      }
    }
  }
}
