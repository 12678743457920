.toggle-switch {
  > .label {
    font-weight: $typography-font-weight-bolder;
    margin-bottom: 1em;
  }

  .toggle-switch-input {
    @include flex;
    align-items: center;
    border: 1px solid $color-grey-300;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.05);
    max-width: fit-content;
    border-radius: 1.5em;
    overflow: hidden;

    .toggle-switch-option {
      display: flex;
      align-items: center;
      box-sizing: border-box;
      padding: 5px 12px;
      color: $color-grey-500;
      cursor: pointer;
      border-radius: 1.5em;

      &:first-child {
        border-right: 2px solid transparent;
      }

      &:last-child {
        border-left: 2px solid transparent;
      }

      .icon {
        font-size: 0.75em;
      }

      .label {
        margin-left: 0.5em;
      }

      &.active {
        font-weight: $typography-font-weight-bold;
        color: $typography-font-color-normal;
        border-color: $color-grey-300;

        box-shadow: 0 0 3px rgba(0, 0, 0, 0.05);

        &:first-child .icon {
          color: $color-green-dg;
        }

        &:last-child .icon {
          color: $color-red-dg;
        }
      }
    }
  }

  &.disabled {
    .toggle-switch-input {
      .toggle-switch-option {
        opacity: 0.5;
        cursor: default;
      }
    }
  }
}
