@import 'scss/vars.scss';

.cds-bot-modal-panel {
  .modal-footer {
    background-color: $color-white;
    margin-top: 20px;

    p {
      color: $color-red-errors-negative;
      font-weight: 400;
      font-size: 0.85rem;
    }
    border-top: none;

    .btn-cancel {
      color: $color-blue-dg;
      text-decoration: underline;
      text-underline-offset: 3px;
      font-weight: $typography-font-weight-bold;
    }

    .btn-run {
      padding: 0 2rem;
    }

    .btn-disabled {
      opacity: 0.5;
      cursor: default;
    }
  }
}

.tab-title {
  display: flex;
  gap: 4px;

  .invalid {
    color: red;
    margin-left: 0.5rem;
    margin-top: 0;
  }
}

.title-bot-name {
  color: $color-grey-900;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 4px;
}

.table-wrapper {
  @include flex;
  width: 100%;
  .drag-table {
    width: 100%;
    padding: 10px 0 0 0;
  }
}
.content-guidelines {
  display: flex;
  gap: 10px;
  > section {
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 0;
  }
}
.content-formatting {
  display: flex;
  gap: 10px;
  > section {
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 0;
  }
}

section {
  &.header-mapping {
    > .content {
      .header-mapping-table {
        width: 100%;
      }
    }
  }

  &.header-selection {
    > .content {
      display: block;
    }

    .header-selector {
      width: 100%;
      vertical-align: middle;

      .styled-multiselect-container {
        width: 100%;
        margin-top: 0.5em;
      }
    }
  }
}
