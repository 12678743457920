@import 'scss/vars.scss';

$alert-bg: #fff3cd;
$alert-text: #856404;

$alert-border: mix($alert-bg, white, 40%);
$alert-header-bg: darken($alert-bg, 10%);

.ids-trial-banner-card {
  border: 1px solid $alert-border;
  border-radius: 0.5em;
  margin-bottom: 2em;

  .card-header {
    background-color: $alert-header-bg;
    color: $alert-text;

    .ids-trial-banner-header {
      @include flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: row;

      .ids-trial-banner-title {
        font-size: 1.5em;
        font-weight: 600;
        margin-bottom: 0.25em;
      }
    }
  }

  .card-text {
    font-weight: bold;
  }

  .card-body {
    background-color: $alert-bg;

    @include flex;

    align-items: center;
    justify-content: space-between;

    .ids-trial-banner-content {
      @include flex;
      align-items: flex-start;
      justify-content: space-between;
      flex-direction: column;
      flex-wrap: wrap;
      color: $alert-text;

      > ul {
        padding-inline-start: 1.5em;
      }

      .note {
        font-size: 0.85em;
        font-weight: 400;
        margin-left: 0.5em;
        margin-bottom: 0.25em;
      }
    }

    .ids-trial-banner-action {
      @include flex;
      align-items: flex-start;
      justify-content: space-between;
      flex-direction: column;
      flex-wrap: wrap;
    }
  }

  .accordion-toggle {
    color: $alert-text;
  }
}
