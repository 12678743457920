.view-notifications {
  .notifications-wrapper {
    display: flex;
    flex-direction: column;
    width: 800px;
    margin: 20px auto;
    padding: 20px 20px;
    color: $color-text;
    background-color: $color-white;
    border-radius: 6px;
    box-shadow: 0 1px 5px 0 rgba(30, 44, 71, 0.12);

    .notifications-list {
      @include unstyled-list;
      margin-bottom: 20px;
      &-item-wrapper {
        @include flex;
        flex-direction: row;
        vertical-align: middle;
      }
      .notif-image-wrapper {
        margin-top: auto;
        margin-bottom: auto;
        .notif-image {
          display: block;
          object-fit: contain;
          width: 3rem;
          height: 3rem;
          box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 1);
          border-radius: 50%;

          &.placeholder {
            opacity: 0.25;
          }
        }
      }

      li {
        padding: 15px 18px;
        border-bottom: 1px solid $color-grey-dg-light;

        &:last-child {
          border-bottom: none;
        }
      }

      &-item {
        position: relative;
        display: flex;
        flex-direction: column;
        cursor: pointer;

        &-viewed-indicator {
          position: absolute;
          top: 0;
          left: 0;
          width: 8px;
          height: 8px;
          margin-top: 22px;
          background-color: $color-yellow-dg;
          border-radius: 50%;
        }

        &__title {
          font-weight: $typography-font-weight-bolder;
          font-size: 14px;
        }

        &__details {
          color: $color-grey-900;
        }

        &__date {
          margin-top: 10px;
          color: $color-grey-550;
        }
      }
    }

    .notifications-header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-bottom: 20px;

      h1 {
        margin: 0;
        color: $color-text;
        font-weight: $typography-font-weight-bolder;
        font-size: 22px;
      }

      &-mark-read {
        @include button-reset;
        color: $color-blue-dg;

        svg {
          margin-right: 5px;
        }

        &:hover {
          color: $color-blue-dark;
        }
      }
    }

    .notifications-load-more {
      @include button-reset;
      width: 200px;
      margin-right: auto;
      margin-left: auto;
      padding: 10px 10px;
      color: $color-white;
      font-weight: $typography-font-weight-bolder;
      font-size: 15px;
      background-color: $color-blue-dg;
      border-radius: 6px;
      box-shadow: 0 1px 5px 0 rgba(30, 44, 71, 0.12);
    }
  }
}
@include ie11-hack {
  .notif-image-wrapper {
    display: flex;
    height: 3rem;
    box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 1);
    align-items: center;
    border-radius: 50%;
    overflow: hidden;
    .notif-image {
      padding-top: 0.1rem;
      margin: auto;
      border-radius: 0;
      box-shadow: none;
      height: auto;
    }
  }
}
