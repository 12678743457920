@import "scss/vars.scss";

.modal-panel {
  .modal-panel-body-text {
    overflow-wrap: break-word;
  }
  .modal-footer {
    background: $color-grey-200;
  }
}
