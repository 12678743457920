.cursor-pointer {
  cursor: pointer;
}

.react-bootstrap-table {
  padding: 0;
  overflow: auto;
  line-height: 1.2;
  background: $color-white;
  border: 1px solid $color-grey-200;
  border-radius: 0.5em;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.05);

  table {
    width: 100%;
    margin-bottom: 0;
    table-layout: fixed;
  }

  thead {
    border-bottom: 1px solid $color-grey-400;
    tr {
      th {
        padding: 0.5em 0.25em;
        vertical-align: middle;
        border: none;
        &:first-child {
          padding-left: 0.75em;
        }
        &:last-child {
          padding-right: 0.75em;
        }
        .th-wrap {
          @include flex;
          align-items: center;
          .label {
            overflow: hidden;
            color: $color-grey-700;
            font-weight: $typography-font-weight-normal;
            text-overflow: ellipsis;
          }
          .sort {
            display: inline-block;
            margin-top: -0.25em;
            margin-left: 0.5em;
            line-height: 1;
            .sort-caret {
              @include flex;
              flex-direction: column;
              color: $color-grey-300;
              font-size: 0.75em;
              line-height: 1;
              .icon {
                flex: 0 0 0.5em;
                height: 0.6em;
              }

              &.asc .icon.asc {
                color: $color-grey-700;
              }

              &.desc .icon.desc {
                color: $color-grey-700;
              }
            }
          }
        }
      }
    }
  }
  tbody {
    tr {
      &:not(:last-child) {
        border-bottom: 1px solid $color-grey-200;
      }

      &:hover {
        background: $color-grey-25;
      }
      td {
        padding: 0.5em 0.25em;
        vertical-align: middle;
        border: none;
        &:first-child {
          padding-left: 0.75em;
        }
        &:last-child {
          padding-right: 0.75em;
        }
      }
    }
  }
}

.react-bootstrap-table-pagination {
  margin-top: 1em;

  .react-bootstrap-table-page-btns-ul {
    border-radius: 0.1em;
    .page-item {
      .page-link {
        display: inline-block;
        min-width: 2.5em;
        padding: 0.75em 0.5em;
        color: $color-grey-550;
        line-height: 1;
        text-align: center;
        vertical-align: middle;
        background: transparent;
        border: none;
        border-radius: 0.5em;
        box-shadow: none;
      }
      &.active,
      &:hover {
        .page-link {
          color: $typography-font-color-normal;
          background: $color-white;
          box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
        }
      }
      &:not(:first-child) {
        margin-left: 0.5em;
      }
    }
  }
}
