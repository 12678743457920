@import "scss/vars.scss";

.bai-cards-grid {
  @include unstyled-list;
  @include flex;

  flex-wrap: wrap;
  justify-content: center;
  width: calc(100% + 2px);
  margin: -2em 0 0 -2em;

  li {
    margin: 2em 0 0 2em;
  }
}
