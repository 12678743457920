@import "scss/vars.scss";

.metrics-table {
    display: flex;
    flex-direction: column;
    margin-bottom: 35px;

    &-header {
        padding: 10px 15px;
        color: $color-text;
        font-weight: $typography-font-weight-bolder;
        font-size: 13px;
        background-color: $color-white;
        border: 1px solid $color-grey-dg;
        border-bottom: 0;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
    }

    &-search {
        position: relative;
        display: inline-flex;
        margin-bottom: 12px;

        &-input {
            display: inline-flex;
            width: 600px;
            height: 40px;
            padding: 10px 15px;
            font-size: 13px;
            background-color: $color-white;
            border: 1px solid $color-grey-dg;
            border-top-left-radius: 8px;
            border-bottom-left-radius: 8px;

            &::placeholder {
                color: $color-grey-550;
            }
        }

        &-btn {
            padding: 0 10px;
            color: $color-white;
            background-color: $color-green-dg;
            border: 1px solid $color-green-2;
            border-top-right-radius: 8px;
            border-bottom-right-radius: 8px;

            &:hover {
                background-color: $color-green-2;
            }
        }

        input {
            &:active,
            :focus,
            :not(:placeholder-shown) {
                background-color: $color-white;
            }
        }

        > svg:not(:placeholder-shown) {
            background-color: transparent;
        }
    }

    &-main-header {
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        justify-content: space-between;
        color: $color-text;

        label {
            margin: 0;
        }

        &-tab {
            @include button-reset;
            width: 180px;
            margin-right: 10px;
            padding: 10px 15px;
            font-size: 15px;
            text-align: left;
            background-color: $color-white;
            border: 1px solid $color-grey-dg;
            border-bottom: 0;
            border-radius: 6px 6px 0 0;
            box-shadow: 0px 1px 3px rgba(223, 225, 228, 0.5);

            &:focus,
            &:active {
                outline: none;
            }

            &--active {
                color: $color-white;
                background-color: $color-green-dg;
                box-shadow: 0px 1px 3px rgba(223, 225, 228, 0.5);
            }
        }
    }

    &-footer {
        @extend .metrics-table-header;
        font-weight: $typography-font-weight-normal;
        border: 1px solid $color-grey-dg;
        border-top: 0;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        border-bottom-right-radius: 8px;
        border-bottom-left-radius: 8px;
    }

    &-expand {
        @include button-reset;
        align-self: flex-end;
        margin-top: 10px;
        padding: 5px 15px;
        font-weight: $typography-font-weight-bolder;
        font-size: 15px;
        line-height: 30px;
        background: $color-white;
        border: 1px solid $color-grey-dg;
        border-radius: 6px;
        box-shadow: 0px 1px 3px rgba(223, 225, 228, 0.5);
    }

    &-user-img {
        display: inline-flex;
        align-items: center;
        overflow: hidden;

        &-name {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;

            &:hover {
                @include break-word-wrap;
                overflow: visible;
                white-space: normal;
            }
        }

        img {
            width: 24px;
            height: 24px;
            margin-right: 10px;
            object-fit: contain;
            border-radius: 50%;
        }
    }

    &-icon {
        &--success {
            color: $color-green-dg;
        }
        &--invalid {
            color: $color-red-dg;
        }
    }

    &--selectable {
        .react-bootstrap-table .table tr {
            cursor: pointer;
        }
    }

    .react-bootstrap-table {
        border: 1px solid $color-grey-dg;
        border-radius: 0;
        box-shadow: none;

        .table {
            table-layout: auto;
        }

        thead tr th:first-child,
        tbody tr td:first-child,
        tfoot tr th:first-child {
            padding-left: 1em;
        }

        thead {
            border-bottom: 1px solid $color-grey-dg;
        }

        th,
        td {
            min-width: 120px;
            padding: 0.75em 0.25em;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;

            &:hover {
                @include break-word-wrap;
                overflow: visible;
                white-space: normal;
            }
        }

        .manufacturer_name {
            min-width: 300px;
            max-width: 300px;
        }

        .manufacturer_short_name {
            min-width: 170px;
        }

        th .th-wrap .label {
            color: $color-grey-550;
            font-size: 13px;
        }

        tbody tr:not(:last-child) {
            border-bottom: 1px solid $color-grey-dg;
        }

        tr {
            color: $color-text;
            font-size: 15px;

            .user {
                display: inline-flex;
                align-items: center;
            }
        }
    }
}
