@import 'scss/vars.scss';

.bot-details {
  width: 100%;
  margin: 30px 0;

  &-info {
    margin-bottom: 20px;

    &__heading {
      margin-top: 0;
      margin-bottom: 15px;
      color: $color-text;
      font-weight: $typography-font-weight-bolder;
      font-size: 18px;
    }

    &__subheading {
      margin-bottom: 0;
      color: $color-text;
      font-size: 15px;
    }
  }

  .bot-category-card {
    margin-bottom: 20px;
    border-radius: 6px;
    box-shadow: 0 1px 5px 0 rgba(30, 44, 71, 0.12);
  }
}
