.draggable-row {
  cursor: default;

  .firstElement {
    display: flex;
    flex-direction: row;
  }

  &.helperContainerClass {
    width: auto;
    border: 1px solid #efefef;
    box-shadow: 0 5px 5px -5px rgba(0, 0, 0, 0.2);
    background-color: rgba(255, 255, 255, 0.9);
    border-radius: 3px;

    &:active {
      cursor: grabbing;
    }

    & > td {
      padding: 5px;
      text-align: left;
      width: 200px;
    }
  }

  .table-actions {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .action-move-row {
      button {
        padding-left: 4px;
        padding-right: 4px;
      }
    }
  }
}

.drag-handler {
  margin-right: 10px;
  padding: 0 5px;
  cursor: grab;
}
