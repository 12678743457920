@import 'scss/vars.scss';

.view-login .login-register {
  .view-login-card {
    width: 600px;
  }
  .reg-form {
    @include flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 1.5em 0 0 0;
    text-align: left;

    .input-field {
      margin-bottom: 1.5rem;
      label {
        margin-bottom: 0.15rem;
        margin-left: 0.5rem;
        color: $color-grey-500;
        font-weight: $typography-font-weight-bold;
      }
      .form-control::placeholder {
        color: $color-grey-400;
      }
      .password-toggle-icon {
        cursor: pointer;
      }
      .invalid-feedback {
        margin: 0.25rem 0.5rem;
      }
      &.input-field-first_name,
      &.input-field-last_name, {
        flex: 0 0 48%;
      }

      &.input-field-email,
      &.input-field-message,{
        flex: 0 0 100%;
      }
    }
  }

  .recaptcha-wrap {
    margin-bottom: 1.5rem;
    .recaptcha-container {
      @include flex;
      justify-content: center;
    }
  }

  .actions {
    margin: 0.5em 0;
    .btn.btn-sign-up {
      padding-left: 4em;
      padding-right: 4em;
    }
  }
}
