@import "scss/vars.scss";

$_wrap-class: "databot-config-panel";
$_block-padding: 1.5em;

@mixin panel-actions {
  margin-top: 1em;
  display: flex;
  justify-content: flex-end;

  &__action {
    @include button-reset;
    padding: 8px 16px;
    font-weight: $typography-font-weight-bolder;
    font-size: 12px;
    background: $color-white;
    border: 1px solid #9aa4ac;
    border-radius: 6px;
    box-shadow: 0px 2px 6px rgba(0, 19, 92, 0.06);

    .icon {
      &:first-child {
        margin-right: 0.5em;
      }
      &:last-child {
        margin-left: 0.5em;
      }
    }

    &--submit {
      color: $color-white;
      background-color: $color-green-dg;
      border-color: transparent;
    }

    &--submit:disabled {
      color: $color-white;
      background-color: $color-grey-500;
      border-color: transparent;
    }

    &:not(:first-child) {
      margin-left: 1.5em;
    }
  }
}

.#{$_wrap-class} {
  margin: 30px 0;

  .bai-value-block {
    border-color: transparent;

    > .value {
      color: $color-text;
    }
  }

  .#{$_wrap-class}-heading-stats {
    .data-items {
      @include unstyled-list;
      @include flex;
      align-items: stretch;

      .data-item {
        flex: 1 1 16em;
        padding: $_block-padding;

        &:first-child {
          flex: 1 1 66%;
        }

        &:not(:first-child) {
          margin-left: 2em;
        }

        .title {
          .label {
            white-space: nowrap;
          }
        }
      }
    }
  }

  .#{$_wrap-class}-title {
    font-size: 2rem;
    color: $color-text;
    font-weight: $typography-font-weight-bold;
    margin: 1.5rem 0 0.5rem;
  }

  .#{$_wrap-class}-subtitle {
    margin: 0 0 1.5rem;
    color: $color-text;
    font-size: 14px;
    line-height: 2.5rem;
  }

  .#{$_wrap-class}-body {
    @include block;
    border-color: transparent;
    padding: $_block-padding;

    .#{$_wrap-class}-body-content {
      min-height: 5em;
    }

    .#{$_wrap-class}-actions {
      @include panel-actions;
    }
  }

  .#{$_wrap-class}-running-message {
    margin-top: 2em;

    .running-message {
      font-size: 1.5em;
      font-weight: $typography-font-weight-bold;
      text-align: center;

      .loading-spinner {
        margin: 0 0 0 0.75em;
      }
    }

    .running-actions {
      margin-top: 0.5em;
      text-align: center;
    }
  }

  .databot-approval-panel-actions {
    @include panel-actions;
    justify-content: flex-start;
    margin-top: 0;
  }
}

@media (max-width: 1000px) {
  .#{$_wrap-class} {
    .#{$_wrap-class}-heading-stats {
      .data-items {
        .data-item {
          min-width: initial;
          .title {
            font-size: 1em;
          }
          .value {
            font-size: 1.5em;
          }
        }
      }
    }

    .#{$_wrap-class}-title {
      font-size: 2rem;
    }
  }
}
