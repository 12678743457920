@import "scss/vars.scss";

.tips-carousel {
  z-index: 0;
  .carousel {
    padding-bottom: 2.5em;
    .carousel-indicators li {
      width: 0.5em;
      height: 0.5em;
      background: $color-grey-400;
      border: none;
      border-radius: 50%;
      opacity: 0.25;
      &.active {
        opacity: 1;
      }
    }

    .bc-carousel-item-wrap {
      position: relative;

      .bc-carousel-item-close {
        position: absolute;
        top: 0.25em;
        right: 0.5em;
        cursor: pointer;
        opacity: 0.25;

        &:hover {
          opacity: 1;
        }
      }
      .bc-carousel-item {
        @include flex;
        padding: 0.75em 1.5em 0.75em 1em;
        background: $color-grey-50;
        border-radius: 0.25em;

        .bc-carousel-item-icon {
          margin-right: 0.75em;
          font-size: 1.25em;
          filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.2));
        }

        .bc-carousel-item-content {
          margin-top: 0.25em;
          .label {
            margin-right: 0.25em;
            color: $color-grey-500;
            font-weight: $typography-font-weight-bolder;
          }
        }
      }
    }
  }
}
