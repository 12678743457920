@import "scss/vars.scss";

.pricing-validation-log {
    margin-top: 2rem;

    .react-bootstrap-table {
        border: 1px solid $color-grey-dg;
        border-radius: 0;
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;
        box-shadow: none;
    
        .table {
          table-layout: auto;
        }
    
        thead tr th:first-child,
        tbody tr td:first-child,
        tfoot tr th:first-child {
          padding-left: 1em;
        }
    
        thead {
          border-bottom: 1px solid $color-grey-dg;
        }
    
        th,
        td {
          min-width: 120px;
          padding: 0.75em 0.25em;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
    
          &:hover {
            @include break-word-wrap;
            white-space: normal;
          }
        }
      }
}
