@import "scss/vars.scss";

.forgot-password-wrapper {
    text-align: left;

    .forgot-password-heading {
        color: $color-grey-900;
        font-weight: $typography-font-weight-bolder;
        font-size: 28px;
    }

    .forgot-password-subheading {
        margin-bottom: 10px;
        opacity: 0.75;
    }

    .view-login-form {
        margin: 0;
    }

    .view-login-submit {
        margin-bottom: 0;
    }

    p {
        margin: 0;
    }
}
