@import 'scss/vars.scss';

.paginator {
  button,
  div {
    margin-right: 0.5em;
    min-width: 40px;
  }

  div {
    display: inline-block;
    font-weight: 400;
    color: $color-text;
    text-align: center;
    vertical-align: middle;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 0.5rem;
    border-color: $color-grey-300;
    box-shadow: 0 2px 3px 0 $color-grey-300;
    background-color: $color-white;
  }

  button:hover,
  button:focus {
    background-color: $color-white;
    box-shadow: 0 2px 5px $color-grey-500;
  }

  button:disabled {
    color: $color-grey-500;
    &:hover,
    &:focus {
      color: $color-grey-500;
      background-color: $color-grey-300;
      box-shadow: initial;
    }
  }
}
