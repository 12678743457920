@import 'scss/vars.scss';

.view-img-bot-engine-about {
  display: flex;
  flex-direction: column;

  > .title {
    margin-bottom: 1.7em;
  }
  .img-bot-engine-about-section {
    @include block;
    padding: 1em;
    &:not(:last-child) {
      margin-bottom: 2em;
    }
    .title {
      font-size: 1.5em;
      margin-bottom: 1em;
      font-weight: 600;
    }

    .video-wrapper {
      .title {
        font-size: 1.3em;
        margin-bottom: 1em;
        font-weight: 550;
      }
    }

    .youtube-container {
      aspect-ratio: 16/9;
      border: 1px solid #ccc;
      border-radius: 16px;
      overflow: hidden;
    }

    &.what-is {
      @include flex;
      flex-direction: column;
      align-items: center;

      .content {
        width: 45%;
        min-width: 300px;
      }
    }

    &.hot-questions {
      > .content {
        @include flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-around;
        margin-bottom: 1em;
        gap: 1.5em;
      }
    }

    &.faq {
      .faq-item {
        .card-header {
          @include flex;
          flex-direction: row;
          justify-content: space-between;
          cursor: pointer;
        }
      }
    }
  }
}
