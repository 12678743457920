@import 'scss/vars.scss';

.file-container-result {
  margin-bottom: 16px;
  width: 100%;
  height: 100%;
  padding-bottom: 4px;
  padding-left: 8px;
  border-radius: 8px;
  border-left: 3px $color-green-dg solid;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 6px;
  display: inline-flex;
}

.file-result-type {
  color: $color-grey-900;
  font-size: 14px;
  font-family: Inter;
  font-weight: 700;
  word-wrap: break-word;
}

.file-result-group {
  display: flex;
  flex-wrap: wrap;
  max-width: 100%;
}

.file-container-group {
  min-width: 210px;
  position: relative;
  justify-content: flex-start;
  background-color: $color-white;
  transition: all 0.3s ease;
  padding: 4px;
  border-radius: 4px;
  border: 1px $color-grey-300 solid;
  align-items: flex-start;
  gap: 4px;
  display: inline-flex;
  margin-right: 16px;
  margin-bottom: 10px;
}
