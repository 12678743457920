@import "scss/vars.scss";

.view-img-bot-engine-config-dashboard {
  > .top-actions {
    margin-bottom: 0.5rem;
  }

  > .title {
    @include flex;
    align-items: center;
    justify-content: space-between;

    margin-bottom: 2rem;

    h1 {
      font-size: 2rem;
    }

    .actions {
      .btn {
        padding-left: 1.5rem;
        padding-right: 1.5rem;

        &.btn-details {
          display: none;
        }
      }
    }
  }
}
