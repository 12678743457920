@import 'scss/vars.scss';

.cds-job-modal-panel {
  .cds-trigger-modal-actions {
    margin-top: 2rem;
    text-align: right;

    button {
      &:not(:first-child) {
        margin-left: 1rem;
      }
    }
    p {
      color: $color-red-errors-negative;
      font-weight: $typography-font-weight-normal;
      font-size: 0.85rem;
      margin-bottom: 0;
    }
  }
}
