@import 'scss/vars.scss';

.img-bot-engine-details {
  > .top-actions {
    margin-bottom: 0.5rem;
  }

  > .title {
    @include flex;
    align-items: center;
    justify-content: space-between;

    margin-bottom: 2rem;
    h1 {
      font-size: 2rem;
    }

    .actions {
      .btn {
        padding-left: 1.5rem;
        padding-right: 1.5rem;
        &.btn-details {
          display: none;
        }
        &.btn-qc {
          background-color: $color-yellow-dg;
          border-color: $color-yellow-dg;
          display: block;
          &:hover,
          &:active,
          &:focus {
            border-color: scale-color($color: $color-yellow-dg, $lightness: -10%);
            background: linear-gradient(180deg, $color-yellow-dg 10%, $color-yellow-dg-light 300%);
            box-shadow: 0 1px 3px 0 $color-yellow-dg;
          }
        }
      }
    }
  }

  > section.details-header {
    @include flex;
    align-items: stretch;

    .bai-value-block {
      &:not(:first-child) {
        margin-left: 1em;
      }

      > .title {
        margin-bottom: 1rem;
        .label {
          font-size: 1rem;
        }
      }

      > .value {
        font-size: 1.5rem;
      }

      &.job-id {
        flex: 1 0 8em;
        > .value {
          font-size: 2.5rem;
        }
      }

      &.status {
        flex: 1 0 17em;

        > .value {
          font-size: 1rem;

          .status-block {
            white-space: normal;
            &-phase {
              font-size: 1.25rem;
              font-weight: $typography-font-weight-bolder;
            }

            &-status {
              margin-top: 0.25em;
              display: flex;
              align-items: center;

              .icon {
                margin-right: 0.25em;
                .svg-inline--fa {
                  color: $color-grey-500;
                }

                &.failed .svg-inline--fa {
                  color: $color-red-dg;
                }

                &.rejected .svg-inline--fa {
                  color: $color-red-dg;
                }

                &.completed .svg-inline--fa {
                  color: $color-green-dg;
                }

                &.pending .svg-inline--fa {
                  color: $color-orange-dg;
                }

                &.running .svg-inline--fa {
                  color: $color-blue-dg;
                }

                &.under_review .svg-inline--fa {
                  color: $color-blue-dg;
                }
              }

              &-info {
                display: flex;
                flex-direction: column;

                font-weight: 600;
                line-height: 1.2rem;
                color: $color-grey-500;

                &-progress {
                  margin-left: 0.25rem;
                }
              }

              .tooltip-icon {
                margin-left: 0.25em;
                color: $color-grey-500;
              }
            }
          }
        }
      }
      &.input-file {
        flex: 1 0 auto;
        > .value {
          font-size: 1.25rem;
        }
      }

      &.owner {
        flex: 1 1 20em;
        > .value {
          font-size: 1.25rem;
        }
      }

      &.created-at {
        flex: 1 0 14em;
        > .value {
          font-size: 1.25rem;
        }
      }
    }
  }

  > .assessment-titles-wrap,
  > .assessments-summary-wrap,
  > .assessments-details-wrap {
    @include flex;
    justify-content: space-between;
    align-items: stretch;
    margin-top: 2rem;

    > * {
      flex: 0 0 49%;
    }
  }

  > .assessment-titles-wrap {
    .assessment-title {
      h2 {
        font-size: 1.5rem;
      }
    }
  }

  > .assessments-summary-wrap {
    margin-top: 1rem;
    .assessment-summary {
      @include block;
      @include flex;
      justify-content: space-between;
      align-items: center;
      padding: 1em;
      min-height: 10em;

      .left-side {
        flex: 0 0 40%;
        align-self: flex-start;
        > .title {
          display: none;
          margin-bottom: 1em;
          h2 {
            font-size: 1.5rem;
          }
        }

        .stats-wrap {
          flex: 1 1 auto;
          @include flex;
          justify-content: space-between;

          .stats-text-data {
            .stats-data-items {
              @include unstyled-list;

              .stats-data-item {
                font-size: 1.2em;

                &:not(:first-child) {
                  margin-top: 0.25em;
                }

                > .label {
                  color: $color-grey-500;
                  font-weight: $typography-font-weight-bold;
                }

                > .value-wrap {
                  white-space: nowrap;
                  > .value {
                    font-weight: $typography-font-weight-bolder;
                  }

                  > .percentage-wrap {
                    color: $color-grey-500;
                    font-weight: $typography-font-weight-bolder;

                    .unit {
                      font-size: 0.75em;
                    }
                  }
                }
              }
            }
          }
        }

        &.empty {
          .empty-message {
            font-size: 1.25rem;
            color: $color-grey-500;
            font-weight: $typography-font-weight-bolder;
          }
        }
      }

      .right-side {
        flex: 0 0 60%;
        min-width: 25rem;
        height: 13rem;
      }
    }
  }

  > .assessments-details-wrap {
    .assessment-details {
      > .title {
        margin-bottom: 1em;
        h2 {
          font-size: 1.5rem;
        }
      }

      .stat-bars-list {
        @include unstyled-list;

        .stat-bars-item {
          @include flex;
          align-items: center;

          height: 3em;

          > :not(:first-child) {
            margin-left: 0.5em;
          }

          > .title {
            flex: 0 0 9em;
            line-height: 1.2;
            color: $color-grey-550;
            font-weight: $typography-font-weight-bolder;
          }

          > .info-btn {
            flex: 0 0 1.5em;
            height: 1.5em;
            line-height: 1.2em;
            text-align: center;
            border-radius: 50%;
            border: 1px solid $color-grey-300;

            .icon {
              font-size: 0.75em;
              opacity: 0.2;
            }

            &.clickable {
              border: 1px solid $color-grey-550;
              cursor: pointer;
              background: $color-white;

              .icon {
                opacity: 0.75;
              }
              &:hover .icon {
                opacity: 1;
              }
            }
          }
        }
      }
    }
  }

  @media (max-width: 1200px) {
    > section.details-header {
      @include flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: stretch;

      margin: -1em 0 0 -1em;
      width: calc(100% + 1em);

      .bai-value-block {
        margin-top: 1em;
        margin-left: 1em;
        min-width: 25%;
      }
    }
  }

  @media (max-width: 1100px) {
    > .title {
      margin-bottom: 2rem;
      h1 {
        font-size: 1.5rem;
      }

      .actions {
        margin-left: 1rem;
        .ids-job-action-btns {
          .btn {
            font-size: 1rem;
            padding-left: 1rem;
            padding-right: 1rem;
          }
        }
      }
    }

    > .assessment-titles-wrap {
      display: none;
    }

    > .assessments-summary-wrap {
      display: block;

      .assessment-summary {
        width: 100%;

        &:not(:first-child) {
          margin-top: 1em;
        }

        .left-side {
          > .title {
            display: block;
          }
        }
      }
    }
  }

  @media (max-width: 800px) {
    > .title {
      .actions {
        .ids-job-action-btns {
          .btn {
            .label {
              display: none;
            }
          }
        }
      }
    }
    > .assessments-summary-wrap {
      .assessment-summary {
        .left-side {
          > .title {
            h2 {
              font-size: 1.5rem;
            }
          }

          .stats-wrap {
            .stats-text-data {
              .stats-data-items {
                .stats-data-item {
                  // font-size: 1rem;

                  > .label {
                    display: block;
                  }

                  > .value-wrap {
                    display: block;
                  }
                }
              }
            }
          }
        }
      }
    }

    > .assessments-details-wrap {
      display: block;
      .assessment-details {
        width: 100%;

        &:not(:first-child) {
          margin-top: 2em;
        }
      }
    }
  }
}
