@import "scss/vars.scss";

$_slug: "order-details";
$_block_padding: 1.5rem;

.databot-config-panel.databot-config-panel-#{$_slug} {
  .databot-config-panel-body {
    .databot-config-panel-body-content {
      .order-details-bot-body-panel {
        @include flex;
        justify-content: space-between;

        > .block-left,
        .block-right {
          flex: 0 0 49%;
        }

        .block-left {
          .description {
            max-width: 40em;
            margin-bottom: $_block_padding;
          }

          .intelligent-fill-output-block {
            .intelligent-fill-output-table {
              width: 100%;
              background: $color-blue-dg;
              color: $color-white;
              font-size: 1.25rem;
              border-radius: $border-radius-lg;

              thead tr th {
                padding-top: 0.75rem;
              }

              tbody tr:last-child td {
                padding-bottom: 0.75rem;
              }

              td,
              th {
                padding: calc($_block_padding / 4) calc($_block_padding / 2);
                vertical-align: top;
              }

              th {
                font-weight: inherit;
                text-transform: uppercase;
                font-size: 1rem;
              }

              td {
                font-weight: $typography-font-weight-light;
              }
            }
          }
        }

        .block-right {
          .order-attributes-block {
            font-size: 1.25rem;

            .grid-col {
              padding: calc($_block_padding / 4) calc($_block_padding / 2);
              &-title {
                flex: 0 0 50%;
              }
              &-value {
                flex: 0 0 25%;
              }
              &-skus {
                flex: 0 0 25%;
              }
            }
            .top-titles {
              @include flex;
              font-size: 1rem;
              text-transform: uppercase;
              font-weight: $typography-font-weight-bolder;
              color: $color-blue-dg;
            }

            .row-blocks {
              .row-block {
                &:not(:first-child) {
                  margin-top: #{$_block_padding * 1.5};
                }
                .block-values {
                  @include flex;
                  font-weight: $typography-font-weight-bolder;
                }
                .child-block {
                  background: $color-grey-50;
                  margin-left: 1.5rem;
                  padding-top: calc($_block_padding / 3);
                  padding-bottom: calc($_block_padding / 3);

                  .grid-col.grid-col-title {
                    flex: 0 0 calc(50% - 0.75rem); // TODO: Workaround to equalize cols
                  }
                  .child-block-titles {
                    @include flex;
                    font-size: 1rem;
                    text-transform: uppercase;
                    font-weight: $typography-font-weight-bolder;
                    color: $color-blue-dg;
                  }
                  .child-block-values {
                    @include flex;
                  }
                }
              }
            }
          }
        }
      }

      .empty-notice {
        @include flex;
        flex-direction: column;
        align-items: center;
        flex: 0 0 100%;

        .icon {
          font-size: 4em;
          color: $color-green-dg;
        }

        .message {
          font-size: 1.25rem;
          font-weight: $typography-font-weight-bold;
        }
      }
    }
  }
}

.modal.databot-config-modal-panel.databot-config-modal-panel-#{$_slug} {
  .modal-body {
    .intelligent-fill-output-table {
      width: 100%;

      thead tr th {
        font-weight: $typography-font-weight-bolder;
        text-transform: uppercase;
      }

      td,
      th {
        vertical-align: top;
      }
    }
  }
}
