@import 'scss/vars.scss';

.file-selection {
  > .content {
    display: flex;
    flex-direction: row;
    > :not(:first-child) {
      margin-left: 1.5rem;
    }

    .styled-multiselect-container {
      flex: 1;
      vertical-align: middle;
    }
  }
}
