@import "scss/vars.scss";

.data-job-link-btn {
    @include button-reset;
    display: inline-flex;
    align-items: center;
    padding: 10px 15px;
    color: $color-text;
    font-weight: $typography-font-weight-bolder;
    font-size: 15px;
    background-color: $color-white;
    border: 1px solid $color-grey-dg;
    border-radius: 6px;
    box-shadow: 0 1px 3px 0 rgba(223, 225, 228, 0.5);

    .fa-link {
        margin-right: 10px;
        font-size: 10px;
    }
}
