@import 'scss/vars.scss';

// Slug suffixes generated by inner template components
$_slug: "short-description-creator";

@mixin _data-items {
  @include unstyled-list;
  .data-item {
    .kv-pair {
      @include flex;
      color: $color-text;
      font-size: 20px;
      width: 100%;

      .value {
        font-weight: $typography-font-weight-bolder;
        text-align: right;
        margin-right: 0.5em;

        &:first-child {
          min-width: 4em;
          width: auto;
        }
      }
    }
  }
}

// Styles for template content
.databot-config-panel.databot-config-panel-#{$_slug} {
  .databot-config-panel-body {
    .data-items {
      @include _data-items;
      .data-item {
        font-size: 1.5em;
        margin: 0.5em 0;
      }
    }

    .char-limit {
      display: flex;
      flex-direction: column;
      width: 50%;
      margin-left: 5rem;
      margin-top: 1rem;
      margin-bottom: 2rem;

      &-label {
        color: $color-text;
        font-weight: $typography-font-weight-bold;
        font-size: 13px;
        margin-bottom: 0;
      }

      &-input {
        width: 100%;
        padding: 10px 15px;
        color: $color-text;
        font-size: 13px;
        background: $color-white;
        border: 1px solid $color-grey-dg;
        border-radius: 8px;
        margin-top: 0.5rem;

        &::placeholder {
            color: $color-grey-550;
        }

        &::-webkit-outer-spin-button, &::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }

        &--invalid {
          color: $color-red-dg;
          border-color: $color-red-dg;
        }

        -moz-appearance: textfield;
      }

      &-invalid {
        color: $color-red-dg;
        font-weight: $typography-font-weight-bold;
        font-size: 13px;
        margin-top: 0.5rem;
      }
    }
  }
}

// Styles for confirm dialog content
.modal.databot-config-modal-panel.databot-config-modal-panel-#{$_slug} {
  .modal-body {
    .data-items {
      @include _data-items;

      .data-item {
        margin: 1.25em 0;
      }

      .data-item .kv-pair {
        .value {
          text-align: left;
          font-size: 16px;
          width: 5em !important;
          margin-right: 2em;
        }

        .label {
          margin-bottom: 0;
        }
      }
    }
  }
}
